import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Paper } from '@mui/material';
import type {
  DataGridPremiumProps,
  FilterColumnsArgs,
  GridColDef,
  GridToolbarProps,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import {
  DataGridPremium,
  GridLogicOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { type MutableRefObject, useCallback } from 'react';

import { useGlobalFilterContext } from '~/contexts/globalFilterContext';

import type { LiftAIGridColDef } from './colDefTemplate';

export interface NestedKey {
  colName: string;
  queryKey: string;
}

// These are banned for external usage of LiftAITable
type BannedProps =
  | 'autoHeight' // This is deprecated by MUI so we're not allowing it
  | 'autoPageSize'
  | 'pagination'
  | 'sx'
  | 'slots'
  | 'getDetailPanelHeight'; // TODO: We may want to consider allowing this

/**
 * Props for LiftAITable component
 * @param rows: Array of rows to be displayed in the table
 * @param columns: Array of columns to be displayed in the table
 * @param tableHeaderTitle: Title of the table
 */
interface LiftAITableProps<TRow extends GridValidRowModel>
  extends Omit<DataGridPremiumProps<TRow>, BannedProps> {
  columns: LiftAIGridColDef<TRow>[];
  tableHeaderTitle: string;
  enableQuickFilter?: boolean;
  quickFilterPlaceHolder?: string;
  toolbarProps?: ExternalLiftAIToolbarProps;

  // Investigate why this is needed
  apiRef: MutableRefObject<GridApiPremium>;
}

type ExternalLiftAIToolbarProps = {
  /**
   * Pass `CustomGridToolbarExport` to override the export tool of the table.
   * This will replace the component entirely, allowing for fully customize UI and behavior.
   */
  CustomGridToolbarExport?: () => JSX.Element;

  /**
   * pass `CustomGridToolbarColumnsButton` to override the table columns preferences.
   * This will replace the component entirely, allowing for fully customize UI and behavior.
   */
  CustomGridToolbarColumnsButton?: () => JSX.Element;
};

export type { ExternalLiftAIToolbarProps as LiftAIToolbarProps };

type LiftAIToolbarProps = GridToolbarProps &
  ExternalLiftAIToolbarProps & {
    onClearFilters: () => void;
  };

function LiftAIToolbar({
  showQuickFilter,
  onClearFilters,
  CustomGridToolbarExport,
  CustomGridToolbarColumnsButton,
  quickFilterProps,
}: LiftAIToolbarProps) {
  const apiRef = useGridApiContext();
  const filterModel = apiRef.current.state.filter.filterModel;

  const showClearButton =
    filterModel &&
    (filterModel.items.length > 0 ||
      (filterModel.quickFilterValues && filterModel.quickFilterValues?.length > 0));

  return (
    <Box display="flex" justifyContent="space-between">
      <GridToolbarContainer>
        {CustomGridToolbarColumnsButton ? (
          <CustomGridToolbarColumnsButton />
        ) : (
          <GridToolbarColumnsButton />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {CustomGridToolbarExport ? <CustomGridToolbarExport /> : <GridToolbarExport />}
        {showClearButton ? (
          <Button
            sx={{ marginLeft: 2 }}
            size="small"
            startIcon={<ClearIcon />}
            onClick={onClearFilters}
            variant="outlined"
          >
            Clear Filters
          </Button>
        ) : null}
      </GridToolbarContainer>
      {showQuickFilter ? (
        <GridToolbarQuickFilter
          placeholder={quickFilterProps?.placeholder}
          debounceMs={100}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1em',
              height: '1em',
              fontSize: '1.5rem',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              padding: '5px',
            },
            '& .MuiInputBase-root:before': {
              borderBottom: 'none !important',
            },
            '& .MuiInputBase-root:after': {
              borderBottom: 'none !important',
            },
            '& .MuiInputBase-input': {
              marginLeft: '10px !important',
            },
          }}
        />
      ) : null}
    </Box>
  );
}

/**
 * Wrapper around DataGridPremium component to facilitate
 * most of the Pro features. Such as: Quick filtering of columns,
 * column reordering, column pinning, etc.
 * @param props: LiftAITableProps
 * @returns DataGridPremium component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function LiftAITable<TRow extends GridValidRowModel = any>({
  rows,
  columns,
  enableQuickFilter,
  quickFilterPlaceHolder,
  initialState,
  filterModel,
  onFilterModelChange,
  apiRef,
  toolbarProps: liftAIToolbarProps,
  ...tableProps
}: LiftAITableProps<TRow>) {
  const initialStateObj = useKeepGroupedColumnsHidden({
    apiRef,
    initialState,
  });
  const { resetGlobalFilters } = useGlobalFilterContext();

  const onClearFilters = useCallback(() => {
    resetGlobalFilters();

    // If the onFilterModelChange is not provided, we can't do anything
    // because we don't manage the filter model ourselves
    if (!onFilterModelChange) {
      return;
    }

    onFilterModelChange(
      {
        items: [],
        quickFilterValues: [],
        quickFilterLogicOperator: GridLogicOperator.And,
        logicOperator: GridLogicOperator.And,
      },
      { reason: 'deleteFilterItem', api: apiRef.current },
    );
  }, [apiRef, onFilterModelChange, resetGlobalFilters]);

  const LiftAIToolbarWrapper = useCallback(
    function Toolbar(props: GridToolbarProps) {
      return <LiftAIToolbar {...liftAIToolbarProps} onClearFilters={onClearFilters} {...props} />;
    },
    [onClearFilters, liftAIToolbarProps],
  );

  return (
    <Paper sx={{ padding: 1, flex: '1 1 auto', maxWidth: '100%' }}>
      <DataGridPremium
        apiRef={apiRef}
        initialState={initialStateObj}
        rows={rows}
        columns={columns}
        pagination
        slots={{
          toolbar: LiftAIToolbarWrapper,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: enableQuickFilter,
            quickFilterProps: {
              placeholder: quickFilterPlaceHolder,
            },
          },
          filterPanel: {
            // Force usage of "And" operator
            logicOperators: [GridLogicOperator.And],
            disableRemoveAllButton: true,
            filterFormProps: {
              /**
               * This is to remove the grouping column because it's duplicated
               *
               * Link: https://github.com/mui/mui-x/issues/12223
               */

              filterColumns: ({ columns }: FilterColumnsArgs) => {
                const filtered = columns.filter(
                  (column) => column.field !== '__row_group_by_columns_group__',
                );

                return filtered.map((column) => column.field);
              },
            },
          },
          columnsManagement: {
            getTogglableColumns: (columns: GridColDef[]) => {
              return columns
                .filter((column) => column.field !== '__row_group_by_columns_group__')
                .map((column) => column.field);
            },
          },
        }}
        sx={{
          border: 0,
          '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders': {
            boxShadow: 'none',
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        {...tableProps}
      />
    </Paper>
  );
}

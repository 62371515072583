import InfoOutlined from '@mui/icons-material/InfoOutlined';
import type { Grid2Props } from '@mui/material';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import highchartsSeriesLabel from 'highcharts/modules/series-label';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';

import { useLAINavigate } from '~/hooks/useLAINavigate';

if (typeof Highcharts === 'object') {
  highchartsSeriesLabel(Highcharts);
  highchartsAccessibility(Highcharts);
  highchartsExportData(Highcharts);
  highchartsExporting(Highcharts);
  highchartsNoDataToDisplay(Highcharts);
}

export type PerformanceWidgetProps = Grid2Props & {
  metricName: string;
  metricValue: string;
  metricInformation?: string;
  navigateTo?: string;
  addLink?: boolean;
};
export const PerformanceSingleMetricWidgetGrid: React.FC<PerformanceWidgetProps> = ({
  metricName,
  metricValue,
  metricInformation,
  navigateTo,
  addLink = true,
  ...props
}) => {
  const metric = (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 1,
        height: '145px',
        cursor: addLink ? 'pointer' : 'default',
        marginTop: 0,
        transition: 'transform 0.2s',
        '&:hover': {
          bgcolor: '#F6F6F6',
        },
      }}
      variant="outlined"
    >
      <CardContent
        sx={{
          flex: '1 0 auto',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
      >
        <Typography sx={{ color: 'rgba(0,0,0,.6)' }} variant="subtitle1">
          {metricName}
        </Typography>
        <Typography variant="h5">{metricValue}</Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          disableInteractive
          title={metricInformation}
        >
          <IconButton>
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
  return (
    <Grid {...props}>
      {addLink && navigateTo ? (
        <Link sx={{ textDecoration: 'none' }} href={navigateTo}>
          {metric}
        </Link>
      ) : (
        metric
      )}
    </Grid>
  );
};

const calculateMonthlyAverages = (data: [string, number][]): number[][] => {
  if (data.length === 0) return [];

  return data
    .filter((value) => value.length === 2)
    .map(([date, value]) => [new Date(date).getTime(), value * 100])
    .sort(([prevDate], [currDate]) => prevDate - currDate);
};

export type MetricsTrendsProps = {
  data: [string, number][];
};

export const MetricsTrends: React.FC<MetricsTrendsProps> = ({ data }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const monthlyAverages = calculateMonthlyAverages(data);
  const [options, setOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    setOptions({
      title: {
        text: '',
        align: 'left',
      },
      subtitle: {
        text: '',
        align: 'left',
      },
      lang: {
        noData: 'No trends to display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
        position: {
          align: 'center',
          verticalAlign: 'middle',
        },
      },
      chart: {
        type: 'areaspline',
        height: 478,
      },
      xAxis: {
        type: 'datetime',
        lineColor: '#B8B8B8',
        tickColor: '#B8B8B8',
        labels: {
          style: {
            color: '#B8B8B8',
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          format: '{value}%',
          style: {
            color: '#B8B8B8',
          },
        },
        max: 100,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          pointStart: 0,
          color: '#0C5DE9',
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, 'rgba(12,93,233,.5)'],
              [1, 'rgba(255,255,255,0)'],
            ],
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b><br/>',
        valueSuffix: '%',
        valueDecimals: 1,
      },
      series: [
        {
          type: 'area',
          name: '',
          data: monthlyAverages,
          zoneAxis: 'x',
          zones: [
            {
              color: '#0C5DE9',
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, 'rgba(12,93,233,.5)'],
                  [1, 'rgba(255,255,255,0)'],
                ],
              },
              //start of the filtered period
              value:
                monthlyAverages.length > 4
                  ? monthlyAverages[monthlyAverages.length - 4][0]
                  : undefined,
            },
          ],
        },
      ],
      credits: {
        enabled: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Paper sx={{ height: '98%', width: '100%' }} variant="outlined">
      <Typography p={3} variant="h6">
        Maintenance Completion Trend
      </Typography>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        constructorType={'chart'}
        containerProps={{ className: 'trendsChar' }}
      />
    </Paper>
  );
};

export type PerformanceDoubleMetricWidgetGridProps = Grid2Props & {
  metrics: {
    name: string;
    value: string;
  }[];
  metricsInformation?: string;
  navigateTo?: string;
  addLink?: boolean;
};
export const PerformanceDoubleMetricWidgetGrid: React.FC<
  PerformanceDoubleMetricWidgetGridProps
> = ({ metrics, metricsInformation, navigateTo, addLink = true, ...props }) => {
  const { navigate } = useLAINavigate();
  const onClick = () => {
    if (navigateTo && addLink) {
      const [pathname, search] = navigateTo.split('?');
      return navigate({ pathname: pathname, search });
    }
  };

  return (
    <Grid {...props}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: 1,
          height: '145px',
          cursor: addLink ? 'pointer' : 'default',
          marginTop: 0,
          transition: 'transform 0.2s',
          '&:hover': {
            bgcolor: '#F6F6F6',
          },
        }}
        variant="outlined"
        onClick={onClick}
      >
        <CardContent
          sx={{
            flex: '1 0 auto',
            paddingBottom: '8px',
            paddingTop: '8px',
          }}
        >
          <Grid container>
            {metrics.map((metric, pos) => (
              <Grid
                size={{ xs: 6 }}
                key={metric.name + metric.value}
                pr={pos % 2 ? 0 : 2}
                pl={pos % 2 ? 2 : 0}
              >
                <Typography sx={{ color: 'rgba(0,0,0,.6)' }} variant="subtitle1">
                  {metric.name}
                </Typography>
                <Typography variant="h5">{metric.value}</Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip disableFocusListener disableTouchListener title={metricsInformation}>
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );
};

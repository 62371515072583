import type { Ticket } from '@liftai/asset-management-types';
import {
  getWorkPerformedLabel,
  resolveTimeLabels,
  ticketIsCallback,
} from '@liftai/asset-management-types';
import type { SxProps, Theme } from '@mui/material';
import { alpha, Box, Paper, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useContext, useMemo } from 'react';

import { UserContext } from '~/auth/userContext';
import { yesOrNo } from '~/components/utils/booleanToText';
import { LiftAITextLink } from '~/components/utils/helpers';
import { canAccess, DOWNLOAD_TICKET_FILE } from '~/utils/role';

export interface TicketExpandableRowProps<T> {
  row: T;
}

interface ICellProps {
  sx?: SxProps<Theme>;
  colSpan?: number;
  label: string;
  value: React.ReactNode;
}

const Cell = ({ sx, colSpan = 1, label, value }: ICellProps) => {
  const theme = useTheme();
  // TODO: Maybe we should extract common body styles
  const textColor = alpha(theme.palette.common.black, 0.6);

  return (
    <Grid sx={sx} size={{ xs: colSpan }} px={3} py={2}>
      <Typography color={textColor} variant="body2">
        {label}
      </Typography>
      {value || '-'}
    </Grid>
  );
};

export const TicketExpandableRow = ({ row }: TicketExpandableRowProps<Ticket>) => {
  let callTime;
  const problemDesc = 'problemDescription' in row ? row.problemDescription : '-';

  if (ticketIsCallback(row)) {
    callTime = row.callTime ? new Date(row.callTime).toLocaleString() : '-';
  }

  const { startedLabel, finishedLabel } = resolveTimeLabels(row.entryType);

  const getPrimaryAttachment = useMemo(() => {
    return row.attachments?.find((att) => att.isPrimary);
  }, [row.attachments]);

  const {
    userDetails: { role: userRole },
  } = useContext(UserContext);

  return (
    <Box p={1}>
      <Paper>
        <Grid container columns={5}>
          <Cell label="Time on site" value={row.timeOnSiteRegularHours} />
          <Cell label="Travel Time" value={row.travelTimeRegularHours} />
          <Cell label="Time on site (OT)" value={row.timeOnSiteOvertime} />
          <Cell label="Travel Time (OT)" value={row.travelTimeOvertime} colSpan={2} />
          <Cell label="Call Time" value={callTime} />
          <Cell label={startedLabel} value={new Date(row.startedTime).toLocaleString()} />
          <Cell label={finishedLabel} value={new Date(row.finishedTime).toLocaleString()} />
          <Cell
            label="Cars"
            value={
              <Box display="flex" flexDirection="column">
                {row.carsServiced.length
                  ? row.carsServiced.map((car) => (
                      // TODO Add link to car detail page, when it is available.
                      <span key={car.id}>{car.name}</span>
                    ))
                  : '-'}
              </Box>
            }
          />
          {/* TODO: Place proper filename */}
          {canAccess(userRole, DOWNLOAD_TICKET_FILE) && (
            <Cell
              label="File"
              value={
                row.attachments?.length ? (
                  /** NOTE: The download attribute may not work with cross-origin URLs.
                   * In development the url might point to your local API HOST which looks like
                   * http://0.0.0.0:8000/media/attachments/{file}.pdf so when clicking download
                   * it might just open a new browser tab with the Preview PDF file.
                   */
                  <LiftAITextLink
                    href={getPrimaryAttachment?.attachmentUrl}
                    target="_blank"
                    label="Download"
                    download
                  />
                ) : (
                  '-'
                )
              }
            />
          )}
        </Grid>
        <Grid container columns={5}>
          <Cell label="Problem Description" value={problemDesc} />
          <Cell label="Equipment Related Failure" value={yesOrNo(row.equipmentFailure)} />
          <Cell label="Problem Type" value={row.problemType} />
          <Cell label={getWorkPerformedLabel(row.entryType)} value={row.workPerformed || '-'} />
        </Grid>
      </Paper>
    </Box>
  );
};

import { z } from 'zod';
export const api_complianceMetadataSchema = z.object({
    activeCertificates: z.number(),
    expiringCertificates: z.number(),
    expiredCertificates: z.number(),
    certificatesByJurisdiction: z.record(z.number()),
    certificatesWithViolations: z.number(),
});
export const api_carStatSchema = z.object({
    totalCars: z.number(),
    activeCars: z.number(),
    inactiveCars: z.number(),
    overdueCars: z.number(),
    carsWithoutUpdates: z.number(),
});
export const api_complianceSchema = z.object({
    carId: z.string(),
    certificateId: z.string(),
    propertyId: z.string(),
    unitName: z.string(),
    portfolio: z.string(),
    property: z.string(),
    propertyManager: z
        .object({
        id: z.string(),
        name: z.string(),
        owner: z.object({
            id: z.string(),
            email: z.string(),
            fullName: z.string(),
        }),
    })
        .nullable(),
    serviceProvider: z.string().nullable(),
    jurisdiction: z.string().nullable(),
    inspectionStatus: z.enum(['failed', 'passed']),
    certificateExpiration: z.string().nullable(),
    ahjId: z.string(),
    openViolations: z.number(),
    closedViolations: z.number(),
    nextViolationDue: z.string().nullable(),
    violationsWithoutUpdates: z.number(),
    downloadUrl: z.string(),
});
export const api_complianceCarResponse = z.object({
    statistics: api_carStatSchema,
    car: api_complianceSchema,
});
export const api_complianceFilterResponse = z.object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    metadata: api_complianceMetadataSchema,
    results: z.array(api_complianceSchema),
});

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMemo, useState } from 'react';

export type NavigationControlsProps = {
  type: string | null;
  defaultPage: number;
  numberOfPages: number;
  onNavigationChange: (page: number) => void;
};
export const NavigationControls: React.FC<NavigationControlsProps> = ({
  type,
  defaultPage,
  numberOfPages,
  onNavigationChange,
}) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const theme = useTheme();
  const isActivePage = useMemo(() => currentPage === defaultPage, [currentPage, defaultPage]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: isActivePage ? theme.palette.primary.main : theme.palette.action.hover,
        color: isActivePage ? theme.palette.common.white : theme.palette.text.primary,
        padding: theme.spacing(1),
      }}
    >
      <Grid size={{ xs: 9 }}>
        <Typography fontStyle={type === null ? 'italic' : undefined}>
          {type ?? 'Pending classification'}
        </Typography>
      </Grid>
      <Grid
        container
        size={{ xs: 3 }}
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <ArrowBackIosIcon
          fontSize="small"
          onClick={() => {
            if (currentPage === 1) return;
            setCurrentPage(currentPage - 1);
            onNavigationChange(currentPage - 1);
          }}
        />
        <Typography>
          {currentPage}/{numberOfPages}
        </Typography>
        <ArrowForwardIosIcon
          fontSize="small"
          onClick={() => {
            if (currentPage === numberOfPages) return;
            setCurrentPage(currentPage + 1);
            onNavigationChange(currentPage + 1);
          }}
        />
      </Grid>
    </Grid>
  );
};

import {
  API_CertificateCreateRequestResponse,
  API_InspectionReportCreateResponse,
  type InvoiceForm,
  InvoiceKind,
  type TicketDto,
} from '@liftai/asset-management-types';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import RequestQuote from '@mui/icons-material/RequestQuote';
import {
  Alert,
  alpha,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useCallback, useMemo, useState } from 'react';
import { type FieldValues, FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { useElementSize } from 'usehooks-ts';

import { useCertificateForm } from '~/components/uploadDocument/certificateForm';
import { useInspectionReportForm } from '~/components/uploadDocument/inspectionReportForm';
import type { SupportingDataDto } from '~/components/uploadDocument/SupportingDataForm';
import { useSupportingDataForm } from '~/components/uploadDocument/SupportingDataForm';
import { useViolationForm } from '~/components/uploadDocument/violationForm';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';
import { DocumentPreview } from '../documentPreview';
import { UploadDocumentType } from './AddManuallyDropdown';
import { DefaultFields, DefaultForm } from './DefaultFields';
import { useInvoiceForm } from './InvoiceForm';
import SelectDocumentTypeDropdown from './SelectDocumentTypeDropdown';
import { useTicketForm } from './TicketForm';
import type { LiftAIDocumentUntagged } from './UploadDocumentDialogFlow';

interface ISelectUploadDocumentTypeDialogProps {
  open: boolean;
  onCancel: () => void;
  onDone: (taggedDocuments: LiftAIDocumentTagged[]) => void;
  documents: LiftAIDocumentUntagged[];
}

export type InvoiceEditable =
  | {
      /** `editInvoice` enables the form in edit mode for the invoice */
      editInvoice: boolean;
      /** `invoiceData` contains the invoice data to be edited */
      invoiceData: InvoiceForm;
    }
  | {
      editInvoice?: never;
      invoiceData?: never;
    };

export type LiftAIDocumentTagged = LiftAIDocumentUntagged & {
  documentType: UploadDocumentType;
  formData: unknown;
};

type SelectUploadDocumentTypeState = {
  currentDocumentIndex: number;
  taggedDocuments: LiftAIDocumentTagged[];
  lastTicketId: string | null;
  lastCertificateId: string | null;
  lastInspectionReportId: string | null;
  lastInvoiceId: string | null;
  lastInvoiceKind: UploadDocumentType | null;
  lastPropertyId: string | null;
  lastCarIds: string[];
  invoiceSelected: boolean;
  proposalSelected: boolean;
  ticketSelected: boolean;
  certificateSelected: boolean;
  inspectionReportSelected: boolean;
  supportingDataMovingForward?: boolean;
  remainingPages?: number;
  subsequentPagesAsContinued?: boolean;
} & (
  | { name: 'fillingOutForm'; selectedDocumentType: UploadDocumentType | null }
  | { name: 'selecting'; selectedDocumentType: UploadDocumentType | null }
);

export type LiftAIFormProps<T = unknown, TSubmit = unknown> = {
  children: React.ReactNode;
  document?: LiftAIDocumentTagged;
  initialData?: T;
  onSubmit(data: TSubmit): void;
};

export type LAIUseFormReturn<T extends FieldValues, TSubmit = unknown> = {
  hookForm: UseFormReturn<T, TSubmit>;
  Form: React.FC<LiftAIFormProps<T>>;
  Fields: React.FC<{ documentType?: UploadDocumentType }>;
};

type UseCurrentFormReturn = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form: React.FC<LiftAIFormProps<any>>;
  Fields: React.FC<{ documentType?: UploadDocumentType }>;
};

const useCurrentForm = (
  documentType: UploadDocumentType = UploadDocumentType.initial,
): UseCurrentFormReturn => {
  const ticketForm = useTicketForm();
  const invoiceForm = useInvoiceForm();
  const supportingDataForm = useSupportingDataForm();
  const certificateForm = useCertificateForm();
  const violationForm = useViolationForm();
  const inspectionReportForm = useInspectionReportForm();

  const defaults: UseCurrentFormReturn = {
    hookForm: useForm(),
    Form: DefaultForm,
    Fields: DefaultFields,
  };

  switch (documentType) {
    case UploadDocumentType.ticket:
      return {
        hookForm: ticketForm.hookForm,
        Form: ticketForm.Form,
        Fields: ticketForm.Fields,
      };
    case UploadDocumentType.invoice:
    case UploadDocumentType.proposal:
      return {
        hookForm: invoiceForm.hookForm,
        Form: invoiceForm.Form,
        Fields: invoiceForm.Fields,
      };
    case UploadDocumentType.certificate:
      return {
        hookForm: certificateForm.hookForm,
        Form: certificateForm.Form,
        Fields: certificateForm.Fields,
      };
    case UploadDocumentType.violation:
      return {
        hookForm: violationForm.hookForm,
        Form: violationForm.Form,
        Fields: violationForm.Fields,
      };
    case UploadDocumentType.inspectionReport:
      return {
        hookForm: inspectionReportForm.hookForm,
        Form: inspectionReportForm.Form,
        Fields: inspectionReportForm.Fields,
      };
    case UploadDocumentType.supportingData:
    case UploadDocumentType.invoiceContinued:
    case UploadDocumentType.proposalContinued:
    case UploadDocumentType.ticketContinued:
    case UploadDocumentType.certificateContinued:
    case UploadDocumentType.inspectionReportContinued:
      return {
        hookForm: supportingDataForm.hookForm,
        Form: supportingDataForm.Form,
        Fields: supportingDataForm.Fields,
      };
    default:
      return defaults;
  }
};

const DocumentTypeIcon = ({ documentType }: { documentType: UploadDocumentType }) => {
  switch (documentType) {
    case UploadDocumentType.ticket:
      return <ReceiptLongOutlined />;
    case UploadDocumentType.invoice:
    case UploadDocumentType.proposal:
      return <RequestQuote />;
    default:
      return null;
  }
};

const SelectUploadDocumentTypeDialog: React.FC<
  ISelectUploadDocumentTypeDialogProps & InvoiceEditable
> = ({ open, onCancel, onDone, documents, editInvoice, invoiceData }) => {
  const [state, setState] = useState<SelectUploadDocumentTypeState>({
    name: 'selecting',
    currentDocumentIndex: 0,
    selectedDocumentType: null,
    taggedDocuments: documents.map((doc, i) => ({
      ...doc,
      pageNum: i + 1,
      documentType: UploadDocumentType.initial,
      formData: {},
    })),
    lastTicketId: null,
    lastCertificateId: null,
    lastInspectionReportId: null,
    lastInvoiceId: null,
    lastInvoiceKind: null,
    lastPropertyId: null,
    lastCarIds: [],
    invoiceSelected: false,
    proposalSelected: false,
    ticketSelected: false,
    certificateSelected: false,
    inspectionReportSelected: false,
    supportingDataMovingForward: false,
    subsequentPagesAsContinued: false,
  });
  const documentTypes = useMemo(() => {
    const options = [
      UploadDocumentType.invoice,
      UploadDocumentType.ticket,
      state.proposalSelected ? UploadDocumentType.proposalContinued : UploadDocumentType.proposal,
      UploadDocumentType.supportingData,
      UploadDocumentType.certificate,
      UploadDocumentType.violation,
      UploadDocumentType.inspectionReport,
    ];

    if (state.invoiceSelected) {
      options.splice(1, 0, UploadDocumentType.invoiceContinued);
    }

    if (state.ticketSelected) {
      options.splice(2, 0, UploadDocumentType.ticketContinued);
    }
    if (state.certificateSelected) {
      options.splice(
        options.indexOf(UploadDocumentType.certificate) + 1,
        0,
        UploadDocumentType.certificateContinued,
      );
    }

    if (state.inspectionReportSelected) {
      options.splice(
        options.indexOf(UploadDocumentType.inspectionReport) + 1,
        0,
        UploadDocumentType.inspectionReportContinued,
      );
    }

    return options;
  }, [
    state.invoiceSelected,
    state.proposalSelected,
    state.ticketSelected,
    state.certificateSelected,
    state.inspectionReportSelected,
  ]);
  const theme = useTheme();
  const { currentDocumentIndex, taggedDocuments } = state;
  const [documentPreviewContainerRef, { height: documentPreviewContainerHeight }] =
    useElementSize();
  const numDocuments = documents.length;
  const currentDocument: LiftAIDocumentTagged | undefined = taggedDocuments[currentDocumentIndex];
  const currentPage = currentDocumentIndex + 1;

  const handleForwardSubsequentPages = useCallback(
    (checked: boolean) => {
      const nextDocs = [...state.taggedDocuments];
      nextDocs[currentDocumentIndex].formData = {
        ...(nextDocs[currentDocumentIndex].formData || {}),
        subsequentPagesAsContinued: checked,
        remainingPages: numDocuments - currentPage,
      };

      setState((state) => ({
        ...state,
        subsequentPagesAsContinued: checked,
        remainingPages: numDocuments - currentPage,
        taggedDocuments: nextDocs,
      }));
    },
    [state.taggedDocuments, currentDocumentIndex, numDocuments, currentPage],
  );

  const handleUploadDocumentTypeSelected = useCallback(
    (documentType: UploadDocumentType) => {
      if (currentDocument.fileType !== 'pdf') {
        return;
      }

      if (editInvoice) {
        return setState((state) => ({
          ...state,
          lastInvoiceId: invoiceData.id,
          lastPropertyId: invoiceData.propertyId,
          lastCarIds: invoiceData.carIds,
          selectedDocumentType: documentType,
        }));
      }

      return setState((state) => ({
        ...state,
        selectedDocumentType: documentType,
      }));
    },
    [currentDocument.fileType, editInvoice, invoiceData],
  );

  const handleNext = useCallback(
    (...args: unknown[]) => {
      if (state.name === 'selecting' && state.selectedDocumentType) {
        const nextDocs = [...state.taggedDocuments];

        const nextDocument = {
          ...currentDocument,
          documentType: state.selectedDocumentType,
        };
        switch (state.selectedDocumentType) {
          case UploadDocumentType.ticket:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              propertyId: state.lastPropertyId,
              carIds: state.lastCarIds,
            };
            break;
          case UploadDocumentType.certificate:
            nextDocument.formData = {
              jurisdiction: '',
              date_issued: '',
              expiration_date: '',
            };
            break;

          case UploadDocumentType.violation:
            nextDocument.formData = {
              code: '',
              location: '',
              correction_due: '',
              responsibility: 'owner',
              description: '',
            };
            break;
          case UploadDocumentType.inspectionReport:
            nextDocument.formData = {
              property_id: '',
              car_id: '',
              inspection_date: new Date().toISOString().split('T')[0],
              inspection_type: 'periodic',
              custom_type: '',
              status: 'passed',
              inspector_name: '',
              comments: '',
              violations: [],
            };
            break;
          case UploadDocumentType.invoice:
          case UploadDocumentType.proposal: {
            if (editInvoice) {
              nextDocument.formData = invoiceData;
              setState((curState) => ({ ...curState, lastInvoiceId: invoiceData.id }));
            } else {
              nextDocument.formData = {
                invoiceId: state.lastInvoiceId,
                kind:
                  state.selectedDocumentType === UploadDocumentType.invoice
                    ? InvoiceKind.Invoice
                    : InvoiceKind.Proposal,
              };
            }
            break;
          }
          case UploadDocumentType.ticketContinued:
            nextDocument.formData = {
              ticketId: state.lastTicketId,
              isContinued: true,
            };
            break;

          case UploadDocumentType.certificateContinued:
            nextDocument.formData = {
              isContinued: true,
              certificateId: state.lastCertificateId,
            };
            break;

          case UploadDocumentType.inspectionReportContinued:
            nextDocument.formData = {
              inspectionReportId: state.lastInspectionReportId,
              isContinued: true,
            };
            break;
          case UploadDocumentType.proposalContinued:
          case UploadDocumentType.invoiceContinued:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              invoiceKind: state.lastInvoiceKind,
              isContinued: true,
            };
            break;
          case UploadDocumentType.supportingData:
            nextDocument.formData = {
              invoiceId: state.lastInvoiceId,
              invoiceKind: state.lastInvoiceKind,
              propertyId: state.lastPropertyId,
              carIds: state.lastCarIds,
              supportingDataMovingForward: state.supportingDataMovingForward,
              remainingPages: state.remainingPages,
            };
            break;
          default:
            nextDocument.formData = {};
        }

        nextDocs[currentDocumentIndex] = nextDocument;

        setState((curState) => {
          return {
            ...curState,
            name: 'fillingOutForm',
            taggedDocuments: nextDocs,
          };
        });
      }
    },
    [currentDocument, currentDocumentIndex, state, editInvoice, invoiceData],
  );

  const handleBack = useCallback(() => {
    if (state.name === 'fillingOutForm') {
      setState({
        ...state,
        name: 'selecting',
        selectedDocumentType: currentDocument.documentType,
      });
    } else {
      if (currentDocumentIndex === 0) {
        onCancel();
      } else {
        setState((state) => ({
          ...state,
          name: 'fillingOutForm',
          currentDocumentIndex: currentDocumentIndex - 1,
        }));
      }
    }
  }, [state, onCancel, currentDocument, currentDocumentIndex]);

  const handleSkip = useCallback(() => {
    if (currentDocumentIndex >= taggedDocuments.length - 1) {
      return onDone([...state.taggedDocuments]);
    }

    if (state.name === 'selecting') {
      setState({
        ...state,
        name: 'selecting',
        currentDocumentIndex: currentDocumentIndex + 1,
      });
    }
  }, [currentDocumentIndex, onDone, state, taggedDocuments.length]);

  const onSubmit = useCallback(
    (formData: unknown) => {
      const nextDocs = [...state.taggedDocuments];
      nextDocs[currentDocumentIndex] = {
        ...currentDocument,
        formData,
      };

      if (currentDocument.documentType === UploadDocumentType.violation) {
        return onDone([...nextDocs]);
      }

      const hasSavedMultipleSupportingData = () =>
        currentDocument.documentType === UploadDocumentType.supportingData &&
        (currentDocument.formData as SupportingDataDto).supportingDataMovingForward;
      const thereAreNotDocumentsLeft = () =>
        state.name === 'fillingOutForm' && currentDocumentIndex + 1 === numDocuments;
      if (
        hasSavedMultipleSupportingData() ||
        thereAreNotDocumentsLeft() ||
        state.subsequentPagesAsContinued
      ) {
        return onDone([...nextDocs]);
      }

      setState((curState) => {
        const {
          lastInvoiceId,
          lastPropertyId,
          lastCarIds,
          lastInvoiceKind = null,
          lastTicketId = null,
          lastCertificateId = null,
          lastInspectionReportId = null,
        } = (() => {
          switch (currentDocument.documentType) {
            case UploadDocumentType.invoice:
              return {
                lastInvoiceId: (formData as InvoiceForm).id,
                lastPropertyId: (formData as InvoiceForm).propertyId,
                lastCarIds: (formData as InvoiceForm).carIds,
                lastInvoiceKind: UploadDocumentType.invoice,
              };
            case UploadDocumentType.proposal:
              return {
                lastInvoiceId: (formData as InvoiceForm).id,
                lastPropertyId: (formData as InvoiceForm).propertyId,
                lastCarIds: (formData as InvoiceForm).carIds,
                lastInvoiceKind: UploadDocumentType.proposal,
              };
            case UploadDocumentType.ticket:
              return {
                lastInvoiceId: (formData as TicketDto).invoiceId,
                lastPropertyId: (formData as TicketDto).propertyId,
                lastCarIds: (formData as TicketDto).carIds,
                lastTicketId: (formData as TicketDto).id,
              };
            case UploadDocumentType.certificate:
              return {
                lastInvoiceId: curState.lastInvoiceId,
                lastPropertyId: curState.lastPropertyId,
                lastCarIds: curState.lastCarIds,
                lastCertificateId: (formData as API_CertificateCreateRequestResponse).id,
              };
            case UploadDocumentType.inspectionReport:
              return {
                ...curState,
                lastInvoiceId: curState.lastInvoiceId,
                lastPropertyId: curState.lastPropertyId,
                lastCarIds: curState.lastCarIds,
                lastInspectionReportId: (formData as API_InspectionReportCreateResponse).id,
              };
            case UploadDocumentType.violation:
              return {
                ...curState,
                lastPropertyId: curState.lastPropertyId,
              };
            default:
              return {
                lastInvoiceId: curState.lastInvoiceId,
                lastTicketId: curState.lastTicketId,
                lastCertificateId: curState.lastCertificateId,
                lastInspectionReportId: curState.lastInspectionReportId,
                lastPropertyId: curState.lastPropertyId,
                lastCarIds: curState.lastCarIds,
              };
          }
        })();

        const invoiceSelected =
          currentDocument.documentType === UploadDocumentType.invoice ||
          currentDocument.documentType === UploadDocumentType.invoiceContinued;
        const proposalSelected =
          currentDocument.documentType === UploadDocumentType.proposal ||
          currentDocument.documentType === UploadDocumentType.proposalContinued;
        const ticketSelected =
          currentDocument.documentType === UploadDocumentType.ticket ||
          currentDocument.documentType === UploadDocumentType.ticketContinued;

        const certificateSelected =
          currentDocument.documentType === UploadDocumentType.certificate ||
          currentDocument.documentType === UploadDocumentType.certificateContinued;

        const inspectionReportSelected =
          currentDocument.documentType === UploadDocumentType.inspectionReport ||
          currentDocument.documentType === UploadDocumentType.inspectionReportContinued;

        return {
          ...curState,
          name: 'selecting',
          selectedDocumentType: null,
          currentDocumentIndex: currentDocumentIndex + 1,
          taggedDocuments: nextDocs,
          lastTicketId,
          lastCertificateId,
          lastInspectionReportId,
          lastInvoiceId,
          lastInvoiceKind,
          lastPropertyId,
          lastCarIds,
          invoiceSelected,
          proposalSelected,
          ticketSelected,
          certificateSelected,
          inspectionReportSelected,
        };
      });
    },
    [
      currentDocument,
      currentDocumentIndex,
      numDocuments,
      onDone,
      state.subsequentPagesAsContinued,
      state.name,
      state.taggedDocuments,
    ],
  );

  const { hookForm, Form, Fields } = useCurrentForm(currentDocument?.documentType);
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = useMemo(() => {
    const nextEnabled =
      (state.name === 'fillingOutForm' || state.selectedDocumentType != null) && !isSubmitting;
    // Only show next until we can fix the backend update functionality
    // Right now a lot of errors are thrown in the InvoiceSerializer when trying to update an invoice
    // Haven't tested to see what is needed for the other document types
    // When it's fixed we can just remove the `actionBtns` attribute to use the default behavior
    return {
      next: { label: isSubmitting ? 'Saving Page' : 'Next', enabled: nextEnabled },
      ...(state.name === 'selecting' && { skip: { label: 'Skip this Page', enabled: true } }),
    };
  }, [isSubmitting, state]);

  const replaceInvoice =
    editInvoice &&
    state.name === 'selecting' &&
    (state.selectedDocumentType === UploadDocumentType.invoice ||
      state.selectedDocumentType === UploadDocumentType.invoiceContinued ||
      state.selectedDocumentType === UploadDocumentType.proposal ||
      state.selectedDocumentType === UploadDocumentType.proposalContinued);

  const isContinued =
    state.selectedDocumentType === UploadDocumentType.ticketContinued ||
    state.selectedDocumentType === UploadDocumentType.invoiceContinued ||
    state.selectedDocumentType === UploadDocumentType.proposalContinued;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={false}
      sx={{
        // Needed to make the dialog full height so that the DocumentPreview dimensions can be correctly calculated
        '& > .MuiDialog-container > .MuiPaper-root': {
          height: '100%',
        },
      }}
    >
      <FormProvider {...hookForm}>
        <Form document={currentDocument} onSubmit={onSubmit} initialData={currentDocument.formData}>
          {currentDocument.fileType === 'pdf' ? (
            <>
              <DialogTitleWithCloseButton onClose={onCancel} title="Upload Document" />
              <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container minHeight={0} flexGrow={1}>
                  <Grid size={{ xs: 7 }} height="100%" ref={documentPreviewContainerRef}>
                    <DocumentPreview
                      documents={taggedDocuments}
                      currentPage={currentPage}
                      containerHeight={documentPreviewContainerHeight ?? 0}
                    />
                  </Grid>
                  <Grid size={{ xs: 5 }} order={1} px={1}>
                    <Typography variant="body1" mb={2}>
                      {numDocuments > 0 ? `Page ${currentPage} of ${numDocuments}` : null}
                    </Typography>
                    {state.name === 'selecting' ? (
                      <>
                        <Typography variant="h6" mt={6} mb={1}>
                          What type of document is this page?
                        </Typography>
                        <SelectDocumentTypeDropdown
                          onChange={handleUploadDocumentTypeSelected}
                          documentTypes={documentTypes}
                          value={state.selectedDocumentType}
                        />
                        {state.selectedDocumentType === UploadDocumentType.supportingData && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.supportingDataMovingForward}
                                onChange={({ target }) => {
                                  setState((curState) => ({
                                    ...curState,
                                    supportingDataMovingForward: target.checked,
                                    remainingPages: numDocuments - currentPage,
                                  }));
                                }}
                              />
                            }
                            label="Mark subsequent pages as Supporting Data"
                            sx={{ marginLeft: theme.spacing(0.5) }}
                          />
                        )}
                        {replaceInvoice ?? false ? (
                          <Alert sx={{ marginTop: theme.spacing(4) }} severity="warning">
                            Uploading a new {state.selectedDocumentType} PDF will void the existing{' '}
                            {state.selectedDocumentType} and keep it as supporting data.
                          </Alert>
                        ) : null}
                      </>
                    ) : null}
                    {state.name === 'fillingOutForm' ? (
                      <>
                        <Typography variant="h6" my={1} color="primary">
                          <IconButton
                            color="primary"
                            sx={{
                              backgroundColor: alpha(theme.palette.primary.main, 0.12),
                              marginRight: theme.spacing(2),
                            }}
                          >
                            {currentDocument.documentType ? (
                              <DocumentTypeIcon documentType={currentDocument.documentType} />
                            ) : null}
                          </IconButton>
                          {currentDocument.documentType ?? ''}
                        </Typography>
                        <Fields documentType={currentDocument.documentType} />
                        {isContinued ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.subsequentPagesAsContinued}
                                onChange={({ target }) =>
                                  handleForwardSubsequentPages(target.checked)
                                }
                              />
                            }
                            label={`Mark subsequent pages as ${state.selectedDocumentType}`}
                            sx={{ marginLeft: theme.spacing(0.5) }}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          ) : (
            <DialogContent>Image Support Not Implemented</DialogContent>
          )}

          <DialogBackNextActions
            onBack={handleBack}
            onNext={handleNext}
            onSkip={handleSkip}
            actionBtns={actionBtns}
          />
        </Form>
      </FormProvider>
    </Dialog>
  );
};

export default SelectUploadDocumentTypeDialog;

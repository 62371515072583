import type { GridFilterModel } from '@mui/x-data-grid-premium';
import { useCallback } from 'react';
import { useMatches, useSearchParams } from 'react-router-dom';

import type { ItemType } from '~/components/common/SelectCheckbox';
import SelectCheckbox from '~/components/common/SelectCheckbox';
import MonthRangePicker from '~/components/date/MonthRangePicker/MonthRangePicker';
import type { SelectedDateRange } from '~/components/date/MonthRangePicker/types';
import { dateToDateString, getDefaultMonthRange } from '~/components/utils/dateUtils';
import { GlobalFilterContext, useGlobalFilterContext } from '~/contexts/globalFilterContext';

export type FilterName =
  | 'MonthRangePicker'
  | 'portfolioDropDown'
  | 'serviceProviderDropDown'
  | 'regionDropDown';

const filterToSearchParamMap = {
  portfolios: 'portfolio',
  serviceProviders: 'service_provider',
  regions: 'region',
} as const;

export type Filter = {
  name: FilterName;
  component: JSX.Element;
};

type GetMonthRangePickerFilter = {
  value: SelectedDateRange;
  eventHandler: (selectedRage: SelectedDateRange) => void;
};

const getMonthRangePickerFilter = ({ value, eventHandler }: GetMonthRangePickerFilter): Filter => {
  return {
    name: 'MonthRangePicker',
    component: <MonthRangePicker placeHolder="Period" onSelect={eventHandler} value={value} />,
  };
};

type GetDropDownFilter = {
  name: FilterName;
  label: string;
  options: ItemType[];
  value?: string[];
  eventHandler: (selectedItems: string[]) => void;
};

type RemoveFiltersType = {
  [key: string]: string[];
};

// This is the list of filters that should be removed from the sidebar
const routesToFilterForFilterName: RemoveFiltersType = {
  filterPortfolios: ['property-detail', 'tools'],
  filterServiceProviders: ['property-detail', 'tools'],
  filterRegions: ['property-detail', 'tools'],
  filterPeriod: ['tools'],
};

const getDropDownFilter = ({
  name,
  label,
  options,
  eventHandler,
  value,
}: GetDropDownFilter): Filter => {
  return {
    name,
    component: (
      <SelectCheckbox label={label} options={options} onSelect={eventHandler} value={value} />
    ),
  };
};

/**
 * Options for synchronizing search parameters with the filter model
 */
type SyncSearchParamsWithFilterModelOpts = {
  /** Current URL search parameters */
  searchParams: URLSearchParams;
  /** MUI DataGrid filter model containing active filters */
  filterModel: GridFilterModel;
  /** Callback to update the URL search parameters */
  setSearchParams: (searchParams: URLSearchParams) => void;
  /** Map of filter model ids to query param names */
  filterModelToQueryParamMap: Record<string, 'start_date' | 'end_date'>;
};

/**
 * Synchronizes the MUI DataGrid filter model with URL search parameters
 *
 * This function takes the current filter model state from MUI DataGrid and updates
 * the URL search parameters accordingly.
 *
 * @param opts - Options for synchronizing search parameters
 * @param opts.searchParams - Current URL search parameters
 * @param opts.filterModel - MUI DataGrid filter model containing active filters
 * @param opts.setSearchParams - Callback to update URL search parameters
 * @param opts.filterModelToQueryParamMap - Map of filter model ids to query param names
 *
 * @example
 * ```ts
 * syncSearchParamsWithFilterModel({
 *   searchParams: new URLSearchParams(),
 *   filterModel: {
 *     items: [
 *       { field: 'startDate', value: '2023-01-01' },
 *       { field: 'endDate', value: '2023-12-31' }
 *     ]
 *   },
 *   setSearchParams: (params) => {
 *     // Update URL params
 *   },
 *   filterModelToQueryParamMap: {
 *     startDate: 'start_date',
 *     endDate: 'end_date',
 *   },
 * });
 * ```
 */
export const syncSearchParamsWithFilterModel = ({
  searchParams,
  filterModel,
  setSearchParams,
  filterModelToQueryParamMap,
}: SyncSearchParamsWithFilterModelOpts) => {
  const searchParamsClone = new URLSearchParams(searchParams);
  let shouldSync = false;

  for (const item of filterModel.items) {
    if (!item.id) {
      continue;
    }

    const queryParamName = filterModelToQueryParamMap[item.id];
    if (!queryParamName) {
      continue;
    }

    if (item.value instanceof Date) {
      searchParamsClone.set(queryParamName, dateToDateString(item.value));
    } else {
      searchParamsClone.set(queryParamName, item.value);
    }
    shouldSync = true;
  }

  if (shouldSync) {
    setSearchParams(searchParamsClone);
  }
};

// utilites for saving and loading filter model to local storage
export const filterModelToLocalStorage = (filterModel: GridFilterModel): string => {
  // clone the object
  const filterModelClone: GridFilterModel = JSON.parse(JSON.stringify(filterModel));

  // filter out the portfolio filter if it exists
  filterModelClone.items = filterModelClone.items.filter((item) => item.field !== 'portfolio');

  return JSON.stringify(filterModelClone);
};

export const filterModelFromLocalStorage = (filterModel: string): GridFilterModel => {
  // clone the object
  const filterModelClone: GridFilterModel = JSON.parse(JSON.stringify(filterModel));

  // filter out the portfolio filter if it exists
  filterModelClone.items = filterModelClone.items.filter((item) => item.field !== 'portfolio');

  return filterModelClone;
};

export type GlobalFilters = {
  filters: Filter[];
};

export const useGlobalFilters = (): GlobalFilters => {
  const matches = useMatches();
  const [, setSearchParams] = useSearchParams();
  const filterEls: Filter[] = [];
  const { options, selectedFilters, onGlobalFiltersChange } = useGlobalFilterContext();

  const onChange = useCallback(
    (filters: Partial<GlobalFilterContext['selectedFilters']>) => {
      setSearchParams((prev) => {
        const searchParamsClone = new URLSearchParams(prev);

        for (const [key, value] of Object.entries(filters)) {
          searchParamsClone.delete(key);

          if (Array.isArray(value)) {
            const searchParamName =
              filterToSearchParamMap[key as keyof typeof filterToSearchParamMap] ?? key;
            searchParamsClone.delete(searchParamName);

            for (const item of value) {
              searchParamsClone.append(searchParamName, item);
            }
          } else if (key === 'date') {
            searchParamsClone.set('start_date', dateToDateString(value.start));
            searchParamsClone.set('end_date', dateToDateString(value.end));
          } else {
            // Do nothing
          }
        }

        return searchParamsClone;
      });

      onGlobalFiltersChange({
        date: selectedFilters.date,
        portfolios: selectedFilters.portfolios,
        serviceProviders: selectedFilters.serviceProviders,
        regions: selectedFilters.regions,
        ...filters,
      });
    },
    [onGlobalFiltersChange, selectedFilters, setSearchParams],
  );

  const monthRangePickerFilterHandler = useCallback(
    (selectedRange: SelectedDateRange) => {
      onChange({
        date: selectedRange.start && selectedRange.end ? selectedRange : getDefaultMonthRange(),
      });
    },
    [onChange],
  );

  if (showFilter('filterPeriod', matches)) {
    const monthRangePickerFilter = getMonthRangePickerFilter({
      value: selectedFilters.date,
      eventHandler: monthRangePickerFilterHandler,
    });
    filterEls.push(monthRangePickerFilter);
  }

  // Portfolios
  const portfolioFilterHandler = useCallback(
    (selectedItems: string[]) => {
      onChange({
        portfolios: selectedItems,
      });
    },
    [onChange],
  );

  if (showFilter('filterPortfolios', matches)) {
    const filterPortfolios = getFilterItem(options.portfolios, selectedFilters.portfolios);
    const portfolioFilterPros = {
      options: options.portfolios,
      value: filterPortfolios.length ? filterPortfolios : [],
    };

    const portfolioFilter = getDropDownFilter({
      name: 'portfolioDropDown',
      label: 'Portfolio',
      ...portfolioFilterPros,
      eventHandler: portfolioFilterHandler,
    });
    filterEls.push(portfolioFilter);
  }

  // Service Provider
  const serviceProviderFilterHandler = useCallback(
    (selectedItems: string[]) => {
      onChange({
        serviceProviders: selectedItems,
      });
    },
    [onChange],
  );

  if (showFilter('filterServiceProviders', matches)) {
    const filterServiceProviders = getFilterItem(
      options.serviceProviders,
      selectedFilters.serviceProviders,
    );
    const serviceProviderFilterProps = {
      options: options.serviceProviders,
      value: filterServiceProviders.length ? filterServiceProviders : [],
    };

    const serviceProviderFilter = getDropDownFilter({
      name: 'serviceProviderDropDown',
      label: 'Service Provider',
      ...serviceProviderFilterProps,
      eventHandler: serviceProviderFilterHandler,
    });
    filterEls.push(serviceProviderFilter);
  }

  // Regions
  const regionFilterHandler = useCallback(
    (selectedItems: string[]) => {
      onChange({
        regions: selectedItems,
      });
    },
    [onChange],
  );

  if (showFilter('filterRegions', matches)) {
    const filterRegions = getFilterItem(options.regions, selectedFilters.regions);
    const regionFilterProps = {
      options: options.regions,
      value: filterRegions.length ? filterRegions : [],
    };

    const regionFilter = getDropDownFilter({
      name: 'regionDropDown',
      label: 'Region',
      ...regionFilterProps,
      eventHandler: regionFilterHandler,
    });
    filterEls.push(regionFilter);
  }

  return { filters: filterEls };
};

function getFilterItem(items: { id: string }[], queryParams: string[]) {
  return items.map((p) => p.id).filter((id) => queryParams.includes(id));
}

function showFilter(filterName: string, matches: ReturnType<typeof useMatches>) {
  const routesToFilter = routesToFilterForFilterName[filterName];
  const lastRoute = matches[matches.length - 1].id;

  return !routesToFilter.includes(lastRoute);
}

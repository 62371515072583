import { z } from 'zod';
import { api_carSchema, api_portfolioSchema, api_propertySchema, api_serviceProviderSchema, } from './certificate';
export const inspectionReportSchema = z.object({
    id: z.string(),
    car: api_carSchema.nullable(),
    property: api_propertySchema,
    portfolio: api_portfolioSchema,
    serviceProvider: api_serviceProviderSchema.nullable(),
    inspectionDate: z.string(),
    inspectionType: z.enum(['compliance', 'periodic']),
    customType: z.string().nullable(),
    inspectionTypeDisplay: z.string(),
    status: z.enum(['passed', 'failed', 'shutdown']),
    inspectorName: z.string(),
    comments: z.string(),
    violationCount: z.number(),
    createdAt: z.string(),
    metadata: z.array(z.any()),
    downloadUrl: z.string().nullable(),
});
export const inspectionReportMetadataSchema = z.object({
    totalInspections: z.number(),
    passedInspections: z.number(),
    failedInspections: z.number(),
    shutdownInspections: z.number(),
    inspectionsByType: z.record(z.number()),
    inspectionsWithViolations: z.number(),
});
export const inspectionReportFilterResponseSchema = z.object({
    metadata: inspectionReportMetadataSchema,
    results: z.array(inspectionReportSchema),
});
export const inspectionReportCreateSchema = z.object({
    car_id: z.string().nullable(),
    inspection_date: z.string().nullable(),
    inspection_type: z.enum(['compliance', 'periodic', 'other']).nullable(),
    custom_type: z.string().nullable(),
    status: z.enum(['passed', 'failed', 'shutdown']).nullable(),
    inspector_name: z.string(),
    comments: z.string(),
    violation_ids: z.array(z.string()),
    attachment: z.string().nullable(),
});
export const inspectionReportAttachmentDetails = z.object({
    id: z.string(),
    name: z.string(),
    isPrimary: z.boolean(),
    isNextPage: z.boolean(),
    pageNumber: z.number(),
    attachment: z.string(),
});
export const api_inspectionReportCreateResponse = z.object({
    id: z.string(),
    inspectionDate: z.string(),
    inspectionType: z.string(),
    customType: z.string(),
    status: z.enum(['passed', 'failed', 'shutdown']),
    inspectorName: z.string(),
    comments: z.string(),
});

import { zodResolver } from '@hookform/resolvers/zod';
import {
  ContractHourlyRateForm,
  contractHourlyRateFormSchema,
  ContractHourType,
  ContractHourTypeOptions,
  ContractWorkerType,
  ContractWorkerTypeOptions,
  ContractWorkType,
  ContractWorkTypeOptions,
} from '@liftai/asset-management-types';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';

import { ContractFormProps, LAIUseFormReturn } from '~/components/contracts/ContractDetailsForm';
import { FieldAndCell } from '~/components/form/utils';

const defaultContractHourlyRateValues = {
  hourType: ContractHourType.Regular,
  workerType: ContractWorkerType.Mechanic,
  workType: ContractWorkType.Labor,
  hourRate: 0,
  coveredByContract: false,
};

export const useContractHourlyRatesForm = (): LAIUseFormReturn<ContractHourlyRateForm> => {
  const hookForm = useForm<ContractHourlyRateForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      hourlyRates: [defaultContractHourlyRateValues],
    },
    resolver: zodResolver(contractHourlyRateFormSchema),
  });

  return {
    hookForm,
    Fields: ContractHourlyRatesFormFields,
    Form: ContractHourlyRatesForm,
  };
};

const ContractHourlyRatesForm: FC<ContractFormProps<ContractHourlyRateForm>> = ({
  children,
  onSubmit,
  initialData,
}) => {
  const { handleSubmit, reset } = useFormContext<ContractHourlyRateForm>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset({ ...initialData });
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(async (data) => {
        const rates = contractHourlyRateFormSchema.parse(data);

        onSubmit(rates);
      })}
    >
      {children}
    </form>
  );
};

const ContractHourlyRatesFormFields: FC<{ onDelete?: (id: string) => Promise<void> }> = ({
  onDelete,
}) => {
  const { control } = useFormContext<ContractHourlyRateForm>();
  const { fields, remove, append } = useFieldArray<
    ContractHourlyRateForm,
    'hourlyRates',
    'fieldId'
  >({
    control,
    name: 'hourlyRates',
    keyName: 'fieldId',
  });
  const handleAddRow = () => {
    append(defaultContractHourlyRateValues);
  };

  return (
    <Box>
      <TableContainer sx={{ minWidth: '868px' }}>
        <Table aria-label="Hourly Rates">
          <TableHead>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>Value</TableCell>
              <TableCell align="right" />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((rate, index) => (
              <TableRow key={rate.fieldId} sx={{ '& td, & th': { padding: '8px' } }}>
                <TableCell component="th" scope="row">
                  <FieldAndCell
                    key={`hourlyRates.${index}.hourType`}
                    field={{
                      name: `hourlyRates.${index}.hourType`,
                      label: 'Hour Type',
                      type: 'select',
                      options: Object.entries(ContractHourTypeOptions).map(([key, value]) => ({
                        id: key,
                        label: value,
                      })),
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell align="left">
                  <FieldAndCell
                    key={`hourlyRates.${index}.workerType`}
                    field={{
                      name: `hourlyRates.${index}.workerType`,
                      label: 'Worker Type',
                      type: 'select',
                      options: Object.entries(ContractWorkerTypeOptions).map(([key, value]) => ({
                        id: key,
                        label: value,
                      })),
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell align="left">
                  <FieldAndCell
                    key={`hourlyRates.${index}.workType`}
                    field={{
                      name: `hourlyRates.${index}.workType`,
                      label: 'Work Type',
                      type: 'select',
                      options: Object.entries(ContractWorkTypeOptions).map(([key, value]) => ({
                        id: key,
                        label: value,
                      })),
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell width="5px">:</TableCell>
                <TableCell align="right" sx={{ width: '138px' }}>
                  <FieldAndCell
                    key={`hourlyRates.${index}.hourRate`}
                    field={{
                      name: `hourlyRates.${index}.hourRate`,
                      type: 'currency',
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell align="right">
                  <FieldAndCell
                    key={`hourlyRates.${index}.coveredByContract`}
                    field={{
                      name: `hourlyRates.${index}.coveredByContract`,
                      label: 'Covered by Contract',
                      type: 'checkbox',
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={async () => {
                      if (!onDelete) {
                        // TODO(adam): I tried to make this required but the type checker doesn't like it
                        // and refactoring is too difficult fo rnow
                        console.error('onDelete is not defined');
                        return;
                      }

                      if (rate.id) {
                        await onDelete(rate.id);
                      }

                      remove(index);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button sx={{ marginTop: 2 }} onClick={handleAddRow}>
        + Add Row
      </Button>
    </Box>
  );
};

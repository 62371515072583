import type { SvgIconComponent } from '@mui/icons-material';
import Article from '@mui/icons-material/Article';
import type { SvgIconProps, SxProps, Theme } from '@mui/material';
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import type { QuickFilterProps } from '~/components/common/QuickFilterInput';
import { QuickFilterInput } from '~/components/common/QuickFilterInput';
import type { Filter } from '~/hooks/useGlobalFilters';

type PageHeaderProps =
  | {
      title?: never;
      titleAddon?: never;
      titleGridStyle?: never;
      icon?: never;
      quickFilterProps?: QuickFilterProps;
      filters?: Filter[];
      filterGridStyle?: SxProps<Theme>;
      titleRight?: string | null;
    }
  | {
      title: string;
      titleAddon?: React.ReactNode;
      titleGridStyle?: SxProps<Theme>;
      icon?: SvgIconComponent | React.FC<SvgIconProps>;
      quickFilterProps?: QuickFilterProps;
      filters?: Filter[];
      filterGridStyle?: SxProps<Theme>;
      titleRight?: string | null;
    };

export const QUICK_FILTER_DEBOUNG_TIME_MS = 500;

export const PageHeader = ({
  title,
  quickFilterProps,
  titleAddon,
  filters = [],
  icon: Icon = Article,
  filterGridStyle,
  titleGridStyle,
  titleRight,
}: PageHeaderProps) => {
  return (
    <>
      {filters.length > 0 && (
        <>
          <Grid
            container
            pb={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
            sx={filterGridStyle}
          >
            {filters.map(({ name, component }) => (
              <Grid key={name} display="flex" alignItems="center">
                {component}
              </Grid>
            ))}
          </Grid>
          <Divider />
        </>
      )}
      {title || quickFilterProps ? (
        <Grid bgcolor="#F9FAFB" container py={2} justifyContent="space-between" sx={titleGridStyle}>
          {title ? (
            <Grid size={{ sm: 6, md: 6 }} p={0} container alignItems="center">
              <Box pr={1}>
                <Icon color="primary" sx={{ verticalAlign: 'bottom' }} fontSize="large" />
              </Box>
              <Typography variant="h5">{title}</Typography>
              {titleAddon}
            </Grid>
          ) : null}
          <Grid
            size={{ sm: 6, md: 6 }}
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            display="flex"
          >
            {titleRight ? (
              <Box
                sx={{
                  color: 'rgba(0, 0, 0, 0.60)',
                  fontSize: '14px',
                  lineHeight: 'normal',
                  alignContent: 'space-around',
                }}
              >
                {titleRight}
              </Box>
            ) : null}
            {quickFilterProps ? (
              <Grid display="flex" alignItems="center">
                <QuickFilterInput {...quickFilterProps} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

import type { API_InvoiceFilterResponse } from '@liftai/asset-management-types';
import {
  InvoiceKind,
  invoiceStatusToColorMap,
  invoiceStatusToLabelMap,
} from '@liftai/asset-management-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import { toDateFormatMMddyyyy } from '~/utils/format';

interface IPendingInvoiceProps {
  invoice: API_InvoiceFilterResponse['results'][number];
  onInvoiceSelected: (invoiceId: string) => void;
}

const PendingInvoice: React.FC<IPendingInvoiceProps> = ({ invoice, onInvoiceSelected }) => {
  const handleOnInvoiceSelected = () => {
    onInvoiceSelected(invoice.id);
  };
  return (
    <ListItem disablePadding divider>
      <ListItemButton
        onClick={handleOnInvoiceSelected}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="body2" gutterBottom color="text.secondary">
            {toDateFormatMMddyyyy(invoice.date)} - {invoice.property.name}
          </Typography>
          <Typography variant="body1">
            {invoice.kind === InvoiceKind.Invoice ? 'Invoice' : 'Proposal'} #{invoice.number}
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          color={invoiceStatusToColorMap.get(invoice.status)}
          label={invoiceStatusToLabelMap.get(invoice.status)}
        />
      </ListItemButton>
    </ListItem>
  );
};

interface IPendingInvoicesProps {
  onInvoiceSelected: (invoiceId: string) => void;
  pendingInvoices?: API_InvoiceFilterResponse['results'];
  onClose: () => void;
}

const PendingInvoices: React.FC<IPendingInvoicesProps> = ({
  onInvoiceSelected,
  pendingInvoices,
  onClose,
}) => {
  if (pendingInvoices === undefined) {
    return <Box>Loading...</Box>;
  }

  return (
    <>
      <Box px={3} py={2} display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h1" gutterBottom>
          Review Pending
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box>
        {pendingInvoices.length > 0 ? (
          <>
            <Typography p={2} variant="subtitle1" component="h2" gutterBottom>
              Assigned to you
            </Typography>
            <List>
              {pendingInvoices.map((invoice, index) => (
                <PendingInvoice
                  key={index}
                  invoice={invoice}
                  onInvoiceSelected={onInvoiceSelected}
                />
              ))}
            </List>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <img
              src="/images/no-pending-invoices.svg"
              alt="No pending Invoices or Proposals"
              style={{ height: '200px' }}
            />
            <Typography variant="h6" mt={2}>
              No pending Invoices or Proposals assigned to you
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PendingInvoices;

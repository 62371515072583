import z from 'zod';
export const userSchema = z.object({
    id: z.string(),
    fullName: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    consultantGroup: z.string().nullable().optional(),
    clientGroup: z.string().nullable().optional(),
    role: z.enum(['client', 'service_provider', 'consultant']).nullable().optional(),
    isLiftaiAdmin: z.boolean(),
});
export const userDisplaySchema = userSchema.pick({
    id: true,
    fullName: true,
    email: true,
    phoneNumber: true,
});

import z from 'zod';
import { baseLinkedAttachmentSchema } from './attachment';
import { carDisplaySchema } from './car';
import { lineItemSchema } from './lineItem';
import { propertyDisplaySchema } from './property';
import { providerDisplaySchema } from './provider';
import { userDisplaySchema, userSchema } from './user';
import { noFutureDateTime } from './utils/zodHelpers';
export const invoiceAttachmentSchema = baseLinkedAttachmentSchema.extend({
    isVoid: z.boolean(),
});
export const InvoiceType = {
    Maintenance: 'maintenance',
    Modernization: 'modernization',
    Callback: 'callback',
    Inspection: 'inspection',
    Repair: 'repair',
};
export const InvoiceStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    PartialApproval: 'partial-approval',
    PendingData: 'pending-data',
    Reset: 'reset',
};
export const InvoiceKind = {
    Invoice: 'invoice',
    Proposal: 'proposal',
};
export var InvoiceActionType;
(function (InvoiceActionType) {
    InvoiceActionType["APPROVE"] = "APPROVE";
    InvoiceActionType["REJECT"] = "REJECT";
    InvoiceActionType["PARTIAL"] = "PARTIAL";
    InvoiceActionType["REQUEST_DATA"] = "REQUEST_DATA";
    InvoiceActionType["RESET"] = "RESET";
})(InvoiceActionType || (InvoiceActionType = {}));
export const invoiceKindOptions = [
    [InvoiceKind.Invoice, 'Invoice'],
    [InvoiceKind.Proposal, 'Proposal'],
];
export const invoiceKindValues = Object.values(InvoiceKind);
export const invoiceTypeOptions = [
    [InvoiceType.Maintenance, 'Maintenance'],
    [InvoiceType.Modernization, 'Modernization'],
    [InvoiceType.Callback, 'Callback'],
    [InvoiceType.Inspection, 'Inspection'],
    [InvoiceType.Repair, 'Repair'],
];
export const invoiceTypeValues = Object.values(InvoiceType);
export const invoiceStatusOptions = [
    [InvoiceStatus.Pending, 'Pending'],
    [InvoiceStatus.Approved, 'Approved'],
    [InvoiceStatus.Rejected, 'Rejected'],
    [InvoiceStatus.PartialApproval, 'Partial Approval'],
    [InvoiceStatus.PendingData, 'Pending Data'],
    // This will never be used since the API will set the status to `Pending` when the invoice is reset
    // but it is needed to make the type system happy
    [InvoiceStatus.Reset, 'Reset'],
];
export const invoiceStatusValues = Object.values(InvoiceStatus);
export const invoiceKindToLabelMap = new Map(invoiceKindOptions);
export const invoiceTypeToLabelMap = new Map(invoiceTypeOptions);
export const invoiceStatusToLabelMap = new Map(invoiceStatusOptions);
export const invoiceStatusToColorMap = new Map([
    [InvoiceStatus.Approved, 'success'],
    [InvoiceStatus.Rejected, 'error'],
    [InvoiceStatus.Pending, 'info'],
    [InvoiceStatus.PendingData, 'info'],
    [InvoiceStatus.PartialApproval, 'warning'],
    // This will never be used since the API will set the status to `Pending` when the invoice is reset
    // but it is needed to make the type system happy
    [InvoiceStatus.Reset, 'warning'],
]);
export const RelatedInvoiceSchema = z.object({
    id: z.string(),
    number: z.string(),
    status: z.enum(invoiceStatusValues),
    reviewedAmount: z.string(),
    proposedAmount: z.string(),
    kind: z.enum(invoiceKindValues),
    attachments: z.array(invoiceAttachmentSchema).default([]),
});
export const insightSchema = z.object({
    insight: z.string(),
    reasoning: z.string(),
});
export const insightStatus = z.enum(['pending', 'processing', 'completed', 'failed']);
export const insightsSchema = z.object({
    insights: z.array(insightSchema).default([]),
    shouldBePaid: z.boolean(),
    primaryInsight: z.string(),
    runId: z.string(),
    timestamp: z.string(),
});
export const invoiceSchema = z.object({
    id: z.string(),
    number: z.string().min(1, { message: 'This field is required' }),
    date: noFutureDateTime(),
    kind: z.enum(invoiceKindValues),
    type: z.enum(invoiceTypeValues),
    status: z.enum(invoiceStatusValues),
    property: propertyDisplaySchema,
    serviceProvider: providerDisplaySchema.nullable(),
    carsServiced: z.array(carDisplaySchema),
    consultant: userDisplaySchema.nullable(),
    region: z.string().optional(),
    lineItems: z.array(lineItemSchema),
    proposedAmount: z.coerce.number().optional(),
    reviewedAmount: z.coerce.number().optional().nullable(),
    savings: z.coerce.number().optional().nullable(),
    description: z.string().optional(),
    reason: z.string().optional(),
    dateStamped: z.string().nullable().optional(),
    stampFile: z.string().nullable(),
    attachments: z.array(invoiceAttachmentSchema).default([]),
    addStampedReason: z.boolean().optional(),
    countTowardsSavings: z.boolean().optional(),
    consultantGroup: z.string().nullable(),
    autoUpload: z.boolean().default(false),
    relatedInvoices: z.array(RelatedInvoiceSchema).default([]),
    insights: insightsSchema.nullable(),
    insightsStatus: insightStatus.nullable(),
});
export const isInvoiceAutoUpload = (invoice) => {
    return invoice.autoUpload;
};
export const isInvoiceFinal = ({ status }) => [
    InvoiceStatus.Approved,
    InvoiceStatus.PartialApproval,
    InvoiceStatus.Rejected,
].includes(status);
export const invoiceDisplaySchema = invoiceSchema.pick({
    id: true,
    number: true,
    property: true,
});
export const invoiceFormValidator = (val, ctx) => {
    const proposedAmount = val.proposedAmount ? parseFloat(val.proposedAmount.toFixed(2)) : 0.0;
    const lineItemsTotal = parseFloat((val.lineItems ?? []).reduce((acc, curr) => acc + curr.amount, 0).toFixed(2));
    if (lineItemsTotal > proposedAmount) {
        return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'The total of the line items cannot be greater than the proposed amount',
            path: ['lineItems'],
        });
    }
    return true;
};
/* We should validate that line items do not add up to be greater than the proposedAmount but we do not need to validate that they are equal
 * because the proposedAmount is optional and the reviewedAmount is the one that is used to determine if the invoice is approved or not
 */
export const invoiceFormBaseSchema = invoiceSchema
    .extend({
    propertyId: z.string(),
    consultantId: z.string(),
    serviceProviderId: z.string(),
    carIds: z.array(z.string()),
})
    .omit({
    property: true,
    carsServiced: true,
    consultant: true,
    serviceProvider: true,
    attachments: true,
    consultantGroup: true,
    relatedInvoices: true,
    insights: true,
    insightsStatus: true,
});
export const invoiceTicketSchema = z.object({
    id: z.string(),
    number: z.string(),
    property: z.string(),
    invoice: z.string(),
});
const InvoiceWithTickets = invoiceSchema.extend({
    tickets: z.array(invoiceTicketSchema),
});
/** `isInvoice` guard checks if an object is an `Invoice`'s instance */
export function isInvoice(object) {
    return invoiceKindValues.some((val) => val === object.kind);
}
// This refactor helps to address the shortcoming of Zod with regards to refine/superRefine.
// The issue is that Zod does not support partial validation.
// This is a workaround that can be removed once Zod is updated to support partial validation.
// See the following issue for more details:
// https://github.com/colinhacks/zod/issues/479
// And specifically:
// https://github.com/colinhacks/zod/issues/479#issuecomment-855944023
const invoiceFormPartial = invoiceFormBaseSchema.partial();
export const invoiceFormSchema = invoiceFormBaseSchema.superRefine(invoiceFormValidator);
export const invoiceFormSchemaPartial = invoiceFormPartial.superRefine(invoiceFormValidator);
export const partialApproveInvoiceFormSchema = invoiceSchema
    .pick({
    reviewedAmount: true,
    reason: true,
    proposedAmount: true,
})
    .extend({
    reason: z.string().min(1, { message: 'This field is required' }),
    reviewedAmount: z.number().min(0, { message: 'This field is required' }),
    addStampedReason: z.boolean().optional(),
    savings: z.number().optional().default(0),
})
    .superRefine((val, ctx) => {
    const proposedAmount = val.proposedAmount ? parseFloat(val.proposedAmount.toFixed(2)) : 0.0;
    const reviewedAmount = val.reviewedAmount ? parseFloat(val.reviewedAmount.toFixed(2)) : 0.0;
    if (reviewedAmount > proposedAmount) {
        return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'The reviewed amount cannot be greater than the invoice amount',
            path: ['reviewedAmount'],
        });
    }
    return true;
});
export const rejectInvoiceFormSchema = invoiceSchema
    .pick({
    reason: true,
    reviewedAmount: true,
    proposedAmount: true,
})
    .extend({
    reason: z.string().min(1, { message: 'This field is required' }),
    addStampedReason: z.boolean().optional(),
    countTowardsSavings: z.boolean().optional(),
    savings: z.number().optional().default(0),
});
export const resetInvoiceFormSchema = z.object({ reason: z.string() });
export const approveInvoiceFormSchema = invoiceSchema
    .pick({
    reason: true,
    proposedAmount: true,
})
    .extend({
    addStampedReason: z.boolean().optional(),
    savings: z.number().optional().default(0),
});
export const InvoiceNoteSchema = z.object({
    id: z.string(),
    createdAt: z.string(),
    content: z.string(),
    author: userSchema,
});

import type {
  BaseLinkedAttachment,
  Invoice,
  InvoiceAttachment,
  Ticket,
  TicketAttachment,
} from '@liftai/asset-management-types';
import html2canvas from 'html2canvas';
import type { PDFImage, PDFPage } from 'pdf-lib';
import { degrees, PageSizes, PDFDocument, rgb } from 'pdf-lib';
import { useCallback, useRef } from 'react';
import useSWR, { type Fetcher } from 'swr';

import {
  Stamp,
  StampedInvoiceTemplate,
} from '~/components/invoices/stampedInvoice/StampedInvoiceTemplate';
import { getApiClient } from '~/utils/api';

// Configuration constants for stamp rendering and positioning
const STAMP_CONFIG = {
  // Delay needed to ensure DOM elements are fully rendered
  RENDER_DELAY_MS: 200,
  // Stamp size and positioning
  WIDTH_RATIO: 0.3,
  TOP_MARGIN: 80,
  RIGHT_MARGIN: 40,
  // Visual properties
  OPACITY: 0.85,
  // Additional padding for stamp dimensions
  WIDTH_PADDING: 80,
  HEIGHT_PADDING: 40,
  // X-offset for non-rotated pages
  NON_ROTATED_X_OFFSET: 50,
  // Additional right margin for 270° rotation
  ROTATED_RIGHT_MARGIN_ADDITION: 100,
  // Canvas rendering configuration
  CANVAS_SCALE: 3,
  CANVAS_BASE_WIDTH: 300,
} as const;

const stampLogoFetcher: Fetcher<string, readonly [key: 'invoiceStampLogo', url: string]> = async ([
  _,
  url,
]) => {
  const cacheBuster = new Date().getTime();
  const response = await fetch(`${url}?cacheBuster=${cacheBuster}`);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

const triggerDownload = (invoiceNumber: string, blobPart: Uint8Array) => {
  const blob = new Blob([blobPart], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  const id = new Date().getTime();
  link.download = `stamped_invoice-${invoiceNumber}-${id}.pdf`;
  link.click();
};

const StampedInvoiceContainer = ({
  invoice,
  stampLogo,
  containerRef,
}: {
  invoice: Invoice;
  stampLogo: string;
  containerRef: React.RefObject<HTMLDivElement>;
}) => (
  <>
    <div
      id="cover-page"
      ref={containerRef}
      style={{
        zIndex: -99999,
        position: 'fixed',
        top: '-1000px',
        width: '65%',
      }}
    >
      <StampedInvoiceTemplate invoice={invoice} containerRef={containerRef} stampLogo={stampLogo} />
    </div>
    <div
      id="stamp-only-wrapper"
      style={{
        zIndex: -99999,
        position: 'fixed',
        top: '-2000px',
        width: '180px',
        backgroundColor: '#fff',
        padding: '0',
        margin: '0',
      }}
    >
      <div
        id="stamp-only"
        style={{
          position: 'relative',
          width: '100%',
          display: 'block',
          padding: '0',
          margin: '0',
          backgroundColor: '#fff',
        }}
      >
        <Stamp invoice={invoice} status={invoice.status} stampLogo={stampLogo} />
      </div>
    </div>
  </>
);

export const useStampedInvoice = ({ invoice }: { invoice: Invoice | null | undefined }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isSupportingData = (a: BaseLinkedAttachment) => a.isPrimary === false;
  const { data: stampLogo } = useSWR(
    invoice?.stampFile ? ['invoiceStampLogo', invoice.stampFile] : null,
    stampLogoFetcher,
    {},
  );

  const downloadStampedInvoice = useCallback(
    async ({ includeSupportingData = false }: { includeSupportingData?: boolean } = {}) => {
      if (!invoice) {
        console.error('Invoice is not available');
        return;
      }

      const apiClient = getApiClient();

      if (!containerRef.current) {
        console.error('Container reference is not available');
        return;
      }
      const builder = new StampedInvoiceBuilder(containerRef.current);

      const invoiceAttachments = invoice.attachments || [];
      const relatedInvoiceAttachments =
        invoice.relatedInvoices?.flatMap((relatedInvoice) => {
          return relatedInvoice.attachments || [];
        }) || [];
      const invoiceNumber = invoice.number;

      if (!includeSupportingData) {
        builder.withInvoiceAttachments(invoiceAttachments.filter((inv) => !isSupportingData(inv)));
      } else {
        const tickets: Ticket[] = await apiClient.tickets.getByInvoiceId(invoice.id);
        const ticketAttachments = tickets.flatMap((ticket) => ticket.attachments || []);
        builder
          .withInvoiceAttachments(invoiceAttachments)
          .withRelatedInvoicesAttachments(relatedInvoiceAttachments)
          .withTicketsAttachments(ticketAttachments);
      }
      const pdfBlob = await builder.build();

      return triggerDownload(invoiceNumber, pdfBlob);
    },
    [invoice],
  );

  return {
    downloadStampedInvoice,
    /**
     * The reason behind this approach is that
     * html2canvas library used by the
     * StampedInvoice component requires the
     * component to be present in the DOM in order to
     * generate the PDF.
     */
    StampedInvoiceContainer: useCallback(
      (props: { invoice: Invoice }) =>
        stampLogo ? (
          <StampedInvoiceContainer {...props} containerRef={containerRef} stampLogo={stampLogo} />
        ) : null,
      [containerRef, stampLogo],
    ),
  };
};

export class StampedInvoiceBuilder {
  private invoiceAttachments: InvoiceAttachment[] = [];
  private ticketAttachments: TicketAttachment[] = [];
  private relatedInvoiceAttachments: InvoiceAttachment[] = [];
  private readonly containerEl: HTMLDivElement;
  private invoiceAttachmentComparator:
    | ((a: InvoiceAttachment, b: InvoiceAttachment) => number)
    | null = null;

  constructor(containerEl: HTMLDivElement) {
    this.containerEl = containerEl;
  }

  setInvoiceAttachmentComparator(
    comparator: (a: InvoiceAttachment, b: InvoiceAttachment) => number,
  ): void {
    this.invoiceAttachmentComparator = comparator;
  }

  getInvoiceAttachmentComparator(): (a: InvoiceAttachment, b: InvoiceAttachment) => number {
    return (
      this.invoiceAttachmentComparator || StampedInvoiceBuilder.defaultInvoiceAttachmentComparator
    );
  }

  withInvoiceAttachments(attachments: InvoiceAttachment[]): StampedInvoiceBuilder {
    this.invoiceAttachments.push(...attachments);
    return this;
  }

  withRelatedInvoicesAttachments(attachments: InvoiceAttachment[]): StampedInvoiceBuilder {
    this.relatedInvoiceAttachments.push(...attachments);
    return this;
  }

  withTicketsAttachments(attachments: TicketAttachment[]): StampedInvoiceBuilder {
    this.ticketAttachments.push(...attachments);
    return this;
  }

  async build(): Promise<Uint8Array> {
    const aDocument = await PDFDocument.create();
    await this.addStampedPage(aDocument);
    await this.rebuildInvoicePDF(aDocument, this.invoiceAttachments);
    await this.rebuildTicketsPDF(aDocument);
    await this.rebuildInvoicePDF(aDocument, this.relatedInvoiceAttachments);
    return aDocument.save();
  }

  private dataUrlToBytes(dataUrl: string): Uint8Array {
    const base64 = dataUrl.split(',')[1];
    const binaryStr = window.atob(base64);
    const bytes = new Uint8Array(binaryStr.length);

    for (let i = 0; i < binaryStr.length; i++) {
      bytes[i] = binaryStr.charCodeAt(i);
    }

    return bytes;
  }

  private async getStampImage(document: PDFDocument): Promise<{
    image: PDFImage;
    width: number;
    height: number;
  }> {
    try {
      // Wait for DOM elements to be fully rendered
      await new Promise((resolve) => setTimeout(resolve, STAMP_CONFIG.RENDER_DELAY_MS));

      const wrapper = globalThis.document.getElementById('stamp-only-wrapper');
      const stampElement = globalThis.document.getElementById('stamp-only');

      if (!wrapper || !stampElement) {
        throw new Error(
          'Required stamp elements not found. Ensure stamp-only-wrapper and stamp-only elements exist in the DOM',
        );
      }

      const contentHeight = stampElement.scrollHeight;
      wrapper.style.height = `${contentHeight}px`;

      const canvas = await html2canvas(wrapper, {
        useCORS: true,
        backgroundColor: '#fff',
        logging: process.env.NODE_ENV === 'development',
        width: STAMP_CONFIG.CANVAS_BASE_WIDTH,
        height: contentHeight,
        scale: STAMP_CONFIG.CANVAS_SCALE,
        onclone: (clonedDoc, element) => {
          const clonedWrapper = element;
          clonedWrapper.style.position = 'relative';
          clonedWrapper.style.top = '0';
          clonedWrapper.style.left = '0';
          clonedWrapper.style.transform = 'none';
          clonedWrapper.style.height = `${contentHeight}px`;
        },
      });

      const imgData = canvas.toDataURL('image/png', 1.0);
      const bytes = this.dataUrlToBytes(imgData);
      const image = await document.embedPng(bytes);

      return {
        image,
        width: canvas.width / STAMP_CONFIG.CANVAS_SCALE,
        height: canvas.height / STAMP_CONFIG.CANVAS_SCALE,
      };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error in getStampImage:', error);
      }
      throw error;
    }
  }

  private async addStampedPage(aDocument: PDFDocument): Promise<PDFDocument> {
    const coverElement = globalThis.document.getElementById('cover-page');
    if (!coverElement) {
      throw new Error('Cover page element not found');
    }

    const canvas = await html2canvas(coverElement, {
      useCORS: true,
      backgroundColor: null,
    });

    const imgData = canvas.toDataURL('image/png', 1.0);

    const bytes = this.dataUrlToBytes(imgData);

    const imgDataBytes = await aDocument.embedPng(bytes);
    const firstPage = aDocument.addPage(PageSizes.A4);
    const imgProps = firstPage.getSize();

    // Calculate the aspect ratio to fit the image within the PDF page dimensions
    const aspectRatio = canvas.width / canvas.height;
    const maxWidth = imgProps.width;
    const maxHeight = imgProps.height;
    const width = maxWidth;
    const height = Math.min(maxWidth / aspectRatio, maxHeight);

    firstPage.drawImage(imgDataBytes, {
      x: 0,
      y: imgProps.height - height,
      width: width,
      height: height,
    });

    return aDocument;
  }

  private async addStampToPage(page: PDFPage, document: PDFDocument): Promise<void> {
    const {
      image,
      width: originalWidth,
      height: originalHeight,
    } = await this.getStampImage(document);

    const { width: pageWidth, height: pageHeight } = page.getSize();
    const pageRotation = page.getRotation().angle;

    // Calculate effective page dimensions accounting for rotation
    const effectivePageWidth = pageRotation === 90 || pageRotation === 270 ? pageHeight : pageWidth;
    const effectivePageHeight =
      pageRotation === 90 || pageRotation === 270 ? pageWidth : pageHeight;

    // Calculate stamp dimensions
    const desiredStampWidth = effectivePageWidth * STAMP_CONFIG.WIDTH_RATIO;
    const scaleFactor = desiredStampWidth / originalWidth;
    const stampWidth = desiredStampWidth;
    const stampHeight = originalHeight * scaleFactor;

    // Calculate base position for top-right placement
    let x = effectivePageWidth - stampWidth - STAMP_CONFIG.RIGHT_MARGIN;
    let y = effectivePageHeight - stampHeight - STAMP_CONFIG.TOP_MARGIN;

    // Adjust coordinates based on rotation
    switch (pageRotation) {
      case 90:
        x = STAMP_CONFIG.TOP_MARGIN;
        y = effectivePageHeight - STAMP_CONFIG.RIGHT_MARGIN - stampWidth;
        break;
      case 180:
        x = STAMP_CONFIG.RIGHT_MARGIN;
        y = STAMP_CONFIG.TOP_MARGIN;
        break;
      case 270:
        x = effectivePageHeight - STAMP_CONFIG.TOP_MARGIN - stampHeight;
        y = STAMP_CONFIG.RIGHT_MARGIN + STAMP_CONFIG.ROTATED_RIGHT_MARGIN_ADDITION;
        break;
    }

    // Draw the stamp
    page.drawImage(image, {
      x: pageRotation === 0 ? x + STAMP_CONFIG.NON_ROTATED_X_OFFSET : x,
      y,
      width: stampWidth + STAMP_CONFIG.WIDTH_PADDING,
      height: stampHeight + STAMP_CONFIG.HEIGHT_PADDING,
      rotate: degrees(pageRotation),
      opacity: STAMP_CONFIG.OPACITY,
    });
  }

  private async rebuildInvoicePDF(
    aDocument: PDFDocument,
    invoiceAttachments: InvoiceAttachment[],
  ): Promise<PDFDocument> {
    const existingPdfPagesBuffer = await Promise.all(
      invoiceAttachments.sort(this.getInvoiceAttachmentComparator()).map(async (attachment) => {
        return {
          buffer: await fetch(attachment.attachmentUrl).then((res: Response) => res.arrayBuffer()),
          page: attachment.pageNumber,
          isVoid: attachment.isVoid,
          isPrimary: attachment.isPrimary,
        };
      }),
    );

    const existingPdfPages = await Promise.all(
      existingPdfPagesBuffer.map(async (attachment) => ({
        doc: await PDFDocument.load(attachment.buffer),
        page: attachment.page,
        isVoid: attachment.isVoid,
        isPrimary: attachment.isPrimary,
      })),
    );

    for (const page of existingPdfPages) {
      let [newPage] = await aDocument.copyPages(page.doc, [(page.page ?? 0) - 1]);

      if (page.isVoid) {
        newPage = this.voidPage(newPage);
      } else if (page.isPrimary) {
        await this.addStampToPage(newPage, aDocument);
      }

      aDocument.addPage(newPage);
    }

    return aDocument;
  }

  private async rebuildTicketsPDF(aDocument: PDFDocument): Promise<PDFDocument> {
    const existingPdfPagesBuffer = await Promise.all(
      this.ticketAttachments.reverse().map(async (attachment) => ({
        buffer: await fetch(attachment.attachmentUrl).then((res: Response) => res.arrayBuffer()),
        page: attachment.pageNumber,
      })),
    );

    const existingPdfPages = await Promise.all(
      existingPdfPagesBuffer.map(async (attachment) => ({
        doc: await PDFDocument.load(attachment.buffer),
        page: attachment.page,
      })),
    );
    const pdfPages: PDFPage[] = [];
    for (const page of existingPdfPages) {
      pdfPages.push(...(await aDocument.copyPages(page.doc, [(page.page ?? 0) - 1])));
    }

    for (const page of pdfPages) {
      aDocument.addPage(page);
    }

    return aDocument;
  }

  private voidPage(aPage: PDFPage): PDFPage {
    const { width, height } = aPage.getSize();
    aPage.drawText('VOID', {
      x: width / 2 - 100,
      y: height / 2 - 150,
      size: 150,
      color: rgb(1, 0, 0),
      rotate: degrees(45),
      opacity: 0.3,
    });

    return aPage;
  }

  static defaultInvoiceAttachmentComparator(prev: InvoiceAttachment, curr: InvoiceAttachment) {
    if (prev.isPrimary === curr.isPrimary) {
      if (prev.isVoid === curr.isVoid) return -1;
      return !prev.isVoid ? -1 : 1;
    }

    return prev.isPrimary ? -1 : 1;
  }
}

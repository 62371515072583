import {
  InvoiceKind,
  InvoiceKindKey,
  type InvoiceStatusKey,
  type InvoiceTypeKey,
  type InvoiceWithPortfolio,
  type InvoiceWithTickets,
} from '@liftai/asset-management-types';
import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

import { gridFilterItemToNumberFilterParams } from '~/components/table/helpers';
import { dateToDateString } from '~/components/utils/dateUtils';
import { type GlobalFilterContext, useGlobalFilterContext } from '~/contexts/globalFilterContext';
import useInvoices, { type UseInvoicesParameters } from '~/data/hooks/useInvoices';
import { syncSearchParamsWithFilterModel } from '~/hooks/useGlobalFilters';
import { mergeFilterModel, useGridFilterModel } from '~/hooks/useGridFilterModel';
import { getApiClient } from '~/utils/api';

export const invoiceWithPortfolio = (invoice: InvoiceWithTickets): InvoiceWithPortfolio => {
  return {
    ...invoice,
    portfolio: invoice.property.portfolio!,
  };
};

const selectedPeriodRangeAfterId = 'selected-period-range-after';
const selectedPeriodRangeBeforeId = 'selected-period-range-before';

export const invoiceFilterModelToQueryParamMap = {
  [selectedPeriodRangeAfterId]: 'start_date',
  [selectedPeriodRangeBeforeId]: 'end_date',
} as const;

export const getFilterModelFromQueryParams = (searchParams: URLSearchParams): GridFilterModel => {
  const items: GridFilterModel['items'] = [];

  const proposedAmount = searchParams.get('proposed_amount');
  if (proposedAmount) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '=',
      value: proposedAmount,
    });
  }

  const proposedAmountGreaterThan = searchParams.get('proposed_amount_gt');
  if (proposedAmountGreaterThan) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '>',
      value: proposedAmountGreaterThan,
    });
  }

  const proposedAmountGreaterThanEqual = searchParams.get('proposed_amount_gte');
  if (proposedAmountGreaterThanEqual) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '>=',
      value: proposedAmountGreaterThanEqual,
    });
  }

  const proposedAmountLessThan = searchParams.get('proposed_amount_lt');
  if (proposedAmountLessThan) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '<',
      value: proposedAmountLessThan,
    });
  }

  const proposedAmountLessThanEqual = searchParams.get('proposed_amount_lte');
  if (proposedAmountLessThanEqual) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '<=',
      value: proposedAmountLessThanEqual,
    });
  }

  const region = searchParams.getAll('region');
  if (region) {
    for (const r of region) {
      items.push({
        id: 'region',
        field: 'region',
        operator: 'contains',
        value: r,
      });
    }
  }

  const number = searchParams.get('number');
  if (number) {
    items.push({
      id: 'number',
      field: 'number',
      operator: 'equals',
      value: number,
    });
  }

  const serviceProviders = searchParams.getAll('service_provider');
  if (serviceProviders) {
    for (const serviceProvider of serviceProviders) {
      items.push({
        id: 'serviceProvider',
        field: 'serviceProvider',
        operator: 'contains',
        value: serviceProvider,
      });
    }
  }

  const type = searchParams.getAll('invoice_type') as InvoiceTypeKey[];
  if (type.length > 0) {
    items.push({
      id: 'type',
      field: 'type',
      operator: 'isAnyOf',
      value: type,
    });
  }

  const status = searchParams.getAll('invoice_status') as InvoiceStatusKey[];
  if (status.length > 0) {
    items.push({
      id: 'status',
      field: 'status',
      value: status,
      operator: 'isAnyOf',
    });
  }

  const property = searchParams.get('property');
  if (property) {
    items.push({
      id: 'property',
      field: 'property',
      operator: 'equals',
    });
  }

  const propertyName = searchParams.get('property_name');
  if (propertyName) {
    items.push({
      id: 'property-name',
      field: 'property',
      operator: 'contains',
      value: propertyName,
    });
  }

  return { items };
};

type BuildFilterParamsOpts = {
  propertyId: string | null;
  page: number;
  pageSize: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  searchParams: URLSearchParams;
  globalFilters: GlobalFilterContext['selectedFilters'];
};

const buildFilterParams = ({
  propertyId,
  page,
  pageSize,
  filterModel,
  sortModel,
  searchParams,
  globalFilters,
}: BuildFilterParamsOpts): UseInvoicesParameters | undefined => {
  if (pageSize <= 0 || page < 0) {
    return undefined;
  }

  const startDateStamped: Date | undefined = filterModel.items.find(
    (item) => item.field === 'dateStamped' && item.operator === 'onOrAfter',
  )?.value;
  const endDateStamped: Date | undefined = filterModel.items.find(
    (item) => item.field === 'dateStamped' && item.operator === 'onOrBefore',
  )?.value;

  return {
    limit: pageSize.toString(),
    offset: (page * pageSize).toString(),
    ordering:
      sortModel.length > 0
        ? sortModel.map((item) => `${item.sort === 'desc' ? '-' : ''}${item.field}`).join(',')
        : '-date',
    search: filterModel.quickFilterValues?.map((value) => value).join(' '),
    kind: searchParams.getAll('invoice_kind'),
    portfolio: globalFilters.portfolios,
    serviceProvider: globalFilters.serviceProviders,
    region: globalFilters.regions,
    startDate: dateToDateString(globalFilters.date.start) ?? undefined,
    endDate: dateToDateString(globalFilters.date.end) ?? undefined,
    number: filterModel.items.find((item) => item.field === 'number')?.value,
    tickets: filterModel.items.find((item) => item.field === 'tickets')?.value,
    type: filterModel.items.find((item) => item.field === 'type')?.value,
    status: filterModel.items.find((item) => item.field === 'status')?.value,
    propertyName: filterModel.items.find((item) => item.field === 'property')?.value,
    property: propertyId ?? undefined,
    proposedAmount: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'proposedAmount'),
    ),
    reviewedAmount: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'reviewedAmount'),
    ),
    savings: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'savings'),
    ),
    startDateStamped: startDateStamped ? dateToDateString(startDateStamped) : undefined,
    endDateStamped: endDateStamped ? dateToDateString(endDateStamped) : undefined,
  };
};

export type UseSpendingOptions = {
  propertyId?: string | null;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  onFilterModelChange: (filterModel: GridFilterModel) => void;
};

const defaultSortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];
const spendingViewList = [
  { value: InvoiceKind.Invoice, title: 'Invoices' },
  { value: InvoiceKind.Proposal, title: 'Proposals' },
];

type UseSpendingTableModelOpts = {
  initialFilterModel?: GridFilterModel;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
};
export function useSpendingTableModel({
  initialFilterModel,
  onFilterModelChange,
}: UseSpendingTableModelOpts = {}) {
  const [searchParams, setURLSearchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [spendingView, setSpendingView] = useState<InvoiceKindKey>(
    (searchParams.get('invoice_kind') as InvoiceKindKey) ?? InvoiceKind.Invoice,
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const filterModelFromParams = getFilterModelFromQueryParams(searchParams);

  const { filterModel, setFilterModel, quickFilterProps } = useGridFilterModel({
    initialState: initialFilterModel
      ? mergeFilterModel(initialFilterModel, filterModelFromParams)
      : filterModelFromParams,
    quickFilterPlaceHolder: `Search ${spendingView === 'invoice' ? 'Invoices' : 'Proposals'}...`,
  });

  const onViewChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newView: string) => {
      if (newView === InvoiceKind.Invoice || newView === InvoiceKind.Proposal) {
        setSpendingView(newView);

        setURLSearchParams((prev) => {
          prev.set('invoice_kind', newView);
          return prev;
        });
      }
    },
    [setURLSearchParams, setSpendingView],
  );

  // Can be used for persistence if the consuming component needs it
  useEffect(() => {
    onFilterModelChange?.(filterModel);
  }, [filterModel, onFilterModelChange]);

  return {
    sortModel,
    setSortModel,
    spendingView,
    onViewChange,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
    quickFilterProps,
    spendingViewList,
  };
}

export function useSpending({
  propertyId = null,
  paginationModel,
  filterModel,
  sortModel,
  onFilterModelChange: parentOnFilterModelChange,
}: UseSpendingOptions) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedFilters } = useGlobalFilterContext();

  const filterParams = useMemo(
    () =>
      buildFilterParams({
        propertyId,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        filterModel,
        sortModel,
        searchParams,
        globalFilters: selectedFilters,
      }),
    [propertyId, paginationModel, filterModel, sortModel, searchParams, selectedFilters],
  );

  const [filterParamsDebounced] = useDebounceValue(filterParams, 250);

  const { invoices: data, metadata, isLoading } = useInvoices(filterParamsDebounced);

  const onExport = useCallback(async () => {
    const apiClient = getApiClient();
    const { results } = await apiClient.invoices.filter({
      ...filterParams,
      limit: undefined,
      offset: undefined,
    });
    return results.map((result) => invoiceWithPortfolio(result));
  }, [filterParams]);

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      parentOnFilterModelChange(filterModel);
      syncSearchParamsWithFilterModel({
        searchParams,
        filterModel,
        setSearchParams,
        filterModelToQueryParamMap: invoiceFilterModelToQueryParamMap,
      });
    },
    [parentOnFilterModelChange, searchParams, setSearchParams],
  );

  const invoices = data?.map((invoice) => invoiceWithPortfolio(invoice)) ?? [];
  const numInvoices = metadata?.numInvoices ?? 0;

  return {
    invoices,
    numInvoices,
    metadata,
    isLoading,
    onExport,
    onFilterModelChange,
  };
}

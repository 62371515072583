import { zodResolver } from '@hookform/resolvers/zod';
import { contractReviewRulesSchema } from '@liftai/asset-management-types';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { ContractFormProps, LAIUseFormReturn } from '~/components/contracts/ContractDetailsForm';
import { FieldAndCell } from '~/components/form/utils';

const defaultContractReviewRuleValues = {
  reviewRule: '',
  reviewRuleDescription: '',
};

export const contractReviewRulesFormSchema = z.object({
  reviewRules: z.array(contractReviewRulesSchema),
});
export type ContractReviewRuleForm = z.infer<typeof contractReviewRulesFormSchema>;

export const useContractReviewRulesForm = (): LAIUseFormReturn<ContractReviewRuleForm> => {
  const hookForm = useForm<ContractReviewRuleForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      reviewRules: [defaultContractReviewRuleValues],
    },
    resolver: zodResolver(contractReviewRulesFormSchema),
  });

  return {
    hookForm,
    Fields: ContractReviewRulesFormFields,
    Form: ContractReviewRulesForm,
  };
};

const ContractReviewRulesForm: FC<ContractFormProps<ContractReviewRuleForm>> = ({
  children,
  onSubmit,
  initialData,
}) => {
  const { handleSubmit, reset } = useFormContext<ContractReviewRuleForm>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset({ ...initialData });
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(async (data) => {
        const rates = contractReviewRulesFormSchema.parse(data);

        onSubmit(rates);
      })}
    >
      {children}
    </form>
  );
};

const ContractReviewRulesFormFields: FC<{ onDelete?: (id: string) => Promise<void> }> = ({
  onDelete,
}) => {
  const { control } = useFormContext<ContractReviewRuleForm>();
  const { fields, remove, append } = useFieldArray<
    ContractReviewRuleForm,
    'reviewRules',
    'fieldId'
  >({
    control,
    name: 'reviewRules',
    keyName: 'fieldId',
  });
  const handleAddRow = () => {
    append(defaultContractReviewRuleValues);
  };

  return (
    <Box>
      <TableContainer sx={{ minWidth: '868px' }}>
        <Table aria-label="Review Rules">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((rate, index) => (
              <TableRow key={rate.fieldId}>
                <TableCell>
                  <FieldAndCell
                    key={`reviewRules.${index}.reviewRule`}
                    field={{
                      name: `reviewRules.${index}.reviewRule`,
                      type: 'text-multiline',
                      gridProps: { xs: 12 },
                    }}
                    control={control}
                  />
                </TableCell>
                <TableCell align="right" sx={{ width: 0, padding: 0 }}>
                  <IconButton
                    onClick={async () => {
                      if (!onDelete) {
                        // TODO(adam): I tried to make this required but the type checker doesn't like it
                        // and refactoring is too difficult for now
                        console.error('onDelete is not defined');
                        return;
                      }

                      if (rate.id) {
                        await onDelete(rate.id);
                      }

                      remove(index);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ marginTop: 2 }}>
        <Button sx={{ marginTop: '24px' }} onClick={handleAddRow}>
          + Add Rule
        </Button>
      </Box>
    </Box>
  );
};

import type {
  Invoice,
  InvoiceWithPortfolio,
  Portfolio,
  Property,
} from '@liftai/asset-management-types';
import {
  invoiceStatusOptions,
  invoiceStatusToColorMap,
  invoiceStatusToLabelMap,
  invoiceTypeOptions,
} from '@liftai/asset-management-types';
import { Chip } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-premium';

import CommaSeparatedList from '~/components/common/CommaSeparatedList';
import { colDefTemplate } from '~/components/table/colDefTemplate';
import {
  basicNumberComparisonOperator,
  onlyContainsFilterOperator,
  onlyIsAnyOfFilterOperator,
  onOrBeforeOrOnOrAfterFilterOperator,
} from '~/components/table/filterOperators';
import { getApplyQuickFilterFn } from '~/components/table/helpers';
import { LiftAITextLink } from '~/components/utils/helpers';
import { formatCurrency } from '~/utils/format';
import {
  buildUrlForInvoices,
  buildUrlForPropertyTickets,
  buildUrlForTickets,
  urlToHref,
} from '~/utils/urlBuilder';

type GetSpendingTableColumns = {
  currencyCode: string;
  additionalColumns?: GridColDef[];
  propertyId?: string;
  canFilterProperty?: boolean;
};

export function getSpendingTableColumns({
  currencyCode,
  additionalColumns = [],
  propertyId,
  canFilterProperty = true,
}: GetSpendingTableColumns) {
  const spendingTableCols = colDefTemplate<InvoiceWithPortfolio>({
    options: {
      autoWidth: true,
    },
    cols: [
      {
        field: 'number',
        headerName: 'Number',
        filterOperators: onlyContainsFilterOperator,
        sortable: false,
        aggregable: false,
        renderCell: ({ value }) => <LiftAITextLink label={value} style={{ cursor: 'pointer' }} />,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'singleSelect',
        aggregable: false,
        filterOperators: onlyIsAnyOfFilterOperator,
        valueOptions: invoiceTypeOptions.map(([key, value]) => ({
          value: key,
          label: value,
        })),
      },
      {
        field: 'date',
        headerName: 'Date',
        valueGetter: (value, row) => new Date(row.date),
        valueFormatter: (value: string | null) =>
          value ? new Date(value).toLocaleDateString('EN-US') : '-',
        type: 'date',
        filterable: false,
        aggregable: false,
      },
      {
        field: 'dateStamped',
        headerName: 'Date Stamped',
        aggregable: false,
        filterOperators: onOrBeforeOrOnOrAfterFilterOperator,
        valueGetter: (value, row) => (row.dateStamped ? new Date(row.dateStamped) : null),
        valueFormatter: (value: string | null) =>
          value ? new Date(value).toLocaleDateString('EN-US') : '-',
        type: 'date',
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        aggregable: false,
        filterOperators: onlyIsAnyOfFilterOperator,
        valueOptions: invoiceStatusOptions.map(([key, value]) => ({
          value: key,
          label: value,
        })),
        renderCell(params) {
          const label = invoiceStatusToLabelMap.get(params.value);
          const color = invoiceStatusToColorMap.get(params.value)!;

          if (!label || !color) {
            return null;
          }

          return <Chip label={label} color={color} variant="outlined" />;
        },
      },
      {
        field: 'property',
        headerName: 'Property',
        nested: { query: 'property.name' },
        aggregable: false,
        filterOperators: onlyContainsFilterOperator,
        filterable: canFilterProperty,
        valueFormatter: (value: Property) => value.name,
        renderCell: ({ value }) => {
          const { searchParams } = new URL(window.location.href);

          return (
            <LiftAITextLink
              label={value.name}
              target="_blank"
              href={`/portfolio/${value.id}?${searchParams}`}
              onClick={(e) => e.stopPropagation()}
            />
          );
        },
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'property.name');
        },
      },
      {
        field: 'tickets',
        headerName: 'Tickets',
        aggregable: false,
        filterOperators: onlyContainsFilterOperator,
        valueFormatter: (value: InvoiceWithPortfolio['tickets']) =>
          value.map((ticket: { number: string }) => ticket.number).join(', '),
        renderCell: ({ row }: { row: InvoiceWithPortfolio }) => {
          const searchParams = new URLSearchParams(window.location.search);
          const startDate = searchParams.get('start_date') ?? '';
          const endDate = searchParams.get('end_date') ?? '';
          const items = row.tickets.map((item, i) => {
            const url = propertyId
              ? buildUrlForPropertyTickets({
                  propertyId,
                  startDate,
                  endDate,
                  number: item.number,
                  selectedInvoiceId: row.id,
                  ticketId: item.id,
                })
              : buildUrlForTickets({
                  startDate,
                  endDate,
                  number: item.number,
                  selectedInvoiceId: row.id,
                  ticketId: item.id,
                });
            return (
              <LiftAITextLink
                key={i}
                label={item.number}
                href={urlToHref(url)}
                onClick={(e) => e.stopPropagation()}
              />
            );
          });
          return <CommaSeparatedList items={items} />;
        },
      },
      {
        field: 'relatedInvoices',
        headerName: 'Related Invoices & Proposals',
        aggregable: false,
        filterOperators: onlyContainsFilterOperator,
        valueFormatter: (value: InvoiceWithPortfolio['relatedInvoices']) =>
          value.map((invoice: { number: string }) => invoice.number).join(', '),
        renderCell: ({ row }) => {
          const searchParams = new URLSearchParams(window.location.search);
          const startDate = searchParams.get('start_date') ?? '';
          const endDate = searchParams.get('end_date') ?? '';
          const items = row.relatedInvoices.map((item, i) => {
            const url = buildUrlForInvoices({
              startDate,
              endDate,
              invoiceId: item.id,
              kind: item.kind,
            });
            return (
              <LiftAITextLink
                key={i}
                label={item.number}
                href={urlToHref(url)}
                onClick={(e) => e.stopPropagation()}
              />
            );
          });
          return <CommaSeparatedList items={items} />;
        },
      },
      {
        field: 'portfolio',
        headerName: 'Portfolio',
        nested: { query: 'portfolio.name' },
        valueFormatter: (value: Portfolio) => value.name,
        renderCell: ({ value }) => value?.name ?? '-',
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'portfolio.name');
        },
        filterable: false,
        aggregable: false,
      },
      {
        field: 'region',
        headerName: 'Region',
        filterable: false,
        sortable: false,
        aggregable: false,
      },
      {
        field: 'serviceProvider',
        headerName: 'Provider',
        nested: { query: 'serviceProvider.name' },
        filterOperators: onlyContainsFilterOperator,
        filterable: false,
        sortable: false,
        aggregable: false,
        valueFormatter: (value: Invoice['serviceProvider']) => value?.name,
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'serviceProvider.name');
        },
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
      },
      {
        field: 'carsServiced',
        headerName: 'Cars Serviced',
        filterable: false,
        sortable: false,
        aggregable: false,
        valueFormatter: (value: InvoiceWithPortfolio['carsServiced']) =>
          value.map((car: { name: string }) => car.name).join(', '),
        renderCell: ({ row }) => {
          const items = row.carsServiced.map((item, i) => <span key={i}>{item.name}</span>);
          return <CommaSeparatedList items={items} />;
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        filterable: false,
        sortable: false,
        aggregable: false,
      },
      {
        field: 'consultant',
        headerName: 'Consultant',
        filterable: false,
        sortable: false,
        aggregable: false,
        nested: { query: 'consultant.fullName' },
        valueFormatter: (value: InvoiceWithPortfolio['consultant']) => {
          return value?.fullName ?? '-';
        },
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'consultant.fullName');
        },
        sortComparator: (prev, curr, ...rest) => {
          return prev.fullName.localeCompare(curr.fullName);
        },
      },
      {
        field: 'proposedAmount',
        headerName: 'Invoice Amount',
        filterOperators: basicNumberComparisonOperator,
        aggregable: false,
        type: 'number',
        valueFormatter: (value: InvoiceWithPortfolio['proposedAmount']) =>
          value !== null ? formatCurrency({ value: value ?? 0, currencyCode }) : null,
      },
      {
        field: 'reviewedAmount',
        headerName: 'After Review',
        filterOperators: basicNumberComparisonOperator,
        aggregable: false,
        type: 'number',
        valueGetter: (_, row) => {
          if (row.status === 'approved') {
            return row.proposedAmount;
          }

          return row.reviewedAmount;
        },
        valueFormatter: (value: InvoiceWithPortfolio['reviewedAmount']) =>
          value !== null ? formatCurrency({ value: value ?? 0, currencyCode }) : null,
      },
      {
        field: 'savings',
        headerName: 'Savings',
        filterOperators: basicNumberComparisonOperator,
        aggregable: false,
        type: 'number',
        valueGetter: (value, row) => row.savings,
        valueFormatter: (value: number | null) =>
          value !== null ? formatCurrency({ value, currencyCode }) : null,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        filterable: false,
        sortable: false,
        aggregable: false,
      },
      ...additionalColumns,
    ],
  });

  return spendingTableCols;
}

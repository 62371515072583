import type { Attachment, Invoice, Ticket } from '@liftai/asset-management-types';
import { InvoiceKind } from '@liftai/asset-management-types';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useState } from 'react';
import { pdfjs } from 'react-pdf';

import AddCertificateDialog from '~/components/uploadDocument/AddCertificateDialog';
import AddInspectionReportDialog from '~/components/uploadDocument/AddInspectionReportDialog';
import AddSupportingDataDialog from '~/components/uploadDocument/AddSupportingDataDialog';
import AddViolationDialog from '~/components/uploadDocument/AddViolationDialog';
import { getDefaultInvoiceFormValues } from '~/components/uploadDocument/InvoiceForm';
import type { DocumentFlowAddedDoc } from '~/components/uploadDocument/SuccessDocumentDialog';
import SuccessDocumentDialog from '~/components/uploadDocument/SuccessDocumentDialog';
import type { SupportingDataOnSubmitData } from '~/components/uploadDocument/SupportingDataForm';

import InvoiceDialog from '../invoices/InvoiceDialog';
import { UploadDocumentType } from './AddManuallyDropdown';
import AddTicketDialog from './AddTicketDialog';
import type { LiftAIDocumentTagged } from './SelectDocumentTypeDialog';
import SelectUploadDocumentTypeDialog from './SelectDocumentTypeDialog';
import UploadDocumentDialog from './UploadDocumentDialog';

pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdfjs/pdf.worker.min.mjs';

interface LiftAIBaseDocument {
  fileType: string;
  name: string;
  attachment: Attachment;
}

interface LiftAIDocumentPDFUntagged extends LiftAIBaseDocument {
  fileType: 'pdf';
  file: pdfjs.PDFDocumentProxy;
  pageNum: number;
}

export function isLiftAIDocumentPDFUntagged(
  doc: LiftAIDocumentUntagged,
): doc is LiftAIDocumentPDFUntagged {
  return doc.fileType === 'pdf';
}

interface LiftAIDocumentImageUntagged extends LiftAIBaseDocument {
  fileType: 'image';
  file: File;
}

export type LiftAIDocumentUntagged = LiftAIDocumentPDFUntagged | LiftAIDocumentImageUntagged;

export type UploadDocumentFlowState =
  | { name: 'initial' }
  | { name: 'selectDocumentOrManualEntry' }
  | { name: 'addTicketManually' }
  | { name: 'addCertificateManually' }
  | { name: 'addInspectionReportManually' }
  | { name: 'addViolationManually' }
  | { name: 'addInvoiceManually' }
  | { name: 'addProposalManually' }
  | { name: 'addSupportingDataManually' }
  | {
      name: 'processDocuments';
      documents: LiftAIDocumentUntagged[];
    }
  | { name: 'success'; documents: DocumentFlowAddedDoc[] };

interface IUploadDocumentFlowContext {
  state: UploadDocumentFlowState;
  onTypeSelected: (type: UploadDocumentType) => void;
  onFilesUploaded: (attachments: Attachment[]) => void;
  onDocumentFlowDone: (taggedDocs: LiftAIDocumentTagged[]) => void;
  start(initialState?: UploadDocumentFlowState['name']): void;
  goBack(): void;
  reset(): void;
}

export const UploadDocumentDialogFlowContext = createContext<IUploadDocumentFlowContext>({
  state: { name: 'initial' },
  start: () => {},
  goBack: () => {},
  reset: () => {},
  onTypeSelected: () => {},
  onFilesUploaded: () => {},
  onDocumentFlowDone: () => {},
});

export const useUploadDocumentFlow = (): IUploadDocumentFlowContext => {
  const [state, setState] = useState<UploadDocumentFlowState>({ name: 'initial' });

  const onTypeSelected = useCallback((type: UploadDocumentType) => {
    switch (type) {
      case UploadDocumentType.ticket:
        setState({ name: 'addTicketManually' });
        break;
      case UploadDocumentType.certificate:
        setState({ name: 'addCertificateManually' });
        break;
      case UploadDocumentType.violation:
        setState({ name: 'addViolationManually' });
        break;
      case UploadDocumentType.inspectionReport:
        setState({ name: 'addInspectionReportManually' });
        break;
      case UploadDocumentType.invoice:
        setState({ name: 'addInvoiceManually' });
        break;
      case UploadDocumentType.proposal:
        setState({ name: 'addProposalManually' });
        break;
      case UploadDocumentType.supportingData:
        setState({ name: 'addSupportingDataManually' });
        break;
      default:
        alert('Not Implemented');
        break;
    }
  }, []);

  const start = useCallback(
    (state: UploadDocumentFlowState['name'] = 'selectDocumentOrManualEntry') => {
      setState({ name: state } as UploadDocumentFlowState);
    },
    [],
  );

  const goBack = useCallback(() => {
    setState(({ name }): UploadDocumentFlowState => {
      switch (name) {
        case 'selectDocumentOrManualEntry':
          return { name: 'initial' };
        case 'addTicketManually':
        case 'addInvoiceManually':
        case 'addCertificateManually':
        case 'addInspectionReportManually':
        case 'addViolationManually':
        case 'addProposalManually':
        case 'processDocuments':
        case 'addSupportingDataManually':
          return { name: 'selectDocumentOrManualEntry' };
        case 'success':
          throw new Error('Cannot go back from success state');
        default:
          return { name };
      }
    });
  }, []);

  const reset = useCallback(() => {
    setState({ name: 'initial' });
  }, []);

  const onFilesUploaded = useCallback(async (attachments: Attachment[]) => {
    if (attachments.length < 1) {
      return;
    }

    const attachment = attachments[0];

    if (attachment.fileType === 'image') {
      return;
    }

    const doc = await pdfjs.getDocument(attachment.file).promise;

    setState({
      name: 'processDocuments',
      documents: new Array(doc.numPages).fill(0).map((_, i) => ({
        fileType: attachment.fileType as 'pdf',
        name: attachment.name ?? '',
        file: doc,
        pageNum: i + 1,
        attachment,
      })),
    });
  }, []);

  const onDocumentFlowDone = useCallback((taggedDocs: LiftAIDocumentTagged[]) => {
    const addedDocuments = taggedDocs.map((doc): DocumentFlowAddedDoc | null => {
      switch (doc.documentType) {
        case UploadDocumentType.invoice:
        case UploadDocumentType.proposal: {
          const { id, number, property, kind } = doc.formData as Invoice;

          return {
            id,
            number,
            documentType:
              kind === InvoiceKind.Invoice
                ? UploadDocumentType.invoice
                : UploadDocumentType.proposal,
            propertyName: property.name,
          };
        }
        case UploadDocumentType.ticket: {
          const { number, property } = doc.formData as Ticket;
          return {
            number: number,
            documentType: UploadDocumentType.ticket,
            propertyName: property.name,
          };
        }
        case UploadDocumentType.certificate: {
          if (!doc.formData) {
            console.error('No form data found for certificate');
            return null;
          }
          const data = doc.formData as {
            jurisdiction: string;
            dateIssued: string;
            expiration_date: string;
            carId: string;
          };

          return {
            number: data.jurisdiction,
            documentType: UploadDocumentType.certificate,
            propertyName: data.jurisdiction || 'Unknown',
            carId: data.carId,
          };
        }
        case UploadDocumentType.supportingData: {
          const { number, propertyName } = doc.formData as SupportingDataOnSubmitData;
          return {
            documentType: UploadDocumentType.supportingData,
            number,
            propertyName,
          };
        }
        default: {
          return null;
        }
      }
    });

    setState({
      name: 'success',
      documents: addedDocuments.filter((doc): doc is DocumentFlowAddedDoc => doc !== null),
    });
  }, []);

  return {
    state,
    goBack,
    reset,
    start,
    onTypeSelected,
    onFilesUploaded,
    onDocumentFlowDone,
  };
};

export const UploadDocumentDialogFlow: React.FC<PropsWithChildren> = ({ children }) => {
  const uploadDocumentFlowProvider = useUploadDocumentFlow();

  const { state, onTypeSelected, onFilesUploaded, onDocumentFlowDone, goBack, reset } =
    uploadDocumentFlowProvider;

  return (
    <UploadDocumentDialogFlowContext.Provider value={uploadDocumentFlowProvider}>
      {children}
      {state.name === 'selectDocumentOrManualEntry' ? (
        <UploadDocumentDialog
          open
          onClose={goBack}
          onTypeSelected={onTypeSelected}
          onFilesUploaded={onFilesUploaded}
        />
      ) : null}

      {/* Add manually flow */}
      {/* TODO: Connect onNext to actual action */}
      {state.name === 'addTicketManually' ? (
        <AddTicketDialog open onClose={goBack} onSubmit={reset} />
      ) : null}

      {state.name === 'addViolationManually' ? (
        <AddViolationDialog open onClose={goBack} onSubmit={reset} />
      ) : null}

      {state.name === 'addCertificateManually' ? (
        <AddCertificateDialog open onClose={goBack} onSubmit={reset} />
      ) : null}

      {state.name === 'addInspectionReportManually' ? (
        <AddInspectionReportDialog open onClose={goBack} onSubmit={reset} />
      ) : null}

      {state.name === 'addSupportingDataManually' ? (
        <AddSupportingDataDialog open onClose={goBack} onSubmit={reset} />
      ) : null}

      {/* TODO: In the future if new flow states are added might need to refactor all these ternaries rendering */}
      {state.name === 'addInvoiceManually' ? (
        <InvoiceDialog kind={InvoiceKind.Invoice} open onClose={goBack} onSubmit={reset} />
      ) : null}

      {state.name === 'addProposalManually' ? (
        <InvoiceDialog
          kind={InvoiceKind.Proposal}
          open
          onClose={goBack}
          onSubmit={reset}
          defaultValues={{
            ...getDefaultInvoiceFormValues(),
            kind: InvoiceKind.Proposal,
          }}
        />
      ) : null}

      {/* Upload document flow */}
      {state.name === 'processDocuments' ? (
        <SelectUploadDocumentTypeDialog
          open
          onCancel={goBack}
          onDone={onDocumentFlowDone}
          documents={state.documents}
        />
      ) : null}

      {/** Success Page onDone state */}
      {state.name === 'success' ? (
        <SuccessDocumentDialog documentsAdded={state.documents} onClose={reset} />
      ) : null}
    </UploadDocumentDialogFlowContext.Provider>
  );
};

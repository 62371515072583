import {
  Contract,
  ContractHourlyRate,
  ContractReviewRule,
  ContractSummaryDetails,
} from '@liftai/asset-management-types';
import { useCallback } from 'react';
import type { Fetcher } from 'swr';
import useSWR, { mutate } from 'swr';
import type { MutationFetcher } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import { getApiClient } from '~/utils/api';

/**
 * The metadata custom details key is the key used to store the custom details in the contract metadata.
 */
export const METADATA_CUSTOM_DETAILS_KEY = 'customDetails' as const;
/**
 * The key used to revalidate the contracts list.
 */
const REVALIDATION_KEY = 'contracts';

const propertyContractsListFetcher: Fetcher<
  Contract[],
  [typeof REVALIDATION_KEY, propertyId: string]
> = async ([_, propertyId]) => {
  const apiClient = getApiClient();
  return await apiClient.contracts.getByPropertyId(propertyId);
};

const contractSummaryDetailsUpdateFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; summaryDetails: ContractSummaryDetails }
> = async (_, { arg }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.updateSummary(arg.contractId, arg.summaryDetails);
};

const contractCustomSummaryDetailsUpdateFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; summaryDetails: { [key: string]: string } }
> = async (_, { arg }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.saveCustomSummary(arg.contractId, arg.summaryDetails);
};

const contractHourlyRateCreateFetcher: MutationFetcher<
  ContractHourlyRate,
  string,
  { contractId: string; hourlyRate: ContractHourlyRate }
> = async (_, { arg: { contractId, hourlyRate } }) => {
  const apiClient = getApiClient();
  const data = await apiClient.contracts.hourlyRates.create(contractId, hourlyRate);

  return data;
};

const contractHourlyRateUpdateFetcher: MutationFetcher<
  ContractHourlyRate,
  string,
  { contractId: string; rate: ContractHourlyRate & { id: string } }
> = async (_, { arg: { contractId, rate } }) => {
  const apiClient = getApiClient();
  const data = await apiClient.contracts.hourlyRates.update(contractId, rate.id, rate);

  return data;
};

const contractHourlyRateDeleteFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; rateId: string }
> = async (_, { arg: { contractId, rateId } }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.hourlyRates.delete(contractId, rateId);
};

const contractReviewRuleCreateFetcher: MutationFetcher<
  ContractReviewRule,
  string,
  { contractId: string; rule: ContractReviewRule }
> = async (_, { arg: { contractId, rule } }) => {
  const apiClient = getApiClient();
  const data = await apiClient.contracts.reviewRules.create(contractId, rule);

  return data;
};

const contractReviewRuleUpdateFetcher: MutationFetcher<
  ContractReviewRule,
  string,
  { contractId: string; rule: ContractReviewRule & { id: string } }
> = async (_, { arg: { contractId, rule } }) => {
  const apiClient = getApiClient();
  const data = await apiClient.contracts.reviewRules.update(contractId, rule.id, rule);

  return data;
};

const contractReviewRuleDeleteFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; ruleId: string }
> = async (_, { arg: { contractId, ruleId } }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.reviewRules.delete(contractId, ruleId);
};

const useContractHourlyRates = (propertyId?: string) => {
  const { trigger: createTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractHourlyRateCreateFetcher,
  );
  const { trigger: updateTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractHourlyRateUpdateFetcher,
  );
  const { trigger: deleteTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractHourlyRateDeleteFetcher,
  );

  const saveHourlyRates = useCallback(
    async (contractId: string, data: ContractHourlyRate[]) => {
      await Promise.all(
        data.map((rate) => {
          if (rate.id) {
            return updateTrigger({ contractId, rate: { ...rate, id: rate.id } });
          }

          return createTrigger({ contractId, hourlyRate: rate });
        }),
      );
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [createTrigger, propertyId, updateTrigger],
  );

  const deleteHourlyRate = useCallback(
    async (contractId: string, rateId: string) => {
      await deleteTrigger({ contractId, rateId });
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [deleteTrigger, propertyId],
  );

  return {
    /**
     * Adds multiple hourly rates to the contract.
     * This will create new rates if rates have not `id` and update existing ones.
     */
    saveHourlyRates,
    /**
     * Removes an hourly rate from the contract.
     */
    deleteHourlyRate,
  };
};

const useContractReviewRules = (propertyId?: string) => {
  const { trigger: createTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractReviewRuleCreateFetcher,
  );
  const { trigger: updateTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractReviewRuleUpdateFetcher,
  );
  const { trigger: deleteTrigger } = useSWRMutation(
    REVALIDATION_KEY,
    contractReviewRuleDeleteFetcher,
  );

  const saveReviewRules = useCallback(
    async (contractId: string, data: ContractReviewRule[]) => {
      await Promise.all(
        data.map((rule) => {
          if (rule.id) {
            return updateTrigger({ contractId, rule: { ...rule, id: rule.id } });
          }

          return createTrigger({ contractId, rule });
        }),
      );
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [createTrigger, propertyId, updateTrigger],
  );

  const deleteReviewRule = useCallback(
    async (contractId: string, ruleId: string) => {
      await deleteTrigger({ contractId, ruleId });
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [deleteTrigger, propertyId],
  );

  return {
    /**
     * Adds multiple review rules to the contract.
     * This will create new rules if rules have not `id` and update existing ones.
     */
    saveReviewRules,
    /**
     * Removes an review rule from the contract.
     */
    deleteReviewRule,
  };
};

const useSaveSummaryDetails = (propertyId?: string) => {
  const { trigger } = useSWRMutation(REVALIDATION_KEY, contractSummaryDetailsUpdateFetcher);

  const saveSummaryDetails = useCallback(
    async (contractId: string, data: ContractSummaryDetails) => {
      await trigger({ contractId, summaryDetails: data });
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [propertyId, trigger],
  );

  return saveSummaryDetails;
};

const useSaveCustomDetails = (propertyId?: string) => {
  const { trigger } = useSWRMutation(REVALIDATION_KEY, contractCustomSummaryDetailsUpdateFetcher);

  const saveCustomSummaryDetails = useCallback(
    async (contractId: string, data: ContractSummaryDetails['customDetails']) => {
      const summaryDetails =
        data?.reduce(
          (acc, { name, value }) => {
            acc[name] = value;
            return acc;
          },
          {} as { [key: string]: string },
        ) ?? {};

      await trigger({ contractId, summaryDetails });
      await mutate(propertyId ? [REVALIDATION_KEY, propertyId] : null);
    },
    [propertyId, trigger],
  );

  return saveCustomSummaryDetails;
};

export default function usePropertyContracts(propertyId?: string) {
  const { data, error, isLoading } = useSWR(
    propertyId ? [REVALIDATION_KEY, propertyId] : null,
    propertyContractsListFetcher,
    {},
  );
  const saveSummaryDetails = useSaveSummaryDetails(propertyId);
  const saveCustomDetails = useSaveCustomDetails(propertyId);
  const { deleteHourlyRate, saveHourlyRates } = useContractHourlyRates(propertyId);
  const { deleteReviewRule, saveReviewRules } = useContractReviewRules(propertyId);

  return {
    contracts: data ?? [],
    saveSummaryDetails,
    saveCustomDetails,
    deleteContractHourlyRate: deleteHourlyRate,
    saveContractHourlyRates: saveHourlyRates,
    deleteContractReviewRule: deleteReviewRule,
    saveContractReviewRules: saveReviewRules,
    error,
    isLoading,
  };
}

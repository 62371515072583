import type {
  Invoice,
  InvoiceAttachment,
  InvoiceForm,
  InvoiceKindKey,
  Ticket,
  TicketAttachment,
} from '@liftai/asset-management-types';
import { InvoiceKind, isLinkedPDFAttachment } from '@liftai/asset-management-types';
import { Box, Card, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useCallback, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';

import AttachmentPreviewDialog from '~/components/AttachmentPreviewDialog';
import AttachmentUploader from '~/components/AttachmentUploader';
import PdfPreviewWithActions from '~/components/documentPreview/PdfPreviewWithActions';
import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';
import { mapInvoiceToFormValues } from '~/components/uploadDocument/InvoiceForm';
import type { LiftAIDocumentTagged } from '~/components/uploadDocument/SelectDocumentTypeDialog';
import SelectUploadDocumentTypeDialog from '~/components/uploadDocument/SelectDocumentTypeDialog';
import {
  UploadDocumentDialogFlowContext,
  useUploadDocumentFlow,
} from '~/components/uploadDocument/UploadDocumentDialogFlow';
import { getInvoiceMutator } from '~/data/hooks/utils';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { getApiClient } from '~/utils/api';

type LinkedAttachmentItem =
  | ({ kind?: InvoiceKindKey; isTicket: false; ticketId?: never } & InvoiceAttachment)
  | ({ kind?: never; isTicket: true; ticketId: string } & TicketAttachment);
interface IAttachmentItemProps {
  item: LinkedAttachmentItem;
  onDelete: (id: string) => void;
  currentlyDeleting: { isLoading: boolean; id: string };
}

const getSubtitle = (item: LinkedAttachmentItem) => {
  if (item.isTicket) {
    return item.isNextPage ? UploadDocumentType.ticketContinued : UploadDocumentType.ticket;
  }

  if (item.isPrimary) {
    if (!item.isTicket && item.isVoid) {
      return UploadDocumentType.supportingData;
    }

    if (item.kind === InvoiceKind.Invoice) {
      return item.isNextPage ? UploadDocumentType.invoiceContinued : UploadDocumentType.invoice;
    } else if (item.kind === InvoiceKind.Proposal) {
      return item.isNextPage ? UploadDocumentType.proposalContinued : UploadDocumentType.proposal;
    }
  }

  return UploadDocumentType.supportingData;
};

const AttachmentItem = ({ item, onDelete, currentlyDeleting }: IAttachmentItemProps) => {
  const { isLoading, id } = currentlyDeleting;
  const subtitle = getSubtitle({ ...item });
  const [openPreview, setOpenPreview] = useState(false);

  /** TODO: render <img> for fileType = image */
  return isLinkedPDFAttachment(item) ? (
    <Box
      sx={{
        p: 0,
        textAlign: 'center',
        color: 'text.secondary',
        margin: '.5rem 1rem',
        border: '1px solid',
        borderRadius: '5px',
      }}
    >
      {isLoading && id === item.id ? (
        <Card
          sx={{
            height: 200,
            display: 'flex',
            backgroundColor: '#eee',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Card>
      ) : (
        <>
          <PdfPreviewWithActions
            fileId={item.id}
            filePath={item.attachmentUrl}
            pageNumber={item.pageNumber}
            subtitle={subtitle}
            onDeleted={onDelete}
            onPreview={() => setOpenPreview(true)}
          />
          <AttachmentPreviewDialog
            open={openPreview}
            filePath={item.attachmentUrl}
            pageNumber={item.pageNumber}
            onClose={() => setOpenPreview(false)}
          />
        </>
      )}
    </Box>
  ) : null;
};

interface IAttachmentsProps {
  invoice?: Invoice;
  tickets?: Ticket[];
}

const Attachments = ({ invoice, tickets }: IAttachmentsProps) => {
  const invoiceAttachmentsState = useMemo(() => {
    const invoicePages = (invoice?.attachments ?? [])
      .map((attachment) => {
        return { ...attachment, isTicket: false, kind: invoice?.kind };
      })
      .filter((a) => a.isPrimary && !a.isVoid)
      .sort((prev, curr) => (prev.pageNumber ?? 0) - (curr.pageNumber ?? 0));
    const supportingDataPages = (invoice?.attachments ?? [])
      .map((sd) => {
        return { ...sd, isTicket: false };
      })
      .filter((a) => !a.isPrimary || a.isVoid)
      .reverse();
    const relatedInvoicePages = (invoice?.relatedInvoices ?? []).flatMap((relatedInvoice) => {
      return relatedInvoice.attachments
        .map((attachment) => {
          return { ...attachment, isTicket: false };
        })
        .filter((a) => a.isPrimary && !a.isVoid)
        .sort((prev, curr) => (prev.pageNumber ?? 0) - (curr.pageNumber ?? 0));
    });

    return [
      ...invoicePages,
      ...supportingDataPages,
      ...relatedInvoicePages,
    ] as LinkedAttachmentItem[];
  }, [invoice?.attachments, invoice?.kind, invoice?.relatedInvoices]);

  const ticketsAttachmentsState = useMemo(() => {
    return (
      tickets?.flatMap(({ attachments, id }) => {
        const ticketId: string = id ?? '';

        return (attachments ?? []).map((a) => ({
          ...a,
          ticketId,
          isTicket: true,
        })) as LinkedAttachmentItem[];
      }) ?? []
    ).reverse();
  }, [tickets]);

  const { showEntityActionSnackbar } = useLAISnackbar();
  const { mutate } = useSWRConfig();
  const [currentlyDeleting, setCurrentlyDeleting] = useState({ isLoading: false, id: '' });

  const onDeleteFactory = (type: 'invoice' | 'ticket') => {
    return async (attachmentId: string) => {
      const apiClient = getApiClient();
      const invoiceId = invoice?.id ?? '';
      const ticketId = ticketsAttachmentsState.find((t) => t.id === attachmentId)?.ticketId ?? '';

      try {
        setCurrentlyDeleting({ isLoading: true, id: attachmentId });

        if (type === 'invoice') {
          await apiClient.invoices.deleteAttachment(invoiceId, attachmentId);
        } else {
          await apiClient.tickets.deleteAttachment(ticketId, attachmentId);
        }

        void mutate(getInvoiceMutator(invoiceId));

        showEntityActionSnackbar(
          {
            name: 'Attachment',
            action: ActionEnum.Delete,
          },
          {
            variant: 'info',
          },
        );
      } catch (error) {
        console.error('Error deleting attachment', error);
        showEntityActionSnackbar(
          {
            name: 'Deleting Attachment',
            action: ActionEnum.Fail,
          },
          {
            variant: 'error',
          },
        );
      } finally {
        setCurrentlyDeleting({ isLoading: false, id: attachmentId });
      }
    };
  };

  const uploadDocumentFlowProvider = useUploadDocumentFlow();
  const { state, onFilesUploaded, onDocumentFlowDone, goBack } = uploadDocumentFlowProvider;
  const invoiceFormData = useMemo(
    () => (invoice ? mapInvoiceToFormValues(invoice) : ({} as InvoiceForm)),
    [invoice],
  );

  const onDone = useCallback(
    async (docs: LiftAIDocumentTagged[]) => {
      onDocumentFlowDone(docs);

      if (invoice?.id) {
        void mutate(getInvoiceMutator(invoice.id));
      }
    },
    [invoice?.id, mutate, onDocumentFlowDone],
  );

  const onCancel = useCallback(async () => {
    goBack();
  }, [goBack]);

  return (
    <UploadDocumentDialogFlowContext.Provider value={uploadDocumentFlowProvider}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Box sx={{ margin: '1rem' }}>
              <AttachmentUploader
                fileSizeLimit={5 /*MB*/}
                acceptedFileTypes={{ 'application/pdf': ['.pdf'] }}
                acceptedFileTypesLabel="PDF"
                onFilesUploaded={onFilesUploaded}
              />
            </Box>
            {state.name === 'processDocuments' ? (
              <SelectUploadDocumentTypeDialog
                open
                onCancel={onCancel}
                onDone={onDone}
                documents={state.documents}
                editInvoice
                invoiceData={invoiceFormData}
              />
            ) : null}
          </Grid>
          {invoiceAttachmentsState.map((attachment) => {
            return (
              <Grid key={attachment.id} size={{ xs: 6 }}>
                <AttachmentItem
                  currentlyDeleting={currentlyDeleting}
                  item={attachment}
                  onDelete={onDeleteFactory('invoice')}
                />
              </Grid>
            );
          })}
          {ticketsAttachmentsState.map((attachment) => {
            return (
              <Grid key={attachment.id} size={{ xs: 6 }}>
                <AttachmentItem
                  currentlyDeleting={currentlyDeleting}
                  item={attachment}
                  onDelete={onDeleteFactory('ticket')}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </UploadDocumentDialogFlowContext.Provider>
  );
};

export default Attachments;

import { z } from 'zod';
export const certificateStatusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'expired', label: 'Expired' },
    { value: 'expiring_soon', label: 'Expiring Soon' },
];
export const inspectionStatusOptions = [
    { value: 'Passed', label: 'Passed' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Pending', label: 'Pending' },
];
export const api_carSchema = z.object({
    id: z.string(),
    name: z.string(),
    lastTripDt: z.string().nullable(),
    tripCount30Day: z.number(),
});
export const api_portfolioSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export const api_propertySchema = z.object({
    id: z.string(),
    name: z.string(),
    portfolio: api_portfolioSchema,
    address: z.string().nullable(),
});
export const api_serviceProviderSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export const api_violationSummarySchema = z.object({
    openViolations: z.number(),
    overdueViolations: z.number(),
    resolvedViolations: z.number(),
    nextViolationDue: z.string(),
    violationsWithoutUpdates: z.number(),
    inspectionStatus: z.string(),
});
export const api_certificateSchema = z.object({
    id: z.string(),
    ahjId: z.string(),
    jurisdiction: z.string().nullable(),
    car: api_carSchema,
    property: api_propertySchema,
    portfolio: api_portfolioSchema,
    serviceProvider: api_serviceProviderSchema.nullable(),
    region: z.string().nullable(),
    dateIssued: z.string(),
    expirationDate: z.string(),
    daysUntilExpiration: z.number(),
    status: z.string(),
    violationSummary: api_violationSummarySchema,
    inspectionStatus: z.string(),
    metadata: z.array(z.any()),
    createdAt: z.string(),
    updatedAt: z.string(),
    downloadUrl: z.string().nullable(),
    inspectionType: z.string().nullable(),
    inspectionTypeDisplay: z.string().nullable(),
});
export const api_certificateCreateRequestResponse = z.object({
    id: z.string(),
    ahjId: z.string(),
    carId: z.string(),
    customType: z.string().nullable(),
    dateIssued: z.string(),
    expirationDate: z.string(),
});
export const api_certificateCreateSchema = z.object({
    car_id: z.string(),
    jurisdiction: z.string().nullable(),
    date_issued: z.string(),
    expiration_date: z.string(),
    attachment_id: z.string().nullable(),
});
export const api_certificateMetadataSchema = z.object({
    activeCertificates: z.number(),
    expiringCertificates: z.number(),
    expiredCertificates: z.number(),
    certificatesByJurisdiction: z.record(z.number()),
    certificatesWithViolations: z.number(),
    certificationsByStatus: z.object({
        active: z.number(),
        expiringSoon: z.number(),
        expired: z.number(),
    }),
});
export const api_certificateFilterResponse = z.object({
    count: z.number().nullable(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    metadata: api_certificateMetadataSchema,
    results: z.array(api_certificateSchema),
});
export const api_certificateAttachmentDetails = z.object({
    id: z.string(),
    attachmentId: z.string(),
    attachmentUrl: z.string(),
    fileType: z.string(),
    isPrimary: z.boolean(),
    isNextPage: z.boolean(),
    pageNumber: z.number(),
    attachment: z.string(),
});

import { z } from 'zod';
import { api_carSchema, api_portfolioSchema, api_propertySchema, api_serviceProviderSchema, } from './certificate';
export const api_violationSchema = z.object({
    id: z.string(),
    code: z.string(),
    location: z.string().nullable(),
    car: api_carSchema.nullable(),
    property: api_propertySchema,
    portfolio: api_portfolioSchema,
    serviceProvider: api_serviceProviderSchema.nullable(),
    region: z.string().nullable(),
    correctionDue: z.string(),
    daysUntilDue: z.number(),
    responsibility: z.enum(['owner', 'county', 'service_provider']),
    description: z.string(),
    status: z.enum(['open', 'overdue', 'resolved']),
    resolvedDt: z.string().nullable(),
    resolutionInfo: z
        .object({
        resolvedDt: z.string(),
        resolutionNotes: z.string(),
    })
        .nullable(),
    recurrenceCount: z.number(),
    metadata: z.array(z.any()),
    createdAt: z.string(),
    updatedAt: z.string(),
});
export const api_violationCreateSchema = z.object({
    car_id: z.string(),
    code: z.string(),
    location: z.string(),
    correction_due: z.string(),
    date_open: z.string(),
    responsibility: z.enum(['owner', 'county', 'service_provider']),
    description: z.string(),
});
export const api_violationMetadataSchema = z.object({
    openViolations: z.number(),
    overdueViolations: z.number(),
    resolvedViolations: z.number(),
    recurringViolations: z.record(z.number()),
    violationsByResponsibility: z.object({
        county: z.number(),
        owner: z.number(),
        serviceProvider: z.number(),
    }),
    violationsByStatus: z.object({
        open: z.number(),
        overdue: z.number(),
        resolved: z.number(),
    }),
});
export const api_violationFilterResponse = z.object({
    count: z.number().nullable(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    metadata: api_violationMetadataSchema,
    results: z.array(api_violationSchema),
});

import type { InputBaseComponentProps } from '@mui/material';
import { TextField } from '@mui/material';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  type GridFilterItem,
  type GridFilterOperator,
} from '@mui/x-data-grid-premium';

interface NestedKeyFilterOptions<TRow = unknown> {
  /**
   * A function which receives the `row` and returns a `string` that can be used for
   * filter comparator purposes.
   */
  filterableStringFn: (row: TRow) => string;
}

export const NestedKeyFilterOperator = <TRow,>({
  filterableStringFn,
}: NestedKeyFilterOptions<TRow>) => {
  return {
    label: 'contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return ({ row }: { row: TRow }): boolean => {
        const key = filterableStringFn(row);
        return key ? key.toLowerCase().includes(filterItem.value.toLowerCase()) : false;
      };
    },
    InputComponent: (props: InputBaseComponentProps) => {
      const { item, applyValue } = props;
      return (
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          label="Search"
          placeholder="Search..."
          variant="standard"
          onChange={(event) => {
            applyValue({ ...item, value: event.target.value });
          }}
          value={item.value || ''}
        />
      );
    },
    InputComponentProps: { type: 'string' },
  };
};

/**
 * Limit the kinds of operations (remove starts with, ends with, equals, etc) and stick with contains
 */
export const onlyContainsFilterOperator = getGridStringOperators().filter(
  (operator) => operator.value === 'contains',
);

/**
 * Limit the kinds of operations (remove starts with, ends with, equals, etc) and stick with isAnyOf
 */
export const onlyIsAnyOfFilterOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === 'isAnyOf',
);

export const onOrBeforeOrOnOrAfterFilterOperator: GridFilterOperator[] =
  getGridDateOperators().filter(
    (operator): operator is GridFilterOperator =>
      operator.value === 'onOrAfter' || operator.value === 'onOrBefore',
  );

export const basicNumberComparisonOperator = getGridNumericOperators().filter((operator) => {
  return (
    operator.value === '>' ||
    operator.value === '>=' ||
    operator.value === '<' ||
    operator.value === '<=' ||
    operator.value === '='
  );
});

import { MenuItem } from '@mui/material';
import type { GridColDef, GridExportExtension } from '@mui/x-data-grid-premium';
import {
  gridColumnDefinitionsSelector,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import type Excel from 'exceljs';
import { json2csv } from 'json-2-csv';
import { closeSnackbar } from 'notistack';
import type { MutableRefObject } from 'react';
import { useCallback } from 'react';

import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

type ExportRecord = Record<string, unknown>;
type ExportDataAs<TData extends ExportRecord> = {
  columnsToExport: GridColDef[];
  formatters: { [key in keyof TData]: (record: ExportRecord) => string };
  data: TData[];
  filename: string;
};

/**
 * taken borrowed from mui-x
 * @link [exportAs](https://github.com/mui/mui-x/blob/next/packages/x-data-grid/src/utils/exportAs.ts#L14)
 */
const exportAs = (blob: Blob, extension: GridExportExtension = 'csv', filename: string): void => {
  const fullName = `${filename}.${extension}`;
  if ('download' in HTMLAnchorElement.prototype) {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fullName;

    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
    return;
  }
};

/**
 * `getColumnsToExport` returns an array of
 * the columnDefs that should be exported
 */
const getColumnsToExport = (apiRef: MutableRefObject<GridApiPremium>) => {
  const columnNames = apiRef.current
    .getAllColumns()
    .map((col) => col.field)
    .filter((field) => field !== 'actions' && field !== '__detail_panel_toggle__');

  const columnDefs = gridColumnDefinitionsSelector(apiRef);

  const columnsToExport = columnDefs.filter((col) => columnNames.includes(col.field));

  return columnsToExport;
};

type GetFormatterForFieldOpts<TData extends ExportRecord> = {
  colDefs: GridColDef<TData>[];
  apiRef: MutableRefObject<GridApiPremium>;
};

const defaultValueGetter = <TData extends ExportRecord>(colDef: GridColDef<TData>) => {
  return (record: TData) => record[colDef.field] as string;
};

const getValueGetter = <TData extends ExportRecord>(colDef: GridColDef<TData>) => {
  if (!colDef.valueGetter) {
    return defaultValueGetter(colDef);
  }

  return (record: TData) => {
    try {
      const valueGetter = colDef.valueGetter as ({ row }: { row: TData }) => string;
      return valueGetter({ row: record });
    } catch (error) {
      console.error('Error getting value with valueGetter:', error);
      return defaultValueGetter(colDef)(record);
    }
  };
};

const valuesToIgnore: unknown[] = ['-', null, undefined];
const defaultValueFormatter = (value: unknown) => {
  return cleanValue(value);
};

const cleanValue = (value: unknown) => {
  if (valuesToIgnore.includes(value)) {
    return '';
  }
  return value as string;
};

type ValueFormatterOpts<TData extends ExportRecord> = {
  value: unknown;
  row: TData;
  colDef: GridColDef<TData>;
  field: string;
  apiRef: MutableRefObject<GridApiPremium>;
  valueFormatter: GridColDef<TData>['valueFormatter'];
};
const getValueFormatter = <TData extends ExportRecord>(opts: ValueFormatterOpts<TData>) => {
  const valueFormatter = opts.valueFormatter;

  if (!valueFormatter) {
    return defaultValueFormatter;
  }

  return (value: unknown) => {
    try {
      const formattedValue = valueFormatter(
        opts.value as never,
        opts.row,
        opts.colDef,
        opts.apiRef,
      );
      return cleanValue(formattedValue);
    } catch (error) {
      console.error('Error getting value with valueFormatter:', error);
      return defaultValueFormatter(value);
    }
  };
};

const getFormattersForFields = <TData extends ExportRecord>({
  colDefs,
  apiRef,
}: GetFormatterForFieldOpts<TData>) => {
  return Object.fromEntries(
    colDefs.map((colDef) => [
      colDef.field,
      (record): string => {
        const valueGetter = getValueGetter(colDef);

        const value = valueGetter(record);
        const valueFormatter = getValueFormatter({
          value,
          colDef,
          row: record,
          field: colDef.field,
          apiRef: apiRef,
          valueFormatter: colDef.valueFormatter,
        });
        return valueFormatter(value);
      },
    ]),
  ) as { [key in keyof TData]: (records: TData) => string };
};

type SerializedColumn = {
  key: string;
  width: number;
  style: Partial<Excel.Style>;
  headerText: string;
};

const addMetadataForColumn = (column: GridColDef): SerializedColumn => {
  const defaultColumnsStyles: { [key: string]: Partial<Excel.Style> } = {
    // date: { numFmt: 'dd/mm/yyyy' },
    // dateTime: { numFmt: 'dd/mm/yyyy hh:mm' },
  };
  const { field, type } = column;

  return {
    key: field,
    headerText: column.headerName ?? column.field,
    // Excel width must stay between 0 and 255 (https://support.microsoft.com/en-us/office/change-the-column-width-and-row-height-72f5e3cc-994d-43e8-ae58-9774a0905f46)
    // From the example of column width behavior (https://docs.microsoft.com/en-US/office/troubleshoot/excel/determine-column-widths#example-of-column-width-behavior)
    // a value of 10 corresponds to 75px. This is an approximation, because column width depends on the font-size
    width: Math.min(255, column.width ? column.width / 7.5 : 8.43),
    style: { ...(type && defaultColumnsStyles[type]) },
  };
};

const addMetadataForColumns = (columns: GridColDef[]): Array<SerializedColumn> => {
  return columns.map((column) => addMetadataForColumn(column));
};

const prepareForExport = async <TData extends ExportRecord>({
  columnsToExport,
  data,
  formatters,
}: ExportDataAs<TData>): Promise<ExportRecord[]> => {
  return data.map((record) => {
    return Object.fromEntries(
      columnsToExport.map(({ headerName, field }) => {
        const formatter = formatters[field];
        return [headerName ?? field, formatter(record)];
      }),
    );
  });
};

const exportDataAsCsv = async (opts: ExportDataAs<ExportRecord>) => {
  const exportedRecords = await prepareForExport(opts);

  const csv = json2csv(exportedRecords);
  const blob = new Blob(['', csv], {
    type: 'text/csv',
  });

  exportAs(blob, 'csv', opts.filename);
};

const exportDataAsExcel = async (opts: ExportDataAs<ExportRecord>) => {
  const exportedRecords = await prepareForExport(opts);
  const Excel = await import('exceljs');

  // initialize excel
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // prepare columns
  worksheet.columns = addMetadataForColumns(opts.columnsToExport);

  // add header
  worksheet.addRow(opts.columnsToExport.map(({ headerName, field }) => headerName ?? field));

  exportedRecords.map((record) => worksheet.addRow(Object.values(record)));

  // export as xls
  const xls = await workbook.xlsx.writeBuffer();
  const blob = new Blob([xls], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  exportAs(blob, 'xlsx', opts.filename);
};

export type UseGridExporter<TData extends ExportRecord> = {
  fetcher: () => Promise<TData[]>;
  apiRef: MutableRefObject<GridApiPremium>;
  getFilename: () => string;
  onStartExport?: () => void;
  onFinishedExport?: () => void;
  onErrorExport?: () => void;
};
export const useGridExporter = <TData extends ExportRecord>({
  fetcher,
  apiRef,
  onStartExport,
  onFinishedExport,
  onErrorExport,
  getFilename,
}: UseGridExporter<TData>) => {
  const { showEntityActionSnackbar } = useLAISnackbar();

  const onStartExportDefault = useCallback(() => {
    showEntityActionSnackbar(
      {
        name: 'Exporting information...',
        action: ActionEnum.StatusChange,
      },
      {
        variant: 'info',
        persist: true,
      },
    );
  }, [showEntityActionSnackbar]);

  const onFinishedExportDefault = useCallback(() => {
    closeSnackbar();
    showEntityActionSnackbar(
      {
        name: 'Information exported successfully',
        action: ActionEnum.StatusChange,
      },
      {
        variant: 'success',
        autoHideDuration: 5000,
      },
    );
  }, [showEntityActionSnackbar]);

  const onErrorExportDefault = useCallback(() => {
    closeSnackbar();
    showEntityActionSnackbar(
      {
        name: 'Exporting information failed',
        action: ActionEnum.Fail,
      },
      {
        variant: 'error',
        autoHideDuration: 5000,
      },
    );
  }, [showEntityActionSnackbar]);

  const handleExport = useCallback(
    async (exportFunction: (opts: ExportDataAs<ExportRecord>) => Promise<void>) => {
      try {
        onStartExport ? onStartExport() : onStartExportDefault();

        const data = await fetcher();
        if (!data) {
          return;
        }

        const columnsToExport = getColumnsToExport(apiRef);
        const formatters = getFormattersForFields({
          colDefs: columnsToExport,
          apiRef,
        });

        await exportFunction({
          columnsToExport,
          data,
          formatters,
          filename: getFilename(),
        });

        onFinishedExport ? onFinishedExport() : onFinishedExportDefault();
      } catch (error) {
        console.error('Error exporting data:', error);
        onErrorExport ? onErrorExport() : onErrorExportDefault();
      }
    },
    [
      apiRef,
      fetcher,
      onErrorExport,
      onErrorExportDefault,
      onFinishedExport,
      onFinishedExportDefault,
      onStartExport,
      onStartExportDefault,
      getFilename,
    ],
  );

  /**
   * Custom GridToolbarExport
   */
  const GridToolbarExport = useCallback(
    () => (
      <GridToolbarExportContainer>
        <MenuItem onClick={() => handleExport(exportDataAsCsv)}>Download as CSV</MenuItem>
        <GridExcelExportMenuItem onClick={() => handleExport(exportDataAsExcel)} />
        <GridPrintExportMenuItem />
      </GridToolbarExportContainer>
    ),
    [handleExport],
  );

  return {
    GridToolbarExport,
    onFinishedExportDefault,
    onErrorExportDefault,
    onStartExportDefault,
  };
};

import { FeedbackRequest } from '@liftai/asset-management-types';
import { ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { getApiClient } from '~/utils/api';

interface FeedbackProps {
  feedbackKey: string;
  runId: string;
  placeholder?: string;
}

type ActionType = 'submit' | 'skip' | null;

const FEEDBACK_SCORES = {
  POSITIVE: 1,
  NEGATIVE: 0,
} as const;

const Feedback = ({
  feedbackKey,
  runId,
  placeholder = 'Is this insight helpful?',
}: FeedbackProps) => {
  const { showSnackbar, showEntityActionSnackbar } = useLAISnackbar();
  const apiClient = getApiClient();
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackState, setFeedbackState] = useState({
    isOpen: false,
    feedbackText: '',
    isPositive: null as boolean | null,
    currentAction: null as ActionType,
  });

  const onFeedback = async (opts: { comment: string; isPositive: boolean }) => {
    try {
      setIsSubmittingFeedback(true);
      const score = opts.isPositive ? FEEDBACK_SCORES.POSITIVE : FEEDBACK_SCORES.NEGATIVE;
      const feedback: FeedbackRequest = {
        runId,
        key: feedbackKey,
        comment: opts.comment,
        score,
      };
      await apiClient.feedback.submitFeedback(feedback);

      showEntityActionSnackbar(
        {
          name: 'Feedback',
          action: ActionEnum.Create,
          id: 'submitted successfully',
        },
        {
          variant: 'success',
        },
      );
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      captureException(error);
      showEntityActionSnackbar(
        {
          name: 'Feedback',
          action: ActionEnum.Fail,
          id: 'submission failed',
        },
        {
          variant: 'error',
        },
      );
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  const { isOpen, feedbackText, isPositive, currentAction } = feedbackState;

  const handleScoreClick = (positive: boolean) => {
    setFeedbackState((prev) => ({ ...prev, isOpen: true, isPositive: positive }));
  };

  const handleSubmit = async () => {
    if (isPositive === null) return;
    setFeedbackState((prev) => ({ ...prev, currentAction: 'submit' }));
    try {
      await onFeedback({ comment: feedbackText.trim(), isPositive });
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      captureException(error);
      showSnackbar('An unexpected error occurred. Please try again later.', { variant: 'error' });
    }
  };

  const handleSkip = async () => {
    if (isPositive === null) return;
    setFeedbackState((prev) => ({ ...prev, currentAction: 'skip' }));
    try {
      await onFeedback({ comment: '', isPositive });
    } catch (error) {
      console.error('Failed to skip feedback:', error);
      captureException(error);
      showSnackbar('An unexpected error occurred. Please try again later.', { variant: 'error' });
    }
  };

  const handleClose = () => {
    if (isSubmittingFeedback) return;
    setFeedbackState({ isOpen: false, feedbackText: '', isPositive: null, currentAction: null });
  };

  useEffect(() => {
    if (!isSubmittingFeedback) {
      setFeedbackState({ isOpen: false, feedbackText: '', isPositive: null, currentAction: null });
    }
  }, [isSubmittingFeedback]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
        <Typography variant="caption" color="primary">
          {placeholder}
        </Typography>
        <IconButton
          size="small"
          color="lightButton"
          onClick={() => handleScoreClick(true)}
          disabled={isSubmittingFeedback}
          aria-label="Helpful"
        >
          <ThumbUpOutlined fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          color="lightButton"
          onClick={() => handleScoreClick(false)}
          disabled={isSubmittingFeedback}
          aria-label="Not Helpful"
        >
          <ThumbDownOutlined fontSize="small" />
        </IconButton>
      </Box>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-describedby="feedback-dialog-description"
        role="dialog"
      >
        <DialogTitle sx={{ marginTop: 1 }}>
          {isPositive ? 'What was helpful?' : 'What could be improved?'}
        </DialogTitle>
        <DialogContent id="feedback-dialog-description">
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Optional feedback..."
            value={feedbackText}
            onChange={(e) =>
              setFeedbackState((prev) => ({ ...prev, feedbackText: e.target.value }))
            }
            disabled={isSubmittingFeedback}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '20px' }}>
          <Button onClick={handleSkip} variant="text" disabled={isSubmittingFeedback}>
            Skip
            {isSubmittingFeedback && currentAction === 'skip' ? (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            ) : null}
          </Button>
          <Button onClick={handleSubmit} variant="contained" disabled={isSubmittingFeedback}>
            Submit
            {isSubmittingFeedback && currentAction === 'submit' ? (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            ) : null}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Feedback;

import { type UserRole } from '@liftai/asset-management-types';

type ROLESType = {
  client: NonNullable<UserRole>;
  consultant: NonNullable<UserRole>;
  service_provider: NonNullable<UserRole>;
};

export const ROLES: ROLESType = {
  client: 'client',
  consultant: 'consultant',
  service_provider: 'service_provider',
};

const NO_ACCESS_ROLE = 'no_access_role' as const;

// List ot features
export const PORTFOLIO_PERFORMANCE_KPIS = 'canAccessPortfolioPerformanceKPIs' as const;
export const TICKETS_PERFORMANCE = 'canAccessTicketsPerformance' as const;
export const BOTTOM_FIVE_PERFORMANCE = 'canAccessBottomFivePerformance' as const;
export const NAVIGATE_TO_PORTFOLIO_PAGE = 'canAccessPortfolioPage' as const;
export const NAVIGATE_TO_PROPERTY_PAGE = 'canAccessPropertyDetailsPage' as const;
export const NAVIGATE_TO_TICKETS_PAGE = 'canAccessTicketsPage' as const;
export const NAVIGATE_TO_INVOICES_PAGE = 'canAccessInvoicesPage' as const;
export const UNIVERSAL_SEARCH = 'canAccessUniversalSearch' as const;
export const UPLOAD_DOCUMENT_FORM = 'canAccessUploadDocumentForm' as const;
export const PENDING_INVOICES_NOTIFICATION = 'canAccessPendingInvoicesNotification' as const;
export const ACTIONS_ON_TICKETS = 'canAccessActionMenuOnTickets' as const;
export const DOWNLOAD_TICKET_FILE = 'canAccessTicketFile' as const;
export const TICKET_DATA_STATUS = 'canAccessTicketsDataStatus' as const;
export const EDIT_CONTRACT = 'canAccessEditContract' as const;
export const EDIT_ELEVATOR = 'canAccessEditElevator' as const;
export const INSIGHTS_TAB = 'canAccessInsightsTab' as const;
export type CAN_ACCESS_TO =
  | typeof PORTFOLIO_PERFORMANCE_KPIS
  | typeof TICKETS_PERFORMANCE
  | typeof BOTTOM_FIVE_PERFORMANCE
  | typeof NAVIGATE_TO_PORTFOLIO_PAGE
  | typeof NAVIGATE_TO_PROPERTY_PAGE
  | typeof NAVIGATE_TO_TICKETS_PAGE
  | typeof NAVIGATE_TO_INVOICES_PAGE
  | typeof UNIVERSAL_SEARCH
  | typeof UPLOAD_DOCUMENT_FORM
  | typeof PENDING_INVOICES_NOTIFICATION
  | typeof ACTIONS_ON_TICKETS
  | typeof DOWNLOAD_TICKET_FILE
  | typeof TICKET_DATA_STATUS
  | typeof EDIT_CONTRACT
  | typeof EDIT_ELEVATOR
  | typeof INSIGHTS_TAB;
// end list of features

const NO_ACCESS: Record<CAN_ACCESS_TO, boolean> = {
  [PORTFOLIO_PERFORMANCE_KPIS]: false,
  [TICKETS_PERFORMANCE]: false,
  [BOTTOM_FIVE_PERFORMANCE]: false,
  [NAVIGATE_TO_PROPERTY_PAGE]: false,
  [NAVIGATE_TO_TICKETS_PAGE]: false,
  [UNIVERSAL_SEARCH]: false,
  [UPLOAD_DOCUMENT_FORM]: false,
  [PENDING_INVOICES_NOTIFICATION]: false,
  [ACTIONS_ON_TICKETS]: false,
  [DOWNLOAD_TICKET_FILE]: false,
  [TICKET_DATA_STATUS]: false,
  [NAVIGATE_TO_INVOICES_PAGE]: false,
  [NAVIGATE_TO_PORTFOLIO_PAGE]: false,
  [EDIT_CONTRACT]: false,
  [EDIT_ELEVATOR]: false,
  [INSIGHTS_TAB]: false,
};

const featuresByRole: Record<
  NonNullable<UserRole> | typeof NO_ACCESS_ROLE,
  Record<CAN_ACCESS_TO, boolean>
> = {
  consultant: {
    ...NO_ACCESS,
    [PORTFOLIO_PERFORMANCE_KPIS]: true,
    [TICKETS_PERFORMANCE]: true,
    [BOTTOM_FIVE_PERFORMANCE]: true,
    [NAVIGATE_TO_PORTFOLIO_PAGE]: true,
    [NAVIGATE_TO_PROPERTY_PAGE]: true,
    [NAVIGATE_TO_TICKETS_PAGE]: true,
    [NAVIGATE_TO_INVOICES_PAGE]: true,
    [UNIVERSAL_SEARCH]: true,
    [UPLOAD_DOCUMENT_FORM]: true,
    [PENDING_INVOICES_NOTIFICATION]: true,
    [ACTIONS_ON_TICKETS]: true,
    [DOWNLOAD_TICKET_FILE]: true,
    [TICKET_DATA_STATUS]: true,
    [EDIT_CONTRACT]: true,
    [EDIT_ELEVATOR]: true,
    [INSIGHTS_TAB]: true,
  },
  client: {
    ...NO_ACCESS,
    [PORTFOLIO_PERFORMANCE_KPIS]: true,
    [NAVIGATE_TO_PORTFOLIO_PAGE]: true,
    [NAVIGATE_TO_TICKETS_PAGE]: true,
    [DOWNLOAD_TICKET_FILE]: true,
  },
  service_provider: {
    ...NO_ACCESS,
  },
  [NO_ACCESS_ROLE]: NO_ACCESS,
};

const getFeaturesByRole = (userRole: UserRole) => {
  if (!userRole) {
    return featuresByRole[NO_ACCESS_ROLE];
  }

  return featuresByRole[userRole];
};

/**
 * `canAccess` verifies if a user with an specific role has access to certain feature
 * @param userRole
 * @param feature
 * @returns
 */
export const canAccess = (userRole: UserRole, feature: CAN_ACCESS_TO) => {
  const features = getFeaturesByRole(userRole);
  return features[feature] ?? false;
};

import type { Attachment } from '@liftai/asset-management-types';
import {
  alpha,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { useSearchParams } from 'react-router-dom';

import AttachmentUploader from '../AttachmentUploader';
import type { UploadDocumentType } from './AddManuallyDropdown';
import AddManuallyDropdown from './AddManuallyDropdown';

interface IUploadDocumentDialog {
  open: boolean;
  onClose: () => void;
  onTypeSelected: (type: UploadDocumentType) => void;
  onFilesUploaded: (attachments: Attachment[]) => void;
}

interface IBlueActionBoxProps extends React.PropsWithChildren {
  alignItems?: React.CSSProperties['alignItems'];
}

const BlueActionBox: React.FC<IBlueActionBoxProps> = ({ children, alignItems = 'center' }) => {
  const theme = useTheme();

  return (
    <Grid size={{ xs: 12, sm: 6 }}>
      <Box
        padding={2}
        borderRadius={1}
        height={1}
        justifyContent="center"
        alignItems={alignItems}
        display="flex"
        flexDirection="column"
        bgcolor={alpha(theme.palette.info.main, 0.12)}
      >
        {children}
      </Box>
    </Grid>
  );
};

const UploadDocumentDialog = ({
  open,
  onClose,
  onTypeSelected,
  onFilesUploaded,
}: IUploadDocumentDialog) => {
  const [, setSearchParams] = useSearchParams();

  const handleOnCancel = () => {
    onClose();
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete('propertyId');
      newParams.delete('carId');
      return newParams;
    });
  };
  return (
    <Dialog open={open} onClose={handleOnCancel} maxWidth="md">
      <DialogTitle component="h6">
        <Grid container justifyContent="space-between">
          Add to Property
          <IconButton onClick={handleOnCancel}>
            <GridCloseIcon />
          </IconButton>{' '}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} alignItems="stretch">
          <Grid size={{ xs: 12 }}>
            <Typography variant="subtitle2">Add Invoices, Tickets and other documents</Typography>
            <Typography variant="body2">Upload a file or add manually</Typography>
          </Grid>
          <BlueActionBox alignItems="stretch">
            <Box display="flex" justifyContent="center">
              <Typography variant="subtitle2">Add image</Typography>
            </Box>
            <AttachmentUploader
              fileSizeLimit={5} // 5MB
              acceptedFileTypes={{ 'application/pdf': ['.pdf'] }}
              acceptedFileTypesLabel="PDF"
              onFilesUploaded={onFilesUploaded}
            />
          </BlueActionBox>
          <BlueActionBox>
            <AddManuallyDropdown onTypeSelected={onTypeSelected} />
          </BlueActionBox>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default UploadDocumentDialog;

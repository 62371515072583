import { zodResolver } from '@hookform/resolvers/zod';
import type { PropertyForm } from '@liftai/asset-management-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { z } from 'zod';

import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';
import type { LAIUseFormReturn, LiftAIFormProps } from '../uploadDocument/SelectDocumentTypeDialog';

export const violationFormSchema = z.object({
  car_id: z.string(),
  code: z.string().min(1, { message: 'Violation code is required' }),
  location: z.string().min(1, { message: 'Location is required' }),
  correction_due: z.string().refine((date) => new Date(date) > new Date(), {
    message: 'Correction due date must be in the future',
  }),
  date_open: z.string().refine((date) => new Date(date) <= new Date(), {
    message: 'Open date cannot be in the future',
  }),
  responsibility: z.enum(['owner', 'county', 'service_provider']),
  description: z.string().min(10, { message: 'Description must be at least 10 characters' }),
});

const violationFormSchemaWithPropertyId = violationFormSchema.extend({
  property_id: z.string().optional(),
});

export type ViolationFormData = z.infer<typeof violationFormSchemaWithPropertyId>;

const propertyOptionsFetcher = async (): Promise<{ properties: PropertyForm[] }> => {
  const apiClient = getApiClient();

  try {
    const properties = await apiClient.properties.getAll({ variant: 'form' });
    return { properties };
  } catch (error) {
    console.error('Failed to fetch properties:', error);
    return { properties: [] };
  }
};

export const ViolationFields = () => {
  const { control, watch, setValue } = useFormContext<ViolationFormData>();
  const propertyId = watch('property_id');
  const [searchParams] = useSearchParams();
  const propertyIdFromParams = searchParams.get('propertyId');

  // Fetch properties
  const { data } = useSWR('ViolationForm.Options', propertyOptionsFetcher);

  // Use the usePropertyCars hook to get cars for selected property
  const { cars, loading: carsLoading } = usePropertyCars(propertyId || propertyIdFromParams);

  // Effect to set initial property value when data is loaded
  useEffect(() => {
    if (propertyIdFromParams && data?.properties) {
      // Check if the propertyId from params exists in the options
      const propertyExists = data.properties.some(
        (property) => property.id === propertyIdFromParams,
      );
      if (propertyExists) {
        setValue('property_id', propertyIdFromParams);
      }
    }
  }, [propertyIdFromParams, data?.properties, setValue]);

  const formFields: FieldDef[] = useMemo(
    () => [
      ...(!propertyIdFromParams
        ? [
            {
              name: 'property_id',
              label: 'Property',
              type: 'autocomplete' as const,
              options:
                data?.properties?.map((property) => ({
                  id: property.id,
                  label: property.name,
                })) ?? [],
              loading: !data?.properties,
              required: true,
              gridProps: { xs: 12 },
            },
          ]
        : []),
      {
        name: 'car_id',
        label: 'Car',
        type: 'autocomplete',
        options:
          cars?.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          })) ?? [],
        loading: carsLoading,
        required: true,
        // disabled: !!propertyIdFromParams,
        gridProps: { xs: 12 },
      },
      {
        name: 'code',
        label: 'Violation Code',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'location',
        label: 'Location',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'date_open',
        label: 'Date Open',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'correction_due',
        label: 'Correction Due',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'responsibility',
        label: 'Responsibility',
        type: 'select',
        required: true,
        options: [
          { id: 'owner', label: 'Owner' },
          { id: 'service_provider', label: 'Service Provider' },
          { id: 'county', label: 'County' },
        ],
        gridProps: { xs: 12 },
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
    ],
    [data?.properties, cars, carsLoading, propertyIdFromParams],
  );

  return (
    <>
      <Typography variant="h6" my={2}>
        Violation Details
      </Typography>
      <Grid container spacing={2}>
        {formFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};

export const ViolationForm: React.FC<LiftAIFormProps<ViolationFormData>> = ({
  children,
  document,
  onSubmit,
  initialData,
}) => {
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, reset } = useFormContext<ViolationFormData>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset(initialData);
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
      onSubmit={handleSubmit(
        async (data) => {
          try {
            const apiClient = getApiClient();
            const violationData = violationFormSchema.parse(data);

            const violation = await apiClient.violations.create({
              car_id: violationData.car_id,
              code: violationData.code,
              correction_due: violationData.correction_due,
              date_open: violationData.date_open,
              location: violationData.location,
              description: violationData.description,
              responsibility: violationData.responsibility,
            });

            onSubmit(violation);
            // Mutate both certificate and violation data for this car
            void mutate(['violation', violationData.car_id]);

            showEntityActionSnackbar(
              {
                name: 'Violation',
                action: ActionEnum.Create,
                id: violationData.car_id,
              },
              {
                variant: 'success',
              },
            );
          } catch (err) {
            console.error('Violation form error:', err);
            showSnackbar('Violation failed to save', {
              variant: 'error',
            });
          }
        },
        (err) => {
          console.log('Violation validation error:', err);
        },
      )}
    >
      {children}
    </form>
  );
};

function useViolationForm(): LAIUseFormReturn<ViolationFormData> {
  const hookForm = useForm<ViolationFormData>({
    mode: 'all',
    resolver: zodResolver(violationFormSchema),
    defaultValues: {
      car_id: '',
      code: '',
      correction_due: '',
      location: '',
      description: '',
      responsibility: 'owner',
    },
  });

  return {
    hookForm,
    Form: ViolationForm,
    Fields: ViolationFields,
  };
}

export { useViolationForm };

import { createContext, useContext } from 'react';

import type { ItemType } from '~/components/common/SelectCheckbox';
import { getDefaultMonthRange } from '~/components/utils/dateUtils';

type GlobalFiltersOptions = {
  portfolios: ItemType[];
  serviceProviders: ItemType[];
  regions: ItemType[];
};

type GlobalFiltersSelection = {
  portfolios: string[];
  serviceProviders: string[];
  regions: string[];
  date: {
    start: Date;
    end: Date;
  };
};

export type GlobalFilterContext = {
  options: GlobalFiltersOptions;
  selectedFilters: GlobalFiltersSelection;
  onGlobalFiltersChange: (filters: GlobalFiltersSelection) => void;
  resetGlobalFilters: () => void;
};

const defaultDate = new Date(0);

const initialContextState: GlobalFilterContext = {
  options: {
    portfolios: [],
    serviceProviders: [],
    regions: [],
  },
  selectedFilters: {
    portfolios: [],
    serviceProviders: [],
    regions: [],
    date: { start: defaultDate, end: defaultDate },
  },
  onGlobalFiltersChange: () => {},
  resetGlobalFilters: () => {},
};

export const getDefaultGlobalFilters = (): GlobalFiltersSelection => {
  const defaultMonthRange = getDefaultMonthRange();
  return {
    portfolios: [],
    serviceProviders: [],
    regions: [],
    date: defaultMonthRange,
  };
};

export const useGlobalFilterContext = () => {
  const context = useContext(GlobalFilterContext);
  if (!context) {
    //throw new Error('useGlobalFilterContext must be used within a GlobalFilterContextProvider');
    console.error('useGlobalFilterContext must be used within a GlobalFilterContextProvider');
  }
  return context;
};

export const GlobalFilterContext = createContext<GlobalFilterContext>(initialContextState);

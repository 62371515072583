import type { Invoice, InvoiceStatusKey } from '@liftai/asset-management-types';
import {
  InvoiceKind,
  InvoiceStatus,
  invoiceStatusToLabelMap,
} from '@liftai/asset-management-types';
import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { useRef } from 'react';

import { toDateFormatMMddyyyy } from '~/utils/format';

interface StampOverlayProps {
  status: InvoiceStatusKey;
}

interface StampedInvoiceTemplateProps {
  invoice: Invoice;
  containerRef: React.RefObject<HTMLDivElement>;
  stampLogo: string;
}

const InnerBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  position: 'relative',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: '1px',
  paddingRight: '1px',
  height: 'auto',
  borderStyle: 'solid',
  borderWidth: '2px',
  position: 'relative',
}));

const TitleHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  height: theme.spacing(9),
  width: '100%',
  textAlign: 'center',
  lineHeight: 'unset',
  padding: theme.spacing(2),
  color: 'white',
  fontWeight: '900',
}));

const ProposedAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginRight: '0.5rem',
  color: 'rgba(0, 0, 0, 0.6)',
  paddingBottom: '1rem',
}));

const AmountAfterReview = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginRight: '0.5rem',
  color: '#000000',
  paddingBottom: theme.spacing(2),
}));

const DateOfDecision = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
}));

const Logo = styled('img')(({ theme }) => ({
  width: '60%',
  height: 'auto',
  float: 'right',
}));

const InvoiceNumberBox = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  textAlign: 'left',
  padding: theme.spacing(2),
  paddingBottom: 0,
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '500',
}));

const StampContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<StampOverlayProps>(({ theme, status }) => ({
  width: '135px',
  backgroundColor: '#ffffff',
  border: `1px solid ${
    status === InvoiceStatus.Approved
      ? theme.palette.success.main
      : status === InvoiceStatus.PartialApproval
        ? theme.palette.warning.main
        : theme.palette.error.main
  }`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
  opacity: 0.75,
  padding: 0,
  margin: 0,
}));

const StampHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<StampOverlayProps>(({ theme, status }) => ({
  width: '100%',
  padding: '2px',
  backgroundColor:
    status === InvoiceStatus.Approved
      ? theme.palette.success.main
      : status === InvoiceStatus.PartialApproval
        ? theme.palette.warning.main
        : theme.palette.error.main,
  color: '#ffffff',
  textAlign: 'center',
  fontWeight: 'bolder',
  fontSize: '12px',
}));

const StampContent = styled(Box)({
  padding: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2px',
  backgroundColor: '#ffffff',
});

const StampLogo = styled('img')({
  height: '20px',
  objectFit: 'contain',
  margin: '4px 0',
});

const StampDate = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '9px',
  fontWeight: 300,
});

const StampSignature = styled(Typography)({
  fontFamily: '"Qwitcher Grypen", cursive',
  fontWeight: 700,
  fontSize: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  lineHeight: '1',
  marginTop: '-1px',
  marginBottom: '2px',
});

export const Stamp = ({
  invoice,
  status,
  stampLogo,
}: {
  invoice: Invoice;
  status: InvoiceStatusKey;
  stampLogo: string;
}) => {
  if (!invoice.dateStamped) {
    throw new Error('Date stamped is required');
  }

  return (
    <StampContainer status={status}>
      <StampHeader status={status}>
        {invoice.kind === InvoiceKind.Invoice ? 'Invoice' : 'Proposal'}{' '}
        {invoiceStatusToLabelMap.get(status)}
      </StampHeader>
      <StampContent>
        <StampLogo src={stampLogo} alt="Consultant Stamp" crossOrigin="anonymous" />
        <StampDate>Date of decision: {toDateFormatMMddyyyy(invoice.dateStamped)}</StampDate>
        <StampSignature>{invoice.consultant?.fullName}</StampSignature>
      </StampContent>
    </StampContainer>
  );
};

export const StampedInvoiceTemplate = ({
  invoice,
  containerRef,
  stampLogo,
}: StampedInvoiceTemplateProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(containerRef?.current);
  const invoiceStatusOption = invoiceStatusToLabelMap.get(invoice.status);

  const bgColorMap: Map<InvoiceStatusKey, string> = new Map([
    [InvoiceStatus.Approved, theme.palette.success.main],
    [InvoiceStatus.PartialApproval, theme.palette.warning.main],
    [InvoiceStatus.Rejected, theme.palette.error.main],
  ]);

  if (!invoice.dateStamped) {
    throw new Error('Date stamped is required');
  }

  return (
    <Box ref={ref} height="auto">
      <InnerBox>
        <TitleHeader
          sx={{
            background: bgColorMap.get(invoice.status),
          }}
          variant="h2"
        >
          {invoice.kind == InvoiceKind.Invoice ? 'Invoice' : 'Proposal'} {invoiceStatusOption}
        </TitleHeader>

        <ContentBox borderColor={bgColorMap.get(invoice.status)}>
          <InvoiceNumberBox>Document #: {invoice.number}</InvoiceNumberBox>
          {invoiceStatusOption !== 'Approved' && (
            <>
              <div style={{ padding: theme.spacing(2) }}>
                <ProposedAmount variant="h6" fontWeight="bold">
                  Amount: ${invoice.proposedAmount}
                </ProposedAmount>
                {invoice.countTowardsSavings || invoiceStatusOption === 'Partial Approval' ? (
                  <AmountAfterReview variant="h6" fontWeight="bold">
                    Amount after review: $
                    {invoiceStatusOption === 'Rejected' ? 0 : invoice.reviewedAmount}
                  </AmountAfterReview>
                ) : null}
              </div>
            </>
          )}
          {invoice.addStampedReason ? (
            <div
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1rem',
                marginRight: '0.5rem',
                color: '#000000',
                padding: theme.spacing(2),
              }}
            >
              <strong
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Reason:
              </strong>
              <p
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  maxWidth: '100%',
                }}
              >
                {invoice.reason}
              </p>
            </div>
          ) : null}
        </ContentBox>
      </InnerBox>
      <Grid
        container
        sx={{
          padding: '0rem 2rem',
        }}
      >
        <Grid size={{ xs: 6 }}>
          <DateOfDecision>
            Date of decision:{' '}
            {toDateFormatMMddyyyy(invoice.dateStamped ?? new Date().toISOString())} Consultant:{' '}
            {invoice.consultant?.fullName}
          </DateOfDecision>
        </Grid>
        <Grid size={{ xs: 6 }}>
          {invoice.stampFile ? (
            <Logo src={stampLogo} alt="Consultant Logo" crossOrigin="anonymous" />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

import type { SvgIconComponent } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HandymanIcon from '@mui/icons-material/Handyman';
import type { SvgIconProps } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import {
  createBrowserRouter,
  isRouteErrorResponse,
  redirect,
  useRouteError,
} from 'react-router-dom';

import { PageHeader } from '~/components/global/PageHeader';
import { ContractIcon, InvoiceIcon, PortfolioIcon, TicketIcon } from '~/components/icons';
import { generateErrorBoundary } from '~/components/utils/errorBoundaries';
import { AppShell, loader as appShellLoader } from '~/routes/_app';

export const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <html style={{ height: '100%' }} lang="en">
      <head>
        <title>Oops!</title>
      </head>
      <body style={{ height: '100%', margin: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          height="100%"
          flex="1 0 auto"
          bgcolor="#F9FAFB"
        >
          <img width="200px" src="/images/lift-logo.svg" alt="Lift AI Logo" />
          <Typography variant="h2" pt={4} whiteSpace="pre-wrap" textAlign="center">
            {isRouteErrorResponse(error)
              ? `${error.status}\n${error.statusText}`
              : error instanceof Error
                ? error.message
                : 'Unknown Error'}
          </Typography>
          {isRouteErrorResponse(error) && error.status === 403 ? (
            <Button href="/logout">Logout and try again</Button>
          ) : null}
        </Box>
      </body>
    </html>
  );
};

export const generateTopLevelErrorBoundary = ({
  title,
  icon,
}: {
  title: string;
  icon: SvgIconComponent | React.FC<SvgIconProps>;
}) => {
  const ErrorBoundary = generateErrorBoundary();

  return (
    <>
      <PageHeader title={title} icon={icon} />
      <ErrorBoundary />
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppShell />,
    errorElement: <ErrorBoundary />,
    loader: appShellLoader,
    children: [
      {
        path: '',
        loader: () => redirect('/portfolio'),
      },
      {
        path: 'tickets',
        lazy: () => import('~/routes/_app.tickets._index'),
        errorElement: generateTopLevelErrorBoundary({
          title: 'Tickets',
          icon: TicketIcon,
        }),
      },
      {
        path: 'spending',
        lazy: () => import('~/routes/_app.spending._index'),
        errorElement: generateTopLevelErrorBoundary({
          title: 'Spending',
          icon: InvoiceIcon,
        }),
      },
      {
        path: 'portfolio',
        errorElement: generateTopLevelErrorBoundary({
          title: 'Portfolio',
          icon: PortfolioIcon,
        }),
        children: [
          {
            path: '',
            lazy: () => import('~/routes/_app.portfolio._index'),
          },
          {
            id: 'property-detail',
            path: ':id',
            lazy: () => import('~/routes/_app.portfolio.$id'),
          },
        ],
      },
      {
        path: 'compliance',
        lazy: () => import('~/routes/_app.compliance._index'),
        errorElement: generateTopLevelErrorBoundary({
          title: 'Compliance',
          icon: ContractIcon,
        }),
      },
      {
        path: 'insights',
        lazy: () => import('~/routes/_app.insights._index'),
        errorElement: generateTopLevelErrorBoundary({
          title: 'Insights',
          icon: AutoAwesomeIcon,
        }),
      },
      {
        id: 'tools',
        path: 'tools',
        errorElement: generateTopLevelErrorBoundary({
          title: 'Tools',
          icon: HandymanIcon,
        }),
        children: [
          {
            path: '',
            lazy: () => import('~/routes/tools/index'),
          },
          {
            path: 'tickets-csv',
            lazy: () => import('~/routes/tools/tickets-csv'),
          },
          {
            path: 'bulk-doc',
            lazy: () => import('~/routes/tools/bulk-doc'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    lazy: () => import('~/routes/login'),
  },
  {
    path: '/logout',
    lazy: () => import('~/routes/logout'),
  },
  {
    path: '/reset-password',
    lazy: () => import('~/routes/reset-password'),
  },
  {
    path: '/signup',
    lazy: () => import('~/routes/signup'),
  },
]);

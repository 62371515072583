import type {
  API_TicketFilterSearchParams,
  EntryTypeKey,
  Ticket,
  TicketWithPortfolio,
} from '@liftai/asset-management-types';
import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';

import { dateToDateString } from '~/components/utils/dateUtils';
import { GlobalFilterContext, useGlobalFilterContext } from '~/contexts/globalFilterContext';
import { syncSearchParamsWithFilterModel } from '~/hooks/useGlobalFilters';
import { mergeFilterModel, useGridFilterModel } from '~/hooks/useGridFilterModel';
import { getApiClient } from '~/utils/api';

const ticketSearchParam = 'ticketId';

const selectedPeriodRangeAfterId = 'selected-period-range-after';
const selectedPeriodRangeBeforeId = 'selected-period-range-before';

const ticketFilterModelToQueryParamMap = {
  [selectedPeriodRangeAfterId]: 'start_date',
  [selectedPeriodRangeBeforeId]: 'end_date',
} as const;

export const getFilterModelFromQueryParams = (searchParams: URLSearchParams): GridFilterModel => {
  const ticketId = searchParams.get(ticketSearchParam);
  const entryType = searchParams.getAll('entry_type') as EntryTypeKey[];
  const property = searchParams.get('property');
  const carId = searchParams.get('car_id');
  const number = searchParams.get('number');
  const items: GridFilterModel['items'] = [];

  if (ticketId) {
    items.push({
      id: 'id',
      field: 'id',
      value: ticketId,
      operator: 'equals',
    });
  }

  if (entryType.length > 0) {
    items.push({
      id: 'entryType',
      field: 'entryType',
      value: entryType,
      operator: 'isAnyOf',
    });
  }

  if (property) {
    // This will override the stored filter model
    // it's sort of a hack. The other way to do it would be a customizer
    // to mergeFilterModel
    items.push({
      id: 'property',
      field: 'property',
      operator: 'equals',
    });
  }

  if (carId) {
    items.push({
      id: 'carId',
      field: 'carId',
      operator: 'equals',
      value: carId,
    });
  }

  if (number) {
    items.push({
      id: 'number',
      field: 'number',
      operator: 'contains',
      value: number,
    });
  }

  return { items };
};

export const mapTicketToTicketWithPortfolio = (ticket: Ticket): TicketWithPortfolio => {
  return {
    ...ticket,
    // Flat the portfolio to be used by the table
    portfolio: ticket.property.portfolio,
  } as TicketWithPortfolio;
};

type BuildFilterParamsOpts = {
  page: number;
  pageSize: number;
  propertyId: string | null;
  carId: string | null;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  globalFilters: GlobalFilterContext['selectedFilters'];
};

const buildFilterParams = ({
  propertyId,
  page,
  pageSize,
  filterModel,
  sortModel,
  carId,
  globalFilters,
}: BuildFilterParamsOpts): API_TicketFilterSearchParams | null => {
  // NOTE: MUI DataGrid passes odd values sometimes while loading. This is a safety check.
  if (pageSize <= 0 || page < 0) {
    return null;
  }

  return {
    limit: pageSize,
    offset: page * pageSize,
    ordering:
      sortModel.length > 0
        ? sortModel.map((item) => `${item.sort === 'desc' ? '-' : ''}${item.field}`).join(',')
        : '-startedTime',
    search: filterModel.quickFilterValues?.map((value) => value).join(' '),
    property: propertyId ?? undefined,
    carId: carId ?? undefined,
    propertyName: filterModel.items.find((item) => item.field === 'property')?.value,
    number: filterModel.items.find((item) => item.field === 'number')?.value,
    entryType: filterModel.items.find((item) => item.field === 'entryType')?.value,
    portfolios: globalFilters.portfolios,
    serviceProviders: globalFilters.serviceProviders,
    regions: globalFilters.regions,
    startedTimeAfter: dateToDateString(globalFilters.date.start) ?? undefined,
    startedTimeBefore: dateToDateString(globalFilters.date.end) ?? undefined,
  };
};

const ticketsFetcher = async ([_, params]: readonly ['ticket', API_TicketFilterSearchParams]) => {
  const apiClient = getApiClient();
  return await apiClient.tickets.filter(params);
};

const defaultSortModel: GridSortModel = [{ field: 'startedTime', sort: 'desc' }];

type UseTicketsTableModelOpts = {
  initialFilterModel?: GridFilterModel;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
};

export function useTicketsTableModel({
  initialFilterModel,
  onFilterModelChange,
}: UseTicketsTableModelOpts = {}) {
  const [searchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const filterModelFromParams = getFilterModelFromQueryParams(searchParams);

  const { filterModel, setFilterModel, quickFilterProps } = useGridFilterModel({
    initialState: initialFilterModel
      ? mergeFilterModel(initialFilterModel, filterModelFromParams)
      : filterModelFromParams,
    quickFilterPlaceHolder: 'Search Tickets...',
  });

  // Can be used for persistence if the consuming component needs it
  useEffect(() => {
    onFilterModelChange?.(filterModel);
  }, [filterModel, onFilterModelChange]);

  return {
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
    quickFilterProps,
  };
}

export type UseTicketsOptions = {
  propertyId?: string | null;
  carId?: string | null;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  onFilterModelChange: (filterModel: GridFilterModel) => void;
};

export function useTickets({
  propertyId = null,
  paginationModel,
  filterModel,
  sortModel,
  onFilterModelChange: parentOnFilterModelChange,
}: UseTicketsOptions) {
  const { selectedFilters } = useGlobalFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const carId = searchParams.get('car_id') ?? null;
  const filterParams = useMemo(
    () =>
      buildFilterParams({
        propertyId,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        filterModel,
        sortModel,
        carId,
        globalFilters: selectedFilters,
      }),
    [propertyId, paginationModel, filterModel, sortModel, carId, selectedFilters],
  );

  const [filterParamsDebounced] = useDebounceValue(filterParams, 500);

  const onExport = useCallback(async () => {
    const apiClient = getApiClient();
    const { results } = await apiClient.tickets.filter({
      ...filterParams,
      limit: undefined,
      offset: undefined,
    });
    return results.map((result) => mapTicketToTicketWithPortfolio(result));
  }, [filterParams]);

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      parentOnFilterModelChange(filterModel);
      syncSearchParamsWithFilterModel({
        searchParams,
        filterModel,
        setSearchParams,
        filterModelToQueryParamMap: ticketFilterModelToQueryParamMap,
      });
    },
    [parentOnFilterModelChange, searchParams, setSearchParams],
  );

  const { data, isLoading } = useSWR(
    filterParamsDebounced ? (['ticket', filterParamsDebounced] as const) : null,
    ticketsFetcher,
  );

  const tickets = data?.results?.map((result) => mapTicketToTicketWithPortfolio(result)) ?? [];
  const numTickets = data?.metadata.numTickets ?? 0;
  const metadata = data?.metadata ?? null;

  return {
    tickets,
    numTickets,
    metadata,
    isLoading,
    onExport,
    onFilterModelChange,
  };
}

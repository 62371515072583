import { type API_PortfolioKPIsResponse, callbackTypeValues } from '@liftai/asset-management-types';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';

import {
  MetricsTrends,
  PerformanceDoubleMetricWidgetGrid,
  PerformanceSingleMetricWidgetGrid,
} from '~/components/portfolio/PortfolioOverviewWidgets';
import getKpiFilter, { AvailableKpis, type AvailableKpisType } from '~/lib/portfolio/getKpiFilter';
import getKpisColumnWidth from '~/lib/portfolio/getKpisColumnWidth';
import {
  formatDecimalStrict,
  formatNumber,
  formatPercent,
  formatSecondstoHours,
  formatSecondstoMinutes,
} from '~/utils/format';
import { buildUrlForPropertyTickets, buildUrlForTickets, urlToHref } from '~/utils/urlBuilder';

export type PortfolioOverviewProps = {
  summary: API_PortfolioKPIsResponse['summary'] | null;
  trends: {
    date: string;
    maintenanceCompletion: number;
  }[];
  searchParams?: URLSearchParams;
  addLinks?: boolean;
  kpisToShow?: AvailableKpisType[];
  propertyId?: string;
};

export const getPortfolioOverviewSearchParams = (searchParams: URLSearchParams) => {
  const next = new URLSearchParams();

  const startDate = searchParams.get('start_date');
  const endDate = searchParams.get('end_date');
  const property = searchParams.get('property');

  if (startDate) {
    next.set('start_date', startDate);
  }

  if (endDate) {
    next.set('end_date', endDate);
  }

  if (property) {
    next.set('property', property);
  }

  return next;
};

export const PortfolioOverview: React.FC<PortfolioOverviewProps> = ({
  summary,
  trends,
  searchParams,
  kpisToShow = [
    AvailableKpis.maintenanceCompletion,
    AvailableKpis.maintenanceVisit,
    AvailableKpis.emergencyCalls,
    AvailableKpis.callbacks,
    AvailableKpis.callbackMedianTime,
  ],
  propertyId,
}) => {
  const data = trends.map((trend) => [trend.date, trend.maintenanceCompletion] as [string, number]);
  const baseUrlArgs = {
    startDate: searchParams?.get('start_date') ?? '',
    endDate: searchParams?.get('end_date') ?? '',
  };

  const callbacksPath = propertyId
    ? buildUrlForPropertyTickets({
        propertyId,
        entryType: callbackTypeValues,
        ...baseUrlArgs,
      })
    : buildUrlForTickets({
        entryType: callbackTypeValues,
        ...baseUrlArgs,
      });
  const visitTicketsPath = propertyId
    ? buildUrlForPropertyTickets({
        propertyId,
        entryType: ['visit'],
        ...baseUrlArgs,
      })
    : buildUrlForTickets({
        entryType: ['visit'],
        ...baseUrlArgs,
      });

  const emergencyTicketsPath = propertyId
    ? buildUrlForPropertyTickets({
        propertyId,
        entryType: ['emergency'],
        ...baseUrlArgs,
      })
    : buildUrlForTickets({
        entryType: ['emergency'],
        ...baseUrlArgs,
      });

  const columnWidth = getKpisColumnWidth(kpisToShow);
  const showKpi = getKpiFilter(kpisToShow);

  return (
    <Paper>
      <Typography p={3} pl={2} pb={2} variant="h5">
        Performance KPI{"'"}s
      </Typography>

      <Grid container sx={{ flexGrow: 1 }}>
        <Grid container size={{ xs: columnWidth.kpis }} sx={{ flexGrow: 1 }}>
          {showKpi(AvailableKpis.maintenanceCompletion) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.maintenanceCompletion }}
              navigateTo={urlToHref(visitTicketsPath)}
              metricName="Maintenance Completion"
              metricValue={formatPercent(summary?.maintenanceCompletion ?? null)}
              metricInformation="Maintenance Completion represents the percentage of contractually required preventive maintenance visits for each elevator actually performed."
            />
          )}
          {showKpi(AvailableKpis.maintenanceVisit) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.maintenanceVisit }}
              navigateTo={urlToHref(visitTicketsPath)}
              metricName="Maintenance Visits"
              metricValue={formatNumber(summary?.maintenanceVisit ?? null)}
              metricInformation="Maintenance Visit identifies how many visits an elevator received in the selected period"
            />
          )}
          {showKpi(AvailableKpis.maintenanceHours) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.maintenanceHours }}
              navigateTo={urlToHref(visitTicketsPath)}
              metricName="Maintenance"
              metricValue={formatSecondstoHours(summary?.maintenanceSecs ?? null)}
              metricInformation="Maintenance Hours represents the amount of hours of maintenance performed in the selected period"
            />
          )}
          {showKpi(AvailableKpis.uptime) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.uptime }}
              navigateTo={urlToHref(callbacksPath)}
              metricName="Uptime"
              metricValue={formatPercent(summary?.uptime ?? null)}
              metricInformation="Uptime represents the percentage of time the elevators are available for use (excluding scheduled repair, testing and maintenance)"
            />
          )}
          {showKpi(AvailableKpis.emergencyCalls) && (
            <PerformanceDoubleMetricWidgetGrid
              size={{ xs: columnWidth.emergencyCalls }}
              navigateTo={urlToHref(emergencyTicketsPath)}
              metrics={[
                {
                  name: 'Annualized Emergency Call rate',
                  value: formatDecimalStrict(summary?.emergencyCallbackRate ?? null),
                },
                { name: 'Emergency Calls', value: formatNumber(summary?.emergencyCalls ?? null) },
              ]}
              metricsInformation="Emergency Call Rate Identifies how many times an elevator breaks down each year causing an entrapment or urgent situation. Emergency Callbacks identifies how many times an elevator broke down causing an entrapment in the selected period"
            />
          )}
          {showKpi(AvailableKpis.emergencyResponse) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.emergencyResponse }}
              navigateTo={urlToHref(emergencyTicketsPath)}
              metricName="Emergency Response"
              metricValue={formatSecondstoMinutes(
                summary?.avgEmergencyCallResponseTimeSecs ?? null,
              )}
              metricInformation="Emergency Response represents the average time between an emergency call is placed and the arrival time."
            />
          )}
          {showKpi(AvailableKpis.callbacks) && (
            <PerformanceDoubleMetricWidgetGrid
              size={{ xs: columnWidth.callbacks }}
              navigateTo={urlToHref(callbacksPath)}
              metrics={[
                {
                  name: 'Annualized Callback rate',
                  value: formatDecimalStrict(summary?.callbackRate ?? null),
                },
                { name: 'Callbacks', value: formatNumber(summary?.callbacks ?? null) },
              ]}
              metricsInformation="Annualized Callback Rate identifies how many times an elevator breaks down each year. Callback rate identifies how many times an elevator broke down in the selected period"
            />
          )}
          {showKpi(AvailableKpis.callbackMedianTime) && (
            <PerformanceSingleMetricWidgetGrid
              size={{ xs: columnWidth.callbackMedianTime }}
              navigateTo={urlToHref(callbacksPath)}
              metricName="Callbacks Median Time"
              metricValue={formatNumber(summary?.callbackMedianTime ?? null)}
              metricInformation="Callbacks Median Time identifies the mean time between Callbacks. Represented in days."
            />
          )}
        </Grid>
        <Grid size={{ xs: columnWidth.chart }} alignItems="center" justifyContent="center">
          <MetricsTrends data={data} />
        </Grid>
      </Grid>
    </Paper>
  );
};

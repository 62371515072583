import type { OEM } from '@liftai/asset-management-types';
import { CarTypeOptions, CarTypeValues, yearOptions } from '@liftai/asset-management-types';

import type { FieldDef } from '../form/utils';

interface CarFieldDefParams {
  oems?: OEM[] | undefined;
}

export const buildCarFieldDef = ({ oems }: CarFieldDefParams): FieldDef[] => {
  return [
    {
      name: 'ahjId',
      label: 'License',
      type: 'text',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'name',
      label: 'Unit Name',
      type: 'text',
      required: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'criticalUnit',
      label: 'Critical Unit',
      type: 'checkbox',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'elevatorType',
      label: 'Device Type',
      type: 'autocomplete',
      options: CarTypeValues.map((type) => ({
        value: type,
        label: CarTypeOptions[type],
        id: type,
      })),
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'installationYear',
      label: 'Installation Year',
      type: 'autocomplete',
      options: yearOptions.map((year) => ({
        label: year,
        id: year,
      })),
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'modernizationYear',
      label: 'Modernization Year',
      type: 'autocomplete',
      options: yearOptions.map((year) => ({
        label: year,
        id: year,
      })),
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'numLandings',
      label: 'Number of Landings',
      type: 'number',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'speedFpm',
      label: 'Speed (FPM)',
      type: 'number',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'controllerModel',
      label: 'Controller Model',
      type: 'text',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'driveType',
      label: 'Drive Type',
      type: 'text',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'lastSspiTest',
      label: 'Last SSPI Test',
      type: 'date',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'numEntrances',
      label: 'Number of Entrances',
      type: 'number',
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: 'oem',
      label: 'OEM',
      type: 'autocomplete',
      options:
        oems?.map((oem) => ({
          label: oem.name,
          id: oem.id,
        })) ?? [],
      required: false,
      gridProps: { xs: 12, sm: 6 },
    },
  ];
};

import { z } from 'zod';
import { baseLinkedAttachmentSchema } from './attachment';
import { metadataSchema } from './metadata';
import { propertyDisplaySchema } from './property';
import { providerSchema } from './provider';
export const contractAttachmentSchema = baseLinkedAttachmentSchema.extend({
    isSummarySheet: z.boolean(),
});
export const ContractType = {
    Full: '24/7',
    Standard: 'standard',
    StandardOt: 'standard-ot-differential',
    PlatinumOt: 'platinum-ot-differential',
    Other: 'other',
};
export const ContractTypeValues = Object.values(ContractType);
export const ContractTypeOptions = {
    [ContractType.Full]: '24/7',
    [ContractType.Standard]: 'Standard',
    [ContractType.StandardOt]: 'Standard OT Differential',
    [ContractType.PlatinumOt]: 'Platinum OT Differential',
    [ContractType.Other]: 'Other',
};
export const ContractHourType = {
    Regular: 'regular-hours',
    Overtime: 'overtime',
    Premium: 'overtime-premium',
    Holidays: 'holidays',
    AfterHours: 'after-hours',
    Saturdays: 'saturdays',
    Sundays: 'sundays',
    OneX: '1x',
    OneAndHalfX: '1.5x',
    OneAndThreeQuartersX: '1.75x',
    TwoX: '2x',
};
export const ContractHourTypeValues = Object.values(ContractHourType);
export const ContractHourTypeOptions = {
    [ContractHourType.Regular]: 'Regular Hours',
    [ContractHourType.Overtime]: 'Overtime',
    [ContractHourType.Premium]: 'Overtime Premium',
    [ContractHourType.Holidays]: 'Holidays',
    [ContractHourType.AfterHours]: 'After Hours',
    [ContractHourType.Saturdays]: 'Saturdays',
    [ContractHourType.Sundays]: 'Sundays',
    [ContractHourType.OneX]: '1x',
    [ContractHourType.OneAndHalfX]: '1.5x',
    [ContractHourType.OneAndThreeQuartersX]: '1.75x',
    [ContractHourType.TwoX]: '2x',
};
export const ContractWorkerType = {
    Mechanic: 'mechanic',
    Helper: 'helper',
    Crew: 'crew',
    Adjustor: 'adjustor',
    Team: 'team',
};
export const ContractWorkerTypeValues = Object.values(ContractWorkerType);
export const ContractWorkerTypeOptions = {
    [ContractWorkerType.Mechanic]: 'Mechanic',
    [ContractWorkerType.Helper]: 'Helper',
    [ContractWorkerType.Crew]: 'Crew',
    [ContractWorkerType.Adjustor]: 'Adjustor',
};
export const ContractWorkType = {
    Labor: 'labor',
    Travel: 'travel',
};
export const ContractWorkTypeValues = Object.values(ContractWorkType);
export const ContractWorkTypeOptions = {
    [ContractWorkType.Labor]: 'Labor',
    [ContractWorkType.Travel]: 'Travel',
};
export const contractHourlyRateSchema = z.object({
    id: z.string().optional(),
    hourType: z.enum(ContractHourTypeValues),
    workerType: z.enum(ContractWorkerTypeValues),
    workType: z.enum(ContractWorkTypeValues),
    hourRate: z.coerce.number().min(1, { message: 'Hour rate must be greater than 0' }),
    coveredByContract: z.boolean(),
});
export const contractHourlyRateFormSchema = z.object({
    hourlyRates: z.array(contractHourlyRateSchema),
});
export const contractReviewRulesSchema = z.object({
    id: z.string().optional(),
    reviewRule: z.string(),
    reviewRuleDescription: z.string(),
});
export const contractSchema = z.object({
    id: z.string(),
    name: z.string(),
    attachments: z.array(contractAttachmentSchema),
    serviceProvider: providerSchema,
    property: propertyDisplaySchema.nullable(),
    startDate: z.string(),
    expirationDate: z.string(),
    monthlyPrice: z.coerce.number(),
    term: z.string(),
    type: z.enum(ContractTypeValues),
    contractPaper: z.string(),
    businessHours: z.string(),
    phoneMonitoring: z.boolean().nullable(),
    fireService: z.boolean().nullable(),
    pressureTest: z.boolean().nullable(),
    partsAndMaterialsMarkup: z.coerce.number(),
    cancellation: z.coerce.number(),
    maintenanceCompletionTarget: z.coerce.number(),
    maintenanceHoursTarget: z.coerce.number(),
    uptimeTarget: z.coerce.number(),
    onTimeResponseTime: z.coerce.number(),
    emergencyCallRateTarget: z.coerce.number(),
    callbackResponseTime: z.coerce.number(),
    hourlyFeeAdjustmentRate: z.coerce.number(),
    notes: z.string(),
    cars: z.coerce.number(),
    metadata: metadataSchema.array().optional(),
    hourlyRates: z.array(contractHourlyRateSchema),
    reviewRules: z.array(contractReviewRulesSchema),
    canChat: z.boolean(),
});
export const contractFormValidator = (val, ctx) => {
    const started = val.startDate ? new Date(val.startDate) : null;
    const finished = val.expirationDate ? new Date(val.expirationDate) : null;
    if (started && finished && started > finished) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Expiration date must be after Effective since date',
            path: ['expirationDate'],
        });
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Effective since date must be before Expiration date',
            path: ['startDate'],
        });
    }
};
export const contractCustomDetailsSchema = z.array(z.record(z.string()));
export const contractFormBaseSchema = contractSchema
    .omit({
    id: true,
    name: true,
    attachments: true,
    serviceProvider: true,
    property: true,
    maintenanceCompletionTarget: true,
    uptimeTarget: true,
    onTimeResponseTime: true,
    emergencyCallRateTarget: true,
    callbackResponseTime: true,
    cars: true,
    metadata: true,
    phoneMonitoring: true,
    fireService: true,
    pressureTest: true,
    hourlyRates: true,
    reviewRules: true,
    canChat: true,
})
    .extend({
    phoneMonitoring: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    fireService: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    pressureTest: z.coerce
        .string()
        .transform((val) => {
        if (val === 'true')
            return true;
        if (val === 'false')
            return false;
        return val;
    })
        .nullable(),
    customDetails: z
        .array(z.object({ name: z.string(), value: z.string(), label: z.string() }))
        .optional(),
});
const contractFormPartial = contractFormBaseSchema.partial();
export const contractFormSchema = contractFormBaseSchema.superRefine(contractFormValidator);
export const contractFormSchemaPartial = contractFormPartial.superRefine(contractFormValidator);

import type { API_ChatRequest, API_ChatResponse } from '@liftai/asset-management-types';
import { ArrowForward, AutoAwesome } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import type { MutationFetcher } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import Feedback from '~/components/feedback/Feedback';
import { getApiClient } from '~/utils/api';

/**
 * Fetcher for the chat mutation
 * TODO: Eventually we'll make this generic for all entities
 *
 * @param key - The key to fetch
 * @param { arg: { query, entityId } } - The arguments to fetch
 * @returns The chat response
 */
const chatFetcher: MutationFetcher<API_ChatResponse, string, API_ChatRequest> = async (
  key,
  { arg: { query, entityId, metadata } },
) => {
  const apiClient = getApiClient();
  const response = await apiClient.chat.askQuestion({
    collection: 'contract',
    query,
    entityId,
    metadata,
  });
  return response;
};

export const useChat = () => {
  const { data, trigger, isMutating } = useSWRMutation('chat', chatFetcher);

  return { chatResponse: data, askQuestion: trigger, isMutating };
};

export type ChatProps = {
  entityId: string;
  entityType: 'contract';
  placeholder?: string;
  canChat: boolean;
  metadata?: Record<string, string>;
};

/**
 * Chat searchbar for contract details
 * *
 * @param entityId - The entity id to chat with
 * @param entityType - The entity type to chat with
 * @returns The chat searchbar component
 */
export const ChatSearchbar: FC<ChatProps> = ({
  canChat,
  entityId,
  entityType,
  placeholder = 'Ask a question',
  metadata,
}) => {
  const [query, setQuestion] = useState('');

  const { chatResponse, askQuestion, isMutating } = useChat();

  const handleAskQuestion = async () => {
    await askQuestion({ collection: entityType, query, entityId, metadata });
  };

  return (
    <Box
      mx={-2}
      my={-1}
      p={2}
      display="flex"
      flexDirection="column"
      bgcolor="primary.light"
      gap={1}
    >
      <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
        <AutoAwesome fontSize="small" color="primary" />
        <Typography variant="h6" color="primary" flexGrow={1}>
          Chat with your contract
        </Typography>
        <img
          style={{ height: 16 }}
          src="/images/liftai-intelligence-wordmark-grey.png"
          alt="LiftAI Intelligence"
        />
      </Box>
      <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between">
        <TextField
          disabled={!canChat}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              backgroundColor: 'white',
              borderRadius: 9.5,
            },
            '& .MuiInputBase-input': {
              paddingTop: 1,
              paddingBottom: 1,
            },
          }}
          placeholder={placeholder}
          value={query}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void handleAskQuestion();
            }
          }}
          slotProps={{
            input: {
              startAdornment: (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={1}
                  sx={{ opacity: !canChat ? 0.5 : 1 }}
                >
                  <img
                    width={26}
                    height={26}
                    src="/images/liftai-intelligence-logo.png"
                    alt="LiftAI Intelligence"
                  />
                </Box>
              ),
              endAdornment: isMutating ? (
                <IconButton disabled>
                  <CircularProgress size={20} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleAskQuestion}
                  disabled={isMutating || !canChat}
                  color="primary"
                >
                  <ArrowForward />
                </IconButton>
              ),
            },
          }}
        />
      </Box>
      {canChat && chatResponse ? (
        <>
          <Box bgcolor="#1976D214" borderRadius={2} px={2} py={1}>
            <Typography variant="body2" color="primary">
              {chatResponse.answer}
            </Typography>
          </Box>
          <Feedback feedbackKey="chat" runId={chatResponse.runId} />
        </>
      ) : null}
      {!canChat && (
        <Box bgcolor="error.light" borderRadius={2} px={2} py={1}>
          <Typography variant="body2" color="error">
            Chat is not available because the PDF does not contain readable text. <br />
            Please reach out to your account manager for assistance.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

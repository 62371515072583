import { zodResolver } from '@hookform/resolvers/zod';
import type { API_InspectionReport, PropertyForm } from '@liftai/asset-management-types';
import { Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import useSWR from 'swr';
import { z } from 'zod';

import {
  associateFileToEntity,
  getFilesAssociatedToEntity,
  updateAssociationFileToEntity,
} from '~/components/utils/upload';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';
import type {
  LAIUseFormReturn,
  LiftAIDocumentTagged,
  LiftAIFormProps,
} from './SelectDocumentTypeDialog';

const violationSchema = z.object({
  code: z.string().min(1, { message: 'Violation code is required' }),
  location: z.string().min(1, { message: 'Location is required' }),
  correction_due: z.string().min(1, { message: 'Correction due date is required' }),
  date_open: z.string().min(1, { message: 'Open date is required' }),
  responsibility: z.enum(['owner', 'service_provider', 'county']),
  description: z.string().min(1, { message: 'Description is required' }),
});

export const inspectionReportFormSchema = z
  .object({
    property_id: z.string().min(1, { message: 'Property is required' }),
    car_id: z.string().min(1, { message: 'Car is required' }),
    inspection_date: z.string().min(1, { message: 'Inspection date is required' }),
    inspection_type: z.enum(['compliance', 'periodic', 'other']),
    custom_type: z.string().optional(),
    status: z.enum(['passed', 'failed', 'shutdown']),
    inspector_name: z.string().min(1, { message: 'Inspector name is required' }),
    comments: z.string(),
    violations: z.array(violationSchema),
  })
  .superRefine((data, ctx) => {
    if (data.inspection_type === 'other' && !data.custom_type) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Custom type is required when Others is selected',
        path: ['custom_type'],
      });
    }
  });

export type InspectionReportFormData = z.infer<typeof inspectionReportFormSchema>;

const inspectionOptionsFetcher = async (): Promise<{ properties: PropertyForm[] }> => {
  const apiClient = getApiClient();
  const properties = await apiClient.properties.getAll({ variant: 'form' });
  return { properties };
};

export const InspectionReportFields = () => {
  const { control, watch } = useFormContext<InspectionReportFormData>();
  const {
    fields: violations,
    append: appendViolation,
    remove: removeViolation,
  } = useFieldArray({
    control,
    name: 'violations',
  });

  const propertyId = watch('property_id');
  const { data } = useSWR('InspectionReportForm.Options', inspectionOptionsFetcher);
  const { cars } = usePropertyCars(propertyId);
  const inspectionType = watch('inspection_type');

  const formFields: FieldDef[] = useMemo(
    () => [
      {
        name: 'property_id',
        label: 'Property',
        type: 'autocomplete',
        options:
          data?.properties?.map((property) => ({
            id: property.id,
            label: property.name,
          })) ?? [],
        loading: !data?.properties,
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'car_id',
        label: 'Car Inspected',
        type: 'autocomplete',
        options:
          cars?.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          })) ?? [],
        loading: !cars,
        required: true,
        disabled: !propertyId,
        gridProps: { xs: 12 },
      },
      {
        name: 'inspection_date',
        label: 'Date of Inspection',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'inspection_type',
        label: 'Type',
        type: 'select',
        options: [
          { id: 'periodic', label: 'Periodic Inspection' },
          { id: 'compliance', label: 'Compliance Inspection' },
          { id: 'other', label: 'Other' },
        ],
        required: true,
        gridProps: { xs: 6 },
      },
      ...(inspectionType === 'other'
        ? [
            {
              name: 'custom_type',
              label: 'Custom Type',
              type: 'text',
              required: true,
              gridProps: { xs: 6 },
            } as FieldDef,
          ]
        : []),
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { id: 'passed', label: 'Passed' },
          { id: 'failed', label: 'Failed' },
          { id: 'shutdown', label: 'Shutdown' },
        ],
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'inspector_name',
        label: 'Inspector Name',
        type: 'text',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'text',
        multiline: true,
        required: false,
        gridProps: { xs: 12 },
      },
    ],
    [data?.properties, cars, propertyId, inspectionType],
  );

  return (
    <>
      <Typography variant="h6" my={2}>
        Inspection Details
      </Typography>
      <Grid container spacing={2}>
        {formFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>

      <Typography variant="h6" mt={4} mb={2}>
        Violations
      </Typography>

      {violations.map((violation, index) => (
        <Box
          key={violation.id}
          sx={{
            mb: 3,
            p: 3,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            backgroundColor: 'background.paper',
          }}
        >
          <Grid container spacing={3}>
            <Grid
              size={{ xs: 12 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1" fontWeight="medium">
                Violation #{index + 1}
              </Typography>
              <IconButton
                onClick={() => removeViolation(index)}
                color="error"
                size="small"
                aria-label="Remove violation"
              >
                <Delete />
              </IconButton>
            </Grid>

            <Grid size={{ xs: 6 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.code`,
                  label: 'Code',
                  type: 'text',
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>

            <Grid size={{ xs: 6 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.location`,
                  label: 'Location',
                  type: 'text',
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>

            <Grid size={{ xs: 6 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.date_open`,
                  label: 'Date Open',
                  type: 'date',
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>

            <Grid size={{ xs: 6 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.correction_due`,
                  label: 'Correction Due',
                  type: 'date',
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.responsibility`,
                  label: 'Responsibility',
                  type: 'select',
                  options: [
                    { id: 'owner', label: 'Owner' },
                    { id: 'service_provider', label: 'Service Provider' },
                    { id: 'county', label: 'County' },
                  ],
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <FieldAndCell
                field={{
                  name: `violations.${index}.description`,
                  label: 'Description',
                  type: 'text',
                  required: true,
                  gridProps: { xs: 12 },
                }}
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
      ))}

      <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          appendViolation({
            code: '',
            location: '',
            correction_due: '',
            date_open: '',
            responsibility: 'owner',
            description: '',
          })
        }
      >
        + ADD VIOLATION
      </Button>
    </>
  );
};

export const InspectionReportForm = ({
  children,
  document,
  onSubmit,
  initialData,
}: LiftAIFormProps<InspectionReportFormData>) => {
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, reset } = useFormContext<InspectionReportFormData>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset(initialData);
  }, [initialData, reset]);

  const updateReferenceToFiles = async (
    report: API_InspectionReport,
    document: LiftAIDocumentTagged,
  ) => {
    console.log('Starting updateReferenceToFiles for inspection report:', {
      report,
      document,
    });

    if (report.id) {
      console.log('Fetching existing attachments for report:', report.id);
      const attachments = await getFilesAssociatedToEntity(report.id, 'inspection_report');
      console.log('Current report attachments:', attachments);

      await Promise.all(
        attachments
          .filter((attachment) => attachment.isPrimary)
          .map((attachment) => {
            console.log('Voiding primary attachment:', attachment);
            return updateAssociationFileToEntity(
              attachment.attachment,
              report.id,
              'inspection_report',
              {
                id: attachment.id,
                isVoid: true,
              },
            );
          }),
      );
    }

    console.log('Creating new attachment for report:', {
      attachmentId: document.attachment.id,
      reportId: report.id,
    });

    await associateFileToEntity(
      document.attachment.id,
      report.id,
      'inspection_report',
      document.fileType === 'pdf'
        ? {
            pageNumber: document.pageNum,
            isPrimary: true,
          }
        : {},
    );
  };

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
      onSubmit={handleSubmit(async (data) => {
        try {
          const apiClient = getApiClient();

          // First create violations
          const violationPromises = data.violations.map((violation) =>
            apiClient.violations.create({
              car_id: data.car_id,
              ...violation,
            }),
          );
          // Wait for all violations to be created
          const createdViolations = await Promise.all(violationPromises);

          // Create the inspection report
          const report = await apiClient.inspectionReports.create({
            car_id: data.car_id,
            inspection_date: data.inspection_date,
            inspection_type: data.inspection_type,
            custom_type: data.custom_type ?? null,
            status: data.status,
            inspector_name: data.inspector_name,
            comments: data.comments,
            violation_ids: createdViolations.map((v) => v.id),
            attachment: document?.attachment.id ?? null,
          });

          // Handle document attachment
          if (document) {
            await updateReferenceToFiles(report, document);
          }

          // If the creation was successful, call onSubmit with the combined data
          onSubmit({
            ...report,
            violations: createdViolations,
          });

          showEntityActionSnackbar(
            {
              name: 'Inspection Report',
              action: ActionEnum.Create,
              id: report.id,
            },
            {
              variant: 'success',
            },
          );
        } catch (err) {
          console.error(err);
          showSnackbar('Failed to create inspection report', {
            variant: 'error',
          });
        }
      })}
    >
      {children}
    </form>
  );
};

export const useInspectionReportForm = (): LAIUseFormReturn<InspectionReportFormData> => {
  const hookForm = useForm<InspectionReportFormData>({
    mode: 'all',
    resolver: zodResolver(inspectionReportFormSchema),
    defaultValues: {
      property_id: '',
      car_id: '',
      inspection_date: '',
      inspection_type: 'periodic',
      custom_type: '',
      status: 'passed',
      inspector_name: '',
      comments: '',
      violations: [],
    },
  });

  return {
    hookForm,
    Form: InspectionReportForm,
    Fields: InspectionReportFields,
  };
};

import { Box } from '@mui/material';

import { TicketTable } from '~/components/tickets/TicketTable';
import { useTickets, useTicketsTableModel } from '~/data/hooks/useTickets';

type PropertyTicketsProps = {
  propertyId: string;
};

export function PropertyTickets({ propertyId }: PropertyTicketsProps) {
  const {
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
  } = useTicketsTableModel();

  const { tickets, numTickets, isLoading, onExport, onFilterModelChange } = useTickets({
    propertyId,
    paginationModel,
    filterModel,
    sortModel,
    onFilterModelChange: setFilterModel,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <TicketTable
        tickets={tickets}
        numTickets={numTickets}
        isLoading={isLoading}
        onExport={onExport}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={onFilterModelChange}
        paginationModel={paginationModel}
        filterModel={filterModel}
        sortModel={sortModel}
        hiddenColumns={['property', 'portfolio']}
        enableQuickFilter
        canFilterProperty={false}
      />
    </Box>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import type {
  ApproveInvoiceForm,
  Invoice,
  PartialApproveInvoiceForm,
  RejectInvoiceForm,
  ResetInvoiceForm,
} from '@liftai/asset-management-types';
import {
  approveInvoiceFormSchema,
  InvoiceActionType,
  InvoiceKind,
  invoiceKindToLabelMap,
  partialApproveInvoiceFormSchema,
  rejectInvoiceFormSchema,
  resetInvoiceFormSchema,
} from '@liftai/asset-management-types';
import { Dialog, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import DialogBackNextActions from '~/components/DialogBackNextActions';
import DialogTitleWithCloseButton from '~/components/DialogTitleWithCloseButton';
import type { FieldDef } from '~/components/form/utils';
import { FieldAndCell } from '~/components/form/utils';

interface IBaseProps {
  invoice: Invoice;
  isSaving: boolean;
  onClose: () => void;
}

interface IPartialApproveDialogProps extends IBaseProps {
  onSubmit: (invoice: PartialApproveInvoiceForm) => void;
}

interface IRejectDialogProps extends IBaseProps {
  onSubmit: (invoice: RejectInvoiceForm) => void;
}

interface IApproveDialogProps extends IBaseProps {
  onSubmit: (invoice: ApproveInvoiceForm) => void;
}

interface IResetDialogProps extends IBaseProps {
  onSubmit: (invoice: { reason: string }) => void;
}

const buildFormFields = (actionType: InvoiceActionType, invoice: Invoice) => {
  if (actionType === InvoiceActionType.RESET) {
    return {
      formFields: [
        {
          name: 'reason',
          label: 'Reason',
          type: 'text-multiline',
          gridProps: {
            xs: 12,
          },
        } as FieldDef,
      ],
    };
  }

  const formFields: FieldDef[] = [
    {
      name: 'proposedAmount',
      label: invoice.kind === InvoiceKind.Proposal ? 'Proposal Amount' : 'Invoice Amount',
      type: 'currency',
      fieldProps: {
        disabled: true,
        defaultValue: invoice.proposedAmount,
      },
      gridProps: {
        xs: 4,
      },
    },
    ...(actionType === InvoiceActionType.PARTIAL
      ? [
          {
            name: 'reviewedAmount',
            label: 'Amount After Review',
            type: 'currency',
            gridProps: {
              xs: 4,
            },
          } as FieldDef,
        ]
      : []),
    {
      name: 'savings',
      label: 'Savings',
      type: 'currency',
      fieldProps: {
        disabled: false,
        defaultValue: invoice.savings,
      },
      gridProps: {
        xs: 4,
      },
    },
    {
      name: 'reason',
      label: 'Reason',
      type: 'text-multiline',
      gridProps: {
        xs: 12,
      },
    },
    {
      name: 'addStampedReason',
      label: `Add reason to stamped ${invoiceKindToLabelMap.get(invoice.kind)}`,
      type: 'checkbox',
      gridProps: {
        xs: 12,
      },
      fieldProps: {
        sx: {
          pt: 0,
        },
      },
    },
    ...(actionType === InvoiceActionType.REJECT
      ? [
          {
            name: 'countTowardsSavings',
            label: "Count Towards Client's Savings",
            type: 'checkbox',
            gridProps: {
              xs: 12,
            },
            fieldProps: {
              sx: {
                pt: 0,
              },
            },
          } as FieldDef,
        ]
      : []),
  ];

  return {
    formFields,
  };
};

export const PartialApproveDialogContent: React.FC<IPartialApproveDialogProps> = ({
  invoice,
  onClose,
  onSubmit,
  isSaving,
}) => {
  const titleWithInvoice = `Partially Approve ${invoiceKindToLabelMap.get(invoice.kind)} #${
    invoice.number
  } - ${invoice.property.name}`;

  const { formFields } = buildFormFields(InvoiceActionType.PARTIAL, invoice);

  const { control, handleSubmit, watch, setValue, trigger } = useForm<PartialApproveInvoiceForm>({
    mode: 'all',
    defaultValues: {
      reviewedAmount: parseFloat(
        parseFloat((invoice.reviewedAmount as unknown as string) ?? '0').toFixed(2),
      ),
      reason: '',
      /**
       * @todo: This is a temporary fix to handle the case where the proposed amount is a string.
       * This is a known issue to be fixed in the backend.
       */
      proposedAmount: parseFloat(
        parseFloat((invoice.proposedAmount as unknown as string) ?? '0').toFixed(2),
      ),
      addStampedReason: false,
      savings: invoice.savings ?? 0,
    },
    resolver: zodResolver(partialApproveInvoiceFormSchema),
  });

  useEffect(() => {
    const subscription = watch(({ savings, reviewedAmount, proposedAmount }, { name }) => {
      if (
        proposedAmount &&
        reviewedAmount &&
        name?.match(/reviewedAmount/) &&
        savings !== reviewedAmount
      ) {
        setValue('savings', parseFloat((proposedAmount - reviewedAmount).toFixed(2)));
        void trigger('savings');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, trigger]);

  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <form
        noValidate
        onSubmit={handleSubmit((data: PartialApproveInvoiceForm, e) => {
          e?.preventDefault();
          onSubmit(data);
        })}
      >
        <DialogTitleWithCloseButton title={titleWithInvoice} onClose={onClose} />
        <DialogContent sx={{ overflowY: 'visible' }}>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <FieldAndCell key={field.name} field={field} control={control} />
            ))}
          </Grid>
        </DialogContent>
        <DialogBackNextActions
          onBack={() => {}}
          actionBtns={{
            next: { label: 'Next', enabled: !isSaving },
          }}
        />
      </form>
    </Dialog>
  );
};

export const ApproveDialogContent: React.FC<IApproveDialogProps> = ({
  invoice,
  onClose,
  onSubmit,
  isSaving,
}) => {
  const titleWithInvoice = `Approve ${invoiceKindToLabelMap.get(invoice.kind)} #${
    invoice.number
  } - ${invoice.property.name}`;

  const { formFields } = buildFormFields(InvoiceActionType.APPROVE, invoice);

  const { control, handleSubmit } = useForm<ApproveInvoiceForm>({
    mode: 'all',
    defaultValues: {
      reason: '',
      addStampedReason: false,
      proposedAmount: invoice.proposedAmount,
      savings: invoice.savings ?? 0,
    },
    resolver: zodResolver(approveInvoiceFormSchema),
  });
  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <form
        noValidate
        onSubmit={handleSubmit((data: ApproveInvoiceForm, e) => {
          e?.preventDefault();
          onSubmit(data);
        })}
      >
        <DialogTitleWithCloseButton title={titleWithInvoice} onClose={onClose} />
        <DialogContent sx={{ overflowY: 'visible' }}>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <FieldAndCell key={field.name} field={field} control={control} />
            ))}
          </Grid>
        </DialogContent>
        <DialogBackNextActions
          onBack={() => {}}
          actionBtns={{
            next: { label: 'Next', enabled: !isSaving },
          }}
        />
      </form>
    </Dialog>
  );
};

export const RejectDialogContent: React.FC<IRejectDialogProps> = ({
  invoice,
  onClose,
  onSubmit,
  isSaving,
}) => {
  const titleWithInvoice = `Reject ${invoiceKindToLabelMap.get(invoice.kind)} #${
    invoice.number
  } - ${invoice.property.name}`;

  const { formFields } = buildFormFields(InvoiceActionType.REJECT, invoice);

  const { control, handleSubmit } = useForm<RejectInvoiceForm>({
    mode: 'all',
    defaultValues: {
      reason: '',
      proposedAmount: parseFloat(invoice.proposedAmount as unknown as string),
      addStampedReason: false,
      countTowardsSavings: false,
      savings: parseFloat(invoice.proposedAmount as unknown as string),
    },
    resolver: zodResolver(rejectInvoiceFormSchema),
  });
  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <form
        noValidate
        onSubmit={handleSubmit((data: RejectInvoiceForm, e) => {
          e?.preventDefault();
          onSubmit(data);
        })}
      >
        <DialogTitleWithCloseButton title={titleWithInvoice} onClose={onClose} />
        <DialogContent sx={{ overflowY: 'visible' }}>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <FieldAndCell key={field.name} field={field} control={control} />
            ))}
          </Grid>
        </DialogContent>
        <DialogBackNextActions
          onBack={() => {}}
          actionBtns={{
            next: { label: 'Next', enabled: !isSaving },
          }}
        />
      </form>
    </Dialog>
  );
};

export const ResetDialogContent: React.FC<IResetDialogProps> = ({
  invoice,
  onClose,
  onSubmit,
  isSaving,
}) => {
  const titleWithInvoice = `Undo Stamp on ${invoiceKindToLabelMap.get(invoice.kind)} #${
    invoice.number
  } - ${invoice.property.name}`;

  const { formFields } = buildFormFields(InvoiceActionType.RESET, invoice);

  const { control, handleSubmit } = useForm<ResetInvoiceForm>({
    mode: 'all',
    defaultValues: {
      reason: '',
    },
    resolver: zodResolver(resetInvoiceFormSchema),
  });
  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <form
        noValidate
        onSubmit={handleSubmit((data: ResetInvoiceForm, e) => {
          e?.preventDefault();
          onSubmit(data);
        })}
      >
        <DialogTitleWithCloseButton title={titleWithInvoice} onClose={onClose} />
        <DialogContent sx={{ overflowY: 'visible' }}>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <FieldAndCell key={field.name} field={field} control={control} />
            ))}
          </Grid>
        </DialogContent>
        <DialogBackNextActions
          onBack={() => {}}
          actionBtns={{
            next: { label: 'Save', enabled: !isSaving },
          }}
        />
      </form>
    </Dialog>
  );
};

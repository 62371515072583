import { UserRole } from '@liftai/asset-management-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HandymanIcon from '@mui/icons-material/Handyman';
import Logout from '@mui/icons-material/Logout';
import type { SvgIconProps } from '@mui/material';
import { ButtonBase, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { ComponentType, useContext } from 'react';

import { UserContext } from '~/auth/userContext';
import { AppBar } from '~/components/global';
import AppBarButton from '~/components/global/AppBarButton';
import { ContractIcon, InvoiceIcon, PortfolioIcon, TicketIcon } from '~/components/icons';
import { useLAINavigate } from '~/hooks/useLAINavigate';

const drawerWidth = 81;

interface SideBarLayoutProps {
  routeMatches: (path: string) => boolean;
  children: React.ReactNode;
  numPendingInvoices?: number;
  openSearch: () => void;
}

const DEFAULT_ROUTE = '/' as const;
export const PORTFOLIO_ROUTE = '/portfolio' as const;
export const TICKETS_ROUTE = '/tickets' as const;
export const SPENDING_ROUTE = '/spending' as const;
export const COMPLIANCE_ROUTE = '/compliance' as const;
export const TOOLS_ROUTE = '/tools' as const;
export const INSIGHTS_ROUTE = '/insights' as const;

type VALID_ROUTE =
  | typeof DEFAULT_ROUTE
  | typeof PORTFOLIO_ROUTE
  | typeof TICKETS_ROUTE
  | typeof SPENDING_ROUTE
  | typeof COMPLIANCE_ROUTE
  | typeof TOOLS_ROUTE
  | typeof INSIGHTS_ROUTE;

const isValidRoute = (route: string): route is VALID_ROUTE => {
  return (
    route === DEFAULT_ROUTE ||
    route === PORTFOLIO_ROUTE ||
    route === TICKETS_ROUTE ||
    route === SPENDING_ROUTE ||
    route === COMPLIANCE_ROUTE ||
    route === TOOLS_ROUTE ||
    route === INSIGHTS_ROUTE
  );
};

const ALLOWED_ROUTES: Record<NonNullable<UserRole>, VALID_ROUTE[]> = {
  client: [PORTFOLIO_ROUTE, TICKETS_ROUTE],
  consultant: [PORTFOLIO_ROUTE, TICKETS_ROUTE, SPENDING_ROUTE, COMPLIANCE_ROUTE, INSIGHTS_ROUTE],
  service_provider: [DEFAULT_ROUTE],
};

export type MenuItem = {
  label: string;
  icon: ComponentType<SvgIconProps>;
  route: string;
};

const mainMenu: MenuItem[] = [
  { label: 'Insights', icon: AutoAwesomeIcon, route: INSIGHTS_ROUTE },
  { label: 'Portfolio', icon: PortfolioIcon, route: PORTFOLIO_ROUTE },
  { label: 'Tickets', icon: TicketIcon, route: TICKETS_ROUTE },
  { label: 'Spending', icon: InvoiceIcon, route: SPENDING_ROUTE },
  { label: 'Compliance', icon: ContractIcon, route: COMPLIANCE_ROUTE },
  { label: 'Tools', icon: HandymanIcon, route: TOOLS_ROUTE },
];

function SideBarLayout({
  children,
  routeMatches,
  numPendingInvoices,
  openSearch,
}: SideBarLayoutProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { navigate } = useLAINavigate();

  const {
    user: { uid },
    userDetails: { role: userRole, isLiftaiAdmin },
  } = useContext(UserContext);

  const featureFlags = useFlags();

  function menuGuardPredicate(menuItem: MenuItem): boolean {
    if (!userRole || !isValidRoute(menuItem.route)) {
      return false;
    }

    // Special handling for Insights route
    if (menuItem.route === INSIGHTS_ROUTE) {
      return (
        ALLOWED_ROUTES[userRole].includes(menuItem.route) && featureFlags.liftAiInsights === true
      );
    }

    return (
      ALLOWED_ROUTES[userRole].includes(menuItem.route) ||
      (menuItem.route === TOOLS_ROUTE && isLiftaiAdmin)
    );
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const isLoggedIn = uid !== null;

  const getParamsToKeep = (route: string) => {
    const commonParams = ['portfolio', 'service_provider', 'region', 'selectedInvoiceId'];

    switch (route) {
      case COMPLIANCE_ROUTE:
        return commonParams;
      default:
        return ['start_date', 'end_date', ...commonParams];
    }
  };

  const drawer = (
    <Grid container justifyContent="center" flexGrow={1} alignContent="space-between">
      <Grid container justifyContent="center">
        <Box py={2}>
          <ButtonBase onClick={() => navigate('/')}>
            <img src="/images/lift-logo-small.svg" alt="Lift AI" style={{ height: '36px' }} />
          </ButtonBase>
        </Box>

        {mainMenu.filter(menuGuardPredicate).map(({ icon: Icon, ...props }) => (
          <Box key={props.label} py={1}>
            <AppBarButton
              isSidebarButton
              key={props.label}
              icon={<Icon />}
              active={routeMatches(props.route)}
              onClick={(e) => {
                e.preventDefault();
                navigate(props.route, {
                  paramsToKeep: getParamsToKeep(props.route),
                });
              }}
              {...props}
            />
          </Box>
        ))}
      </Grid>
      {/**  LogoutButton */}
      <Box py={1}>
        {isLoggedIn ? (
          <IconButton aria-label="logout" onClick={handleLogout}>
            <Logout />
          </IconButton>
        ) : null}
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', maxHeight: '100%' }}>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <AppBar openSearch={openSearch} numPendingInvoices={numPendingInvoices} />
      </Box>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default SideBarLayout;

import { CarInProperty } from '@liftai/asset-management-types';
import { useCallback } from 'react';
import useSWR, { type Fetcher } from 'swr';

import { dateToDateString } from '~/components/utils/dateUtils';
import { useGlobalFilterContext } from '~/contexts/globalFilterContext';
import { getApiClient } from '~/utils/api';

const carsFetcher: Fetcher<
  CarInProperty[],
  readonly ['property', propertyId: string, 'cars', { startDate: Date; endDate: Date }]
> = async ([_, propertyId, , { startDate, endDate }]) => {
  const apiClient = getApiClient();
  const cars = await apiClient.cars.getByPropertyId({
    propertyId,
    startDate: dateToDateString(startDate),
    endDate: dateToDateString(endDate),
  });
  return cars satisfies CarInProperty[];
};

export const usePropertyCars = (propertyId: string | null) => {
  const {
    selectedFilters: {
      date: { start, end },
    },
  } = useGlobalFilterContext();
  const {
    data: cars,
    isLoading: loading,
    mutate,
  } = useSWR(
    propertyId
      ? (['property', propertyId, 'cars', { startDate: start, endDate: end }] as const)
      : null,
    carsFetcher,
  );
  const refresh = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { cars, loading, refresh };
};

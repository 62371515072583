import type { API_CertificateCreateRequest } from '@liftai/asset-management-types';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { useCertificateForm } from '~/components/uploadDocument/certificateForm';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';

interface IAddCertificateDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: API_CertificateCreateRequest) => void;
  isEdit?: boolean;
  defaultValues?: API_CertificateCreateRequest;
  onSuccess?: () => void;
}

const AddCertificateDialog = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  defaultValues,
  onSuccess,
}: IAddCertificateDialogProps) => {
  const theme = useTheme();
  const { hookForm, Form: CertificateForm, Fields: CertificateFields } = useCertificateForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  const handleSuccess = (data: API_CertificateCreateRequest) => {
    onSubmit(data);
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <FormProvider {...hookForm}>
      <CertificateForm onSubmit={handleSuccess} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton
            onClose={onClose}
            title={`${isEdit ? 'Edit' : 'Add'} Certificate`}
          />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'auto', height: '100%' }}
          >
            <CertificateFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </CertificateForm>
    </FormProvider>
  );
};

export default AddCertificateDialog;

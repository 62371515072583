import { zodResolver } from '@hookform/resolvers/zod';
import {
  Contract,
  contractFormSchema,
  ContractSummaryDetails,
  ContractTypeOptions,
} from '@liftai/asset-management-types';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, TextField, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import { FieldAndCell, FieldDef } from '~/components/form/utils';
import { METADATA_CUSTOM_DETAILS_KEY } from '~/data/hooks/usePropertyContracts';

export type ContractSummaryDetailsWithoutHourlyRatesAndReviewRules = Omit<
  ContractSummaryDetails,
  'hourlyRates' | 'reviewRules'
>;

export type ContractFormProps<T = unknown, TSubmit = unknown> = {
  children: React.ReactNode;
  initialData?: T;
  onSubmit(data: TSubmit): void;
};

export type LAIUseFormReturn<T extends FieldValues, TSubmit = unknown> = {
  hookForm: UseFormReturn<T, TSubmit>;
  Form: React.FC<ContractFormProps<T>>;
  Fields: React.FC<{ onDelete?: (id: string) => Promise<void> }>;
};

const defaultContractFormValues: ContractSummaryDetailsWithoutHourlyRatesAndReviewRules = {
  type: '',
  startDate: '',
  expirationDate: '',
  monthlyPrice: 0,
  term: '',
  contractPaper: '',
  businessHours: '',
  phoneMonitoring: null,
  fireService: null,
  pressureTest: null,
  partsAndMaterialsMarkup: 0,
  cancellation: 0,
  maintenanceHoursTarget: 0,
  hourlyFeeAdjustmentRate: 0,
  notes: '',
};

export const mapContractToFormValues = (
  contract: Contract,
): ContractSummaryDetailsWithoutHourlyRatesAndReviewRules => {
  const customDetailsValue = contract.metadata?.find(
    (m) => m.key === METADATA_CUSTOM_DETAILS_KEY,
  )?.value;
  const customDetails = Object.entries(
    JSON.parse(customDetailsValue ?? '{}') as { [key: string]: string },
  ).map(([name, value]) => ({
    name: name.replace(/_([a-z])/g, function (_, letter) {
      return ' ' + letter.toUpperCase();
    }),
    value,
    label: name,
  }));

  return {
    type: contract.type,
    startDate: contract.startDate,
    expirationDate: contract.expirationDate,
    monthlyPrice: contract.monthlyPrice,
    term: contract.term,
    contractPaper: contract.contractPaper,
    businessHours: contract.businessHours,
    phoneMonitoring: contract.phoneMonitoring,
    fireService: contract.fireService,
    pressureTest: contract.pressureTest,
    partsAndMaterialsMarkup: contract.partsAndMaterialsMarkup,
    cancellation: contract.cancellation,
    maintenanceHoursTarget: contract.maintenanceHoursTarget,
    hourlyFeeAdjustmentRate: contract.hourlyFeeAdjustmentRate,
    customDetails,
    notes: contract.notes ?? '',
  };
};

export const useContractDetailsForm =
  (): LAIUseFormReturn<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules> => {
    const hookForm = useForm<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules>({
      mode: 'all',
      defaultValues: defaultContractFormValues,
      resolver: zodResolver(contractFormSchema),
    });

    return {
      hookForm,
      Form: ContractDetailsForm,
      Fields: ContractDetailsFields,
    };
  };

const ContractDetailsForm = ({
  children,
  onSubmit,
  initialData,
}: ContractFormProps<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules>) => {
  const { handleSubmit, reset } =
    useFormContext<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset({ ...initialData });
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(async (data) => {
        const contractData = contractFormSchema.parse(data);

        onSubmit(contractData);
      })}
    >
      {children}
    </form>
  );
};

const ContractDetailsFields: FC = () => {
  const contractPresetFields: FieldDef[] = [
    {
      name: 'startDate',
      label: 'Effective Since',
      type: 'date',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'expirationDate',
      label: 'Expiration',
      type: 'date',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    { name: 'term', label: 'Term', type: 'text', gridProps: { xs: 12, sm: 6, md: 4 } },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: Object.entries(ContractTypeOptions).map(([value, label]) => ({ id: value, label })),
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'cancellation',
      label: 'Cancellation',
      type: 'number',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'monthlyPrice',
      label: 'Monthly Price',
      type: 'number',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'hourlyFeeAdjustmentRate',
      label: 'Adjustment Rate (yearly)',
      type: 'number',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'contractPaper',
      label: 'Contract Paper',
      type: 'text',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'businessHours',
      label: 'Regular Business Hours',
      type: 'text',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'partsAndMaterialsMarkup',
      label: 'Parts and Materials Markup',
      type: 'number',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'phoneMonitoring',
      label: 'Phone Monitoring',
      type: 'select',
      options: [
        { id: 'true', label: 'Yes' },
        { id: 'false', label: 'No' },
      ],
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'fireService',
      label: 'Fire Service',
      type: 'select',
      options: [
        { id: 'true', label: 'Yes' },
        { id: 'false', label: 'No' },
      ],
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'pressureTest',
      label: 'Pressure Test',
      type: 'select',
      options: [
        { id: 'true', label: 'Yes' },
        { id: 'false', label: 'No' },
      ],
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'maintenanceHoursTarget',
      label: 'Maint. Hours per/Month',
      type: 'number',
      gridProps: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'text-multiline',
      required: false,
      gridProps: { xs: 12 },
    },
  ];
  const { control } = useFormContext<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules>();
  const { fields, remove, append } =
    useFieldArray<ContractSummaryDetailsWithoutHourlyRatesAndReviewRules>({
      control,
      name: 'customDetails',
    });
  const [customLabel, setCustomLabel] = useState('');

  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} my={2}>
        {contractPresetFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
        {fields.map((field, index) => (
          <Grid
            container
            key={field.id}
            {...{ xs: 12, sm: 6, md: 4, sx: { display: 'flex', justifyContent: 'space-between' } }}
          >
            <Controller
              key={field.name}
              name={`customDetails.${index}.value`}
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
                return (
                  <TextField
                    name={field.name}
                    type="text"
                    value={value ?? ''}
                    onBlur={onBlur}
                    onChange={onChange}
                    label={field.label}
                    sx={{ width: 'calc(100% - 30px)' }}
                    size="small"
                    inputProps={{ role: 'textbox' }}
                    error={typeof error !== 'undefined'}
                    helperText={error?.message}
                  />
                );
              }}
            />
            <IconButton sx={{ padding: 0, alignSelf: 'center' }} onClick={() => remove(index)}>
              <Delete />
            </IconButton>
          </Grid>
        ))}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          width: '295px',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.light,
          padding: '16px',
          gap: '8px',
          borderRadius: '8px',
        }}
      >
        <Grid>
          <Typography
            variant="h6"
            sx={{ fontSize: '14px', lineHeight: '157%', letterSpacing: '0.1px' }}
          >
            Add your own Summary Item
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignSelf: 'stretch',
          }}
        >
          <TextField
            name="addCustomItem"
            type="text"
            label="Item Label"
            size="small"
            sx={{ width: '170px', backgroundColor: '#fff', borderRadius: '4px' }}
            inputProps={{ role: 'textbox' }}
            value={customLabel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCustomLabel(event.target.value);
            }}
          />
          <Button
            type="button"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              if (!customLabel) {
                return;
              }

              append({
                name: customLabel,
                label: customLabel,
                value: '',
              });
              setCustomLabel('');
            }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import {
  InspectionReportFormData,
  useInspectionReportForm,
} from '~/components/uploadDocument/inspectionReportForm';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';

interface IAddInspectionReportDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: InspectionReportFormData) => void;
  isEdit?: boolean;
  defaultValues?: InspectionReportFormData;
}

const AddInspectionReportDialog = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  defaultValues,
}: IAddInspectionReportDialogProps) => {
  const theme = useTheme();
  const {
    hookForm,
    Form: InspectionReportForm,
    Fields: InspectionReportFields,
  } = useInspectionReportForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <FormProvider {...hookForm}>
      <InspectionReportForm onSubmit={onSubmit} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton
            onClose={onClose}
            title={`${isEdit ? 'Edit' : 'Add'} Inspection Report`}
          />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'auto', height: '100%' }}
          >
            <InspectionReportFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </InspectionReportForm>
    </FormProvider>
  );
};

export default AddInspectionReportDialog;

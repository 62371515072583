import { zodResolver } from '@hookform/resolvers/zod';
import type {
  API_CertificateFilterResponse,
  API_InspectionReportFilterResponse,
  API_InvoiceFilterResponse,
  API_TicketFilterResponse,
  Ticket,
} from '@liftai/asset-management-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useForm, useFormContext } from 'react-hook-form';
import useSWR, { type Fetcher } from 'swr';
import { z } from 'zod';

import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';
import type {
  LAIUseFormReturn,
  LiftAIFormProps,
} from '~/components/uploadDocument/SelectDocumentTypeDialog';
import { isLiftAIDocumentPDFUntagged } from '~/components/uploadDocument/UploadDocumentDialogFlow';
import { associateFileToEntity } from '~/components/utils/upload';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';

export type SupportingDataDto = {
  supportingDataMovingForward?: boolean;
  remainingPages?: number;
  invoiceKind?: string;
  documentType: 'certificate' | 'invoice' | 'ticket';
  isContinued?: boolean;
  subsequentPagesAsContinued?: boolean;
} & (
  | { certificateId: string }
  | { invoiceId: string }
  | { ticketId?: string | null }
  | { inspectionReportId: string }
);

export type SupportingDataOnSubmitData = {
  number: string;
  propertyName: string;
};

const addSupportingDataSchema = z
  .object({
    invoiceId: z.string().optional(),
    ticketId: z.string().nullable(),
    certificateId: z.string().nullable(),
    inspectionReportId: z.string().nullable(),
    isContinued: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.isContinued) {
        return true;
      }
      return (
        !!data.invoiceId || !!data.ticketId || !!data.certificateId || !!data.inspectionReportId
      );
    },
    {
      message: 'At least one ID must be provided',
    },
  );

const getDefaultValues = (): DefaultValues<SupportingDataDto> => {
  return {
    invoiceId: '',
    ticketId: null,
    certificateId: '',
    inspectionReportId: '',
  };
};

export const useSupportingDataForm = (): LAIUseFormReturn<
  SupportingDataDto,
  SupportingDataOnSubmitData
> => {
  const hookForm = useForm<SupportingDataDto>({
    mode: 'all',
    defaultValues: getDefaultValues(),
    resolver: zodResolver(addSupportingDataSchema),
  });

  return {
    hookForm,
    Form: SupportingDataForm,
    Fields: SupportingDataFields,
  };
};

const SupportingDataForm = ({
  children,
  document,
  onSubmit,
  initialData,
}: LiftAIFormProps<SupportingDataDto, SupportingDataOnSubmitData>) => {
  const { showEntityActionSnackbar } = useLAISnackbar();
  const { handleSubmit, reset, watch } = useFormContext<SupportingDataDto>();

  useEffect(() => {
    if (!initialData) {
      return;
    }

    reset({
      ...getDefaultValues(),
      ...(initialData ?? {}),
    });
  }, [initialData, reset]);

  if (!document) {
    console.error('No document provided to SupportingDataForm');
    return <></>;
  }

  const { supportingDataMovingForward, remainingPages, isContinued, subsequentPagesAsContinued } =
    watch();

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data) => {
          const apiClient = getApiClient();

          try {
            if (isLiftAIDocumentPDFUntagged(document)) {
              if ('ticketId' in data && data.ticketId) {
                if (subsequentPagesAsContinued) {
                  const docs = new Array(document.pageNum - 1 + (remainingPages ?? 0)).fill(0);
                  // We cannot optimize this with Promise.all as upload order is important
                  for (let i = 0; i < docs.length; i++) {
                    await associateFileToEntity(document.attachment.id, data.ticketId, 'ticket', {
                      pageNumber: document.pageNum + i,
                      isPrimary: true,
                      isNextPage: true,
                    });
                  }
                } else {
                  await associateFileToEntity(document.attachment.id, data.ticketId, 'ticket', {
                    pageNumber: document.pageNum,
                    isPrimary: false,
                    isNextPage: true,
                  });
                }
                const ticket: Ticket = await apiClient.tickets.getById(data.ticketId);
                onSubmit({
                  number: ticket.number,
                  propertyName: ticket.property.name,
                });
              } else if ('certificateId' in data && data.certificateId) {
                await associateFileToEntity(
                  document.attachment.id,
                  data.certificateId,
                  'certificate',
                  {
                    pageNumber: document.pageNum,
                    isPrimary: false,
                    isNextPage: true,
                  },
                );
                onSubmit({
                  number: data.certificateId.slice(0, 8),
                  propertyName: 'Certificate Continuation',
                });
              } else if ('inspectionReportId' in data && data.inspectionReportId) {
                await associateFileToEntity(
                  document.attachment.id,
                  data.inspectionReportId,
                  'inspection_report',
                  {
                    pageNumber: document.pageNum,
                    isPrimary: false,
                    isNextPage: true,
                  },
                );
                onSubmit({
                  number: data.inspectionReportId.slice(0, 8),
                  propertyName: 'Inspection Report',
                });
              } else if ('invoiceId' in data && data.invoiceId) {
                const invoiceId = data.invoiceId;
                if (subsequentPagesAsContinued) {
                  const docs = new Array(document.pageNum - 1 + (remainingPages ?? 0)).fill(0);
                  // We cannot optimize this with Promise.all as upload order is important
                  for (let i = 0; i < docs.length; i++) {
                    await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                      pageNumber: document.pageNum + i,
                      isPrimary: true,
                      isNextPage: true,
                    });
                  }
                } else if (isContinued) {
                  await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                    pageNumber: document.pageNum,
                    isPrimary: true,
                    isNextPage: true,
                  });
                } else if (supportingDataMovingForward) {
                  const docs = new Array(document.pageNum + (remainingPages ?? 0)).fill(0);
                  // We cannot optimize this with Promise.all as upload order is important
                  for (let i = 0; i < docs.length; i++) {
                    await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                      pageNumber: document.pageNum + i,
                    });
                  }
                } else {
                  await associateFileToEntity(document.attachment.id, invoiceId, 'invoice', {
                    pageNumber: document.pageNum,
                  });
                }

                const invoice = await apiClient.invoices.getById(invoiceId);
                onSubmit({
                  number: invoice.number,
                  propertyName: invoice.property.name,
                });
              }
            }

            showEntityActionSnackbar(
              {
                name: document.documentType ?? 'Document',
                action: ActionEnum.Create,
                id: undefined,
              },
              {
                variant: 'info',
              },
            );
          } catch (err) {
            console.error('err :>> ', err);
          }
        },
        (err) => {
          console.log('err :>> ', err);
        },
      )}
    >
      {children}
    </form>
  );
};

const getForProp = (documentType?: UploadDocumentType) => {
  switch (documentType) {
    case UploadDocumentType.supportingData:
    case UploadDocumentType.invoiceContinued:
    case UploadDocumentType.proposalContinued:
      return 'invoice';
    case UploadDocumentType.ticketContinued:
      return 'ticket';
    case UploadDocumentType.certificateContinued:
      return 'certificate';
    case UploadDocumentType.inspectionReportContinued:
      return 'inspectionReport';
    default:
      return '';
  }
};

export const ticketOrInvoiceCertificateOptionsFetcher: Fetcher<
  | API_InvoiceFilterResponse['results']
  | API_TicketFilterResponse['results']
  | API_CertificateFilterResponse['results']
  | API_InspectionReportFilterResponse['results'],
  readonly [
    'SupportingDataForm.Options',
    forProp: 'ticket' | 'invoice' | 'certificate' | 'inspectionReport',
  ]
> = async ([_, forProp]) => {
  const apiClient = getApiClient();
  switch (forProp) {
    case 'ticket':
      return apiClient.tickets.filter({ ordering: 'number' }).then((r) => r.results);
    case 'invoice':
      return apiClient.invoices
        .filter({ ordering: 'number', variant: 'form' })
        .then((r) => r.results);
    case 'certificate':
      return apiClient.certificates.get({ ordering: 'expiration_date' }).then((r) => r.results);
    case 'inspectionReport':
      return apiClient.inspectionReports
        .get({ ordering: 'inspection_date' })
        .then((r) => r.results);
    default:
      return [];
  }
};

const SupportingDataFields = ({ documentType }: { documentType?: UploadDocumentType }) => {
  const { control, watch } = useFormContext<SupportingDataDto>();

  const forProp = getForProp(documentType);

  const { data, isLoading } = useSWR(
    forProp ? (['ticketOrInvoiceOptionsFetcher', forProp] as const) : null,
    ticketOrInvoiceCertificateOptionsFetcher,
    {},
  );
  const invoiceKind = watch('invoiceKind');

  let fieldName: string;
  switch (forProp) {
    case 'invoice':
      fieldName = 'invoiceId';
      break;
    case 'certificate':
      fieldName = 'certificateId';
      break;
    case 'inspectionReport':
      fieldName = 'inspectionReportId';
      break;
    default:
      fieldName = 'ticketId';
  }

  const fields = useMemo(() => {
    let label;
    switch (fieldName) {
      case 'invoiceId':
        label = `Related to ${invoiceKind ?? 'Invoice or Proposal'} #`;
        break;
      case 'ticketId':
        label = 'Related to Ticket #';
        break;
      case 'certificateId':
        label = 'Related to Certificate';
        break;
      case 'inspectionReportId':
        label = 'Related to Inspection Report';
        break;
    }

    let options: { id: string; label: string }[] = [];

    if (data && !isLoading) {
      // Narrow down the data type based on the fieldName
      switch (fieldName) {
        case 'certificateId': {
          // data should be API_CertificateFilterResponse['results']
          const docs = data as API_CertificateFilterResponse['results'];
          options = docs.map((doc) => {
            const expirationDate = new Date(doc.expirationDate).toLocaleDateString();
            console.log('This is the doc:', doc);
            return {
              id: doc.id,
              label: `Certificate ${doc.property.name} - ${doc.car.name} (Expires: ${expirationDate})`,
            };
          });
          break;
        }
        case 'inspectionReportId': {
          // data should be API_InspectionReportFilterResponse['results']
          const docs = data as API_InspectionReportFilterResponse['results'];
          options = docs.map((doc) => {
            const inspectionDate = new Date(doc.inspectionDate).toLocaleDateString();
            return {
              id: doc.id,
              label: `Inspection Report ${doc.property.name} - ${doc?.car?.name} (Inspection Date: ${inspectionDate})`,
            };
          });
          break;
        }
        case 'invoiceId': {
          // data should be API_InvoiceFilterResponse['results']
          const docs = data as API_InvoiceFilterResponse['results'];
          options = docs.map((doc) => ({
            id: doc.id,
            label: doc.number,
          }));
          break;
        }
        case 'ticketId': {
          // data should be API_TicketFilterResponse['results']
          const docs = data as API_TicketFilterResponse['results'];
          options = docs.map((doc) => ({
            id: doc.id,
            label: doc.number,
          }));
          break;
        }
      }
    }

    return [
      {
        name: fieldName,
        label,
        type: 'autocomplete',
        options,
        loading: isLoading,
        required: false,
        gridProps: { xs: 12 },
      },
    ] as FieldDef[];
  }, [data, fieldName, invoiceKind, isLoading]);

  return (
    <>
      <Typography variant="h6" my={2}>
        General
      </Typography>
      <Grid container spacing={2} my={2}>
        {fields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};

import {
  addYears,
  differenceInDays,
  endOfMonth,
  format,
  parseISO,
  startOfDay,
  startOfMonth,
  subYears,
} from 'date-fns';

/**
 * Formats a date string into a human-readable string representing the
 * difference between the given date and today.
 *
 * @param dateString - The date string to format. Can be an ISO string or
 *                     a string in the format 'YYYY-MM-DD'.
 * @returns A string representing the time difference (e.g., "Today", "In 3 days", "2 days ago").
 */

export const formatDateDistance = (dateString: string) => {
  const today = startOfDay(new Date());

  const date = dateString.includes('T') ? parseISO(dateString) : parseISO(dateString);

  const normalizedDate = startOfDay(date);

  const days = differenceInDays(normalizedDate, today);

  if (days === 0) {
    return 'Today';
  }

  if (days > 0) {
    return `In ${days} ${days === 1 ? 'day' : 'days'}`;
  }

  const absDays = Math.abs(days);
  return `${absDays} ${absDays === 1 ? 'day' : 'days'} ago`;
};

export const dateToDateString = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

/**
 * getDefaultMonthRange returns the default date range for the MonthRangePicker
 * that is the last 12 months from the current date
 * @returns { start: Date; end: Date } default date range
 * @example
 * const { start, end } = getDefaultMonthRange();
 * console.log(start, end); // [Date[2021-01-01], Date[2022-01-01]]
 */
export const getDefaultMonthRange = (): { start: Date; end: Date } => {
  const today = new Date();
  const pastYearDate = startOfMonth(subYears(today, 1));

  return { start: pastYearDate, end: endOfMonth(addYears(pastYearDate, 1)) };
};

import type { CarDto } from '@liftai/asset-management-types';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';
import { useCarForm } from './PropertyCarForm';

interface IEditCarDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: CarDto) => void;
  defaultValues?: CarDto;
}

const PropertyEditCarDialog = ({ open, onClose, onSubmit, defaultValues }: IEditCarDialogProps) => {
  const theme = useTheme();
  const { hookForm, Form: CarForm, Fields: CarFields } = useCarForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <FormProvider {...hookForm}>
      <CarForm onSubmit={onSubmit} initialData={defaultValues}>
        <Dialog open={open} onClose={onClose} maxWidth="sm" disablePortal>
          <DialogTitleWithCloseButton onClose={onClose} title={'Edit Unit'} />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'auto', height: '100%' }}
          >
            <CarFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </Dialog>
      </CarForm>
    </FormProvider>
  );
};

export default PropertyEditCarDialog;

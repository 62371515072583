import { type Invoice, InvoiceKind } from '@liftai/asset-management-types';
import Download from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';

import { useStampedInvoice } from './useStampedInvoice';

export function DownloadStampedInvoiceDropdownMenu({ invoice }: { invoice: Invoice }) {
  const { downloadStampedInvoice, StampedInvoiceContainer } = useStampedInvoice({ invoice });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick} sx={{ pl: 0 }}>
        <Download sx={{ fontSize: 16, mr: 1 }} /> Download
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={async () => {
            await downloadStampedInvoice();
            setAnchorEl(null);
          }}
        >
          {invoice.kind === InvoiceKind.Proposal
            ? 'Download Stamped Proposal'
            : 'Download Stamped Invoice'}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await downloadStampedInvoice({ includeSupportingData: true });
            setAnchorEl(null);
          }}
        >
          Download with Supporting Data
        </MenuItem>
      </Menu>
      <StampedInvoiceContainer invoice={invoice} />
    </div>
  );
}

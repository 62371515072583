import z from 'zod';
import { propertySchema } from './property';
export const CarType = {
    Traction: 'Traction',
    Hydro: 'Hydro',
    Basement: 'Basement',
    Buried: 'Buried',
    Dual: 'Dual',
    Handicap: 'Handicap',
    Holeless: 'Holeless',
    Inverted: 'Inverted',
    MachineLess: 'MachineLess',
    OverheadGear: 'OverheadGear',
    OverheadGearless: 'OverheadGearless',
    Rack: 'Rack',
    Escalator: 'Escalator',
    SideMountGeared: 'SideMountGeared',
    SideMountGearless: 'SideMountGearless',
    Other: 'Other',
};
export const CarTypeValues = Object.values(CarType);
export const CarTypeOptions = {
    [CarType.Traction]: 'Traction',
    [CarType.Hydro]: 'Hydro',
    [CarType.Basement]: 'Basement geared traction',
    [CarType.Buried]: 'Buried direct-acting hydraulic',
    [CarType.Dual]: 'Dual-post holeless hydraulic',
    [CarType.Handicap]: 'Handicap lift',
    [CarType.Holeless]: 'Holeless direct-acting hydraulic',
    [CarType.Inverted]: 'Inverted twin-post holeless hydraulic',
    [CarType.MachineLess]: 'Machine-room-less',
    [CarType.OverheadGear]: 'Overhead geared traction',
    [CarType.OverheadGearless]: 'Overhead gearless traction',
    [CarType.Rack]: 'Rack and pinion',
    [CarType.Escalator]: 'Escalator',
    [CarType.SideMountGeared]: 'Sidemount geared traction',
    [CarType.SideMountGearless]: 'Sidemount gearless traction',
    [CarType.Other]: 'Other',
};
export const CarInventoryStatus = {
    WIP: 'WP',
    READY: 'RD',
    SHIPPED: 'SH',
    INSTALLED: 'IN',
    TESTING: 'TE',
    SUSPENDED: 'SU',
    DECOMMISIONED: 'DE',
};
export const CarInventoryStatusValues = Object.values(CarInventoryStatus);
export const carSchema = z.object({
    id: z.string(),
    name: z.string(),
    tripCount30Day: z.number(),
    lastTripDate: z.string().nullable(),
    elevatorType: z.enum(CarTypeValues).nullable(),
    criticalUnit: z.boolean(),
    installationYear: z.number(),
    modernizationYear: z.number().nullable(),
    numEntrances: z.number().nullable(),
    speedFpm: z.number().nullable(),
    capacityLbs: z.number().nullable(),
    lastSspiTest: z.string().nullable(),
    driveType: z.string().nullable(),
    controllerModel: z.string().nullable(),
    oem: z.string().nullable(),
    serviceProviderInternalId: z.string().nullable(),
    bank: z
        .object({
        id: z.string(),
        name: z.string(),
        property: z.string(),
    })
        .nullable()
        .optional(),
    inventoryStatus: z.enum(CarInventoryStatusValues),
    numLandings: z.number().gt(0).nullable(),
    // We use `z.lazy()` because `carSchema` and `propertySchema` reference each other.
    property: z.lazy(() => propertySchema),
    serviceProviderId: z.string().nullable(),
    ahjId: z.string().nullable(),
});
export const carDtoSchema = z.object({
    id: z.string().optional(),
    ahjId: z.string().nullable(),
    name: z.string().min(1, { message: 'Name is required' }),
    criticalUnit: z.boolean(),
    elevatorType: z.string().nullable(), // cannot have z.enum(CarTypeValues) since there are other values in the database so validation will fail
    installationYear: z.coerce.number().nullable(),
    modernizationYear: z.coerce.number().nullable(),
    numLandings: z.coerce.number().nullable(),
    speedFpm: z.coerce.number().nullable(),
    controllerModel: z.string().nullable(),
    driveType: z.string().nullable(),
    lastSspiTest: z.string().nullable(),
    numEntrances: z.coerce.number().nullable(),
    oem: z.string().nullable(),
});
export const carDisplaySchema = carSchema.pick({
    id: true,
    name: true,
    tripCount30Day: true,
    lastTripDate: true,
    elevatorType: true,
    ahjId: true,
});
export const carFormSchema = carSchema.pick({
    id: true,
    name: true,
    property: true,
});
export const carInPropertySchema = carSchema
    .pick({
    id: true,
    name: true,
    ahjId: true,
    criticalUnit: true,
    elevatorType: true,
    installationYear: true,
    modernizationYear: true,
    numLandings: true,
    inventoryStatus: true,
    speedFpm: true,
    capacityLbs: true,
    controllerModel: true,
    driveType: true,
    lastSspiTest: true,
    numEntrances: true,
    oem: true,
    serviceProviderId: true,
})
    .extend({
    bank: z.string().nullable(),
    serviceProviderGroup: z.string().nullable(),
    callbacks: z.number().nullable(),
    callbackRate: z.number().nullable(),
    callbackMedianTime: z.number().nullable(),
    maintenanceCompletion: z.number().nullable(),
    maintenanceVisits: z.number().nullable(),
});
export const violationStatisticsSchema = z.object({
    open: z.number().min(0),
    overdue: z.number().min(0),
    resolved: z.number().min(0),
    total: z.number().min(0),
});
export const violationSchema = z.object({
    id: z.string(),
    code: z.string(),
    location: z.string(),
    correctionDue: z.string(),
    dateOpen: z.string(),
    status: z.enum(['open', 'resolved', 'overdue']),
    description: z.string(),
    responsibility: z.enum(['owner', 'service_provider', 'county']),
    resolutionType: z.string().nullable(),
    createdAt: z.string(),
    resolvedDt: z.string().nullable(),
    noteCount: z.number(),
});
export const violationsResponseSchema = z.object({
    statistics: violationStatisticsSchema,
    results: z.array(violationSchema),
});
export const carCertificateSchema = z.object({
    dateIssued: z.string(),
    downloadUrl: z.string(),
    documentType: z.string(),
});
export const yearOptions = (() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => (1950 + i).toString()).reverse();
    return years;
})();

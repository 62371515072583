import {
  ContractHourlyRate,
  ContractHourTypeOptions,
  ContractWorkerTypeOptions,
  ContractWorkTypeOptions,
} from '@liftai/asset-management-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';

import { UserContext } from '~/auth/userContext';
import { yesOrNo } from '~/components/utils/booleanToText';
import { formatCurrency } from '~/utils/format';

export type HourlyRateTableProps = {
  rates: ContractHourlyRate[];
};
export const HourlyRateTable: React.FC<HourlyRateTableProps> = ({ rates }) => {
  const { currencyCode } = useContext(UserContext);

  return (
    <>
      <Typography variant="h6">Hourly Rates</Typography>
      <TableContainer style={{ marginTop: '24px !important' }}>
        <Table aria-label="Hourly Rates">
          <TableHead>
            <TableRow>
              <TableCell align="left">Hour Type</TableCell>
              <TableCell align="left">Worker type</TableCell>
              <TableCell align="left">Work Type</TableCell>
              <TableCell align="left">Value</TableCell>
              <TableCell align="left">Covered by Contract</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {ContractHourTypeOptions[row.hourType] ?? '-'}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {ContractWorkerTypeOptions[row.workerType] ?? '-'}
                </TableCell>
                <TableCell align="left">{ContractWorkTypeOptions[row.workType] ?? '-'}</TableCell>
                <TableCell align="left">
                  {formatCurrency({ value: row.hourRate, currencyCode })}
                </TableCell>
                <TableCell align="left">{yesOrNo(row.coveredByContract)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

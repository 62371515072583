import { zodResolver } from '@hookform/resolvers/zod';
import type {
  API_InvoiceCreateRequest,
  API_InvoiceUpdateRequest,
  Invoice,
  InvoiceForm as InvoiceDto,
  PropertyForm,
  Provider,
  User,
} from '@liftai/asset-management-types';
import {
  invoiceFormSchema,
  InvoiceKind,
  invoiceKindToLabelMap,
  InvoiceStatus,
  InvoiceType,
} from '@liftai/asset-management-types';
import { API_UserGroup } from '@liftai/asset-management-types/src/api/userGroup';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { omit } from 'lodash-es';
import { useContext, useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import useSWR, { type Fetcher, useSWRConfig } from 'swr';

import { UserContext } from '~/auth/userContext';
import type {
  LAIUseFormReturn,
  LiftAIDocumentTagged,
  LiftAIFormProps,
} from '~/components/uploadDocument/SelectDocumentTypeDialog';
import {
  associateFileToEntity,
  getFilesAssociatedToEntity,
  updateAssociationFileToEntity,
} from '~/components/utils/upload';
import { getInvoiceMutator } from '~/data/hooks/utils';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { getApiClient } from '~/utils/api';
import { toDateLocal } from '~/utils/format';

import { FieldAndCell } from '../form/utils';
import { buildFieldDef } from '../invoices/invoiceFields';

const invoiceOptionsFetcher: Fetcher<
  {
    consultants: User[];
    properties: PropertyForm[];
  },
  readonly [key: string, userGroup: API_UserGroup]
> = async ([_, userGroup]) => {
  const apiClient = getApiClient();
  const [consultantGroup, properties] = await Promise.all([
    apiClient.consultants.get(userGroup.id),
    apiClient.properties.getAll({ variant: 'form' }),
  ]);

  return { consultants: consultantGroup?.consultants ?? [], properties };
};

const invoiceServiceProvidersFetcher: Fetcher<
  Provider[],
  readonly [key: 'invoiceServiceProviders', propertyId: string]
> = async ([_, propertyId]) => {
  const apiClient = getApiClient();
  const serviceProviders = await apiClient.providers.filter({ propertyId });
  return serviceProviders;
};

export const getDefaultInvoiceFormValues = (): InvoiceDto => {
  return {
    id: '',
    number: '',
    date: '',
    kind: InvoiceKind.Invoice,
    type: InvoiceType.Maintenance,
    status: InvoiceStatus.Pending,
    propertyId: '',
    carIds: [],
    consultantId: '',
    lineItems: [],
    proposedAmount: 0,
    description: undefined,
    autoUpload: false,
    serviceProviderId: '',
    stampFile: null,
  };
};

export const mapInvoiceToFormValues = (invoice: Invoice): InvoiceDto => {
  const form: InvoiceDto = {
    ...getDefaultInvoiceFormValues(),
    id: invoice.id,
    propertyId: invoice.property?.id ?? undefined,
    consultantId: invoice.consultant?.id ?? '',
    serviceProviderId: invoice.serviceProvider?.id ?? '',
    carIds: invoice.carsServiced?.map((car) => car.id) ?? [],
    kind: invoice.kind,
    date: invoice.date ? toDateLocal(invoice.date) : '',
    number: invoice.number,
    type: invoice.type,
    status: invoice.status,
    description: invoice.description,
    proposedAmount: invoice.proposedAmount,
    lineItems: invoice.lineItems,
    autoUpload: invoice.autoUpload,
  };
  return form;
};

/** API Create Request */
const saveInvoice = async (dto: InvoiceDto): Promise<Invoice> => {
  const apiClient = getApiClient();
  const invoice = await (dto.id
    ? // TODO: Fix this type casting.
      apiClient.invoices.update(
        dto.id,
        omit(dto, ['id', 'attachments']) as API_InvoiceUpdateRequest,
      )
    : apiClient.invoices.create(omit(dto, ['id', 'attachments']) as API_InvoiceCreateRequest));

  return invoice;
};

const updateReferenceToFiles = async (invoice: Invoice, document: LiftAIDocumentTagged) => {
  if (invoice.id) {
    const attachments = await getFilesAssociatedToEntity(invoice.id, 'invoice');

    await Promise.all(
      attachments
        .filter((attachment) => attachment.isPrimary)
        .map((attachment) =>
          updateAssociationFileToEntity(attachment.attachment, invoice.id, 'invoice', {
            id: attachment.id,
            isVoid: true,
          }),
        ),
    );
  }

  await associateFileToEntity(
    document.attachment.id,
    invoice.id,
    'invoice',
    document.fileType === 'pdf'
      ? {
          pageNumber: document.pageNum,
          isPrimary: true,
        }
      : {},
  );
};

export const useInvoiceForm = (): LAIUseFormReturn<InvoiceDto, Invoice> => {
  const hookForm = useForm<InvoiceDto>({
    mode: 'all',
    defaultValues: getDefaultInvoiceFormValues(),
    resolver: zodResolver(invoiceFormSchema),
  });

  return {
    hookForm,
    Form: InvoiceForm,
    Fields: InvoiceFields,
  };
};

export const InvoiceForm = ({
  children,
  document,
  onSubmit,
  initialData,
}: LiftAIFormProps<InvoiceDto, Invoice>) => {
  const { mutate } = useSWRConfig();
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, watch, reset, resetField, trigger, setValue } =
    useFormContext<InvoiceDto>();

  const propertyId = watch('propertyId');

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const formValues = {
      ...getDefaultInvoiceFormValues(),
      ...initialData,
    };

    reset(formValues);
  }, [initialData, reset]);

  useEffect(() => {
    if (propertyId) {
      resetField('carIds', { defaultValue: [] });
    }
  }, [propertyId, resetField]);

  useEffect(() => {
    const subscription = watch(({ lineItems, proposedAmount }, { name }) => {
      if (lineItems && name?.match(/lineItems\.\d+\.amount/)) {
        const lineItemsTotal = lineItems
          // It's important the check map is written in this way
          // even though the types do not express this, amount can be the empty string
          .map((item) => (item?.amount ? item.amount : 0))
          .reduce((acc, curr) => acc + curr, 0);

        if (proposedAmount !== lineItemsTotal) {
          setValue('proposedAmount', parseFloat(lineItemsTotal.toFixed(2)));
          void trigger('proposedAmount');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, trigger]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data, e) => {
          try {
            const invoiceData = invoiceFormSchema.parse(data);
            const invoice = await saveInvoice(invoiceData);

            if (document) {
              await updateReferenceToFiles(invoice, document);
            }

            onSubmit(invoice);

            const action = data.id ? ActionEnum.Update : ActionEnum.Create;
            showEntityActionSnackbar(
              {
                name: invoiceKindToLabelMap.get(invoiceData.kind)!,
                action,
                id: data.number,
              },
              {
                variant: 'info',
              },
            );

            void mutate(getInvoiceMutator(data.id));
          } catch (err) {
            console.error('err :>> ', err);
            showSnackbar('Invoice failed to save', {
              variant: 'error',
            });
          }
        },
        (err) => {
          console.log('err :>> ', err);
        },
      )}
    >
      {children}
    </form>
  );
};

const InvoiceFields: React.FC = () => {
  const { userGroup } = useContext(UserContext);
  const { control, watch } = useFormContext<InvoiceDto>();
  const propertyId = watch('propertyId');
  const kind = watch('kind');
  const fetcher = useSWR(['InvoiceForm.Options', userGroup], invoiceOptionsFetcher);
  const data = fetcher.data;

  const { cars } = usePropertyCars(propertyId);
  const { data: serviceProviders } = useSWR(
    propertyId ? ['invoiceServiceProviders', propertyId] : null,
    invoiceServiceProvidersFetcher,
    {},
  );
  const fields = useMemo(
    () =>
      buildFieldDef({
        properties: data?.properties,
        consultants: data?.consultants,
        kind,
        cars,
        serviceProviders,
      }),
    [data?.properties, data?.consultants, kind, cars, serviceProviders],
  );

  const { generalFields, itemsFields, totalField } = fields;

  return (
    <>
      <Typography variant="h6" my={2}>
        General
      </Typography>
      <Grid container spacing={2} my={2}>
        {generalFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
      <Box my={2}>
        <Typography variant="h6" py={2}>
          Items
        </Typography>
        <Grid container spacing={2} py={2}>
          {itemsFields.map((field) => (
            <FieldAndCell key={field.name} field={field} control={control} />
          ))}
        </Grid>
        <Grid container spacing={2} py={2}>
          <FieldAndCell field={totalField} control={control} />
        </Grid>
      </Box>
    </>
  );
};

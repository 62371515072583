import type {
  API_InvoiceUpdateRequest,
  ApproveInvoiceForm,
  Invoice,
  InvoiceNote,
  PartialApproveInvoiceForm,
  RejectInvoiceForm,
  ResetInvoiceForm,
} from '@liftai/asset-management-types';
import { InvoiceActionType, InvoiceStatus } from '@liftai/asset-management-types';
import { useCallback } from 'react';
import useSWR, { type Fetcher, type SWRConfiguration, useSWRConfig } from 'swr';
import type { MutationFetcher } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import { getInvoiceMutator } from '~/data/hooks/utils';
import { getApiClient } from '~/utils/api';

const invoiceFetcher: Fetcher<Invoice, readonly ['invoice', invoiceId: string]> = async ([
  _,
  invoiceId,
]) => {
  const apiClient = getApiClient();
  const response = await apiClient.invoices.getById(invoiceId);

  return response;
};

const invoiceUpdateFetcher: MutationFetcher<
  Invoice,
  readonly ['invoice', invoiceId: string],
  Partial<API_InvoiceUpdateRequest>
> = async ([_, invoiceId], { arg }) => {
  // TODO: Fix this type casting.
  const apiClient = getApiClient();
  const data = await apiClient.invoices.update(invoiceId, arg as API_InvoiceUpdateRequest);

  return data;
};

const invoiceDeleteFetcher: MutationFetcher<void, readonly [key: string], { id: string }> = async (
  [key],
  { arg: { id } },
) => {
  const apiClient = getApiClient();
  await apiClient.invoices.delete(id);
};

const invoiceNotesFetcher: Fetcher<
  InvoiceNote[],
  readonly ['invoice', invoiceId: string, 'notes']
> = async ([_, invoiceId]) => {
  const apiClient = getApiClient();
  const response = await apiClient.invoices.getNotes(invoiceId);

  return response;
};

const invoiceAddNoteFetcher: MutationFetcher<
  void,
  readonly ['invoice', invoiceId: string, 'notes'] | null,
  { consultantId: string; content: string }
> = async ([_, invoiceId], { arg: { consultantId, content } }) => {
  const apiClient = getApiClient();
  await apiClient.invoices.addNote(invoiceId, consultantId, content);
};

const refreshInsightsFetcher: MutationFetcher<
  void,
  readonly ['invoice', invoiceId: string],
  { id: string }
> = async ([key], { arg: { id } }) => {
  const apiClient = getApiClient();
  await apiClient.invoices.generateInsights(id);
};

const useReviewInvoice = (invoiceId: string | null) => {
  const { mutate } = useSWRConfig();

  const { trigger } = useSWRMutation(
    invoiceId ? (['invoice', invoiceId] as const) : null,
    invoiceUpdateFetcher,
    {},
  );

  const reviewInvoice = useCallback(
    async (
      invoiceActionType: InvoiceActionType,
      formData?:
        | RejectInvoiceForm
        | PartialApproveInvoiceForm
        | ResetInvoiceForm
        | ApproveInvoiceForm,
    ) => {
      if (!invoiceId) {
        throw new Error('Invoice ID is required to update an invoice');
      }

      const dateStamped = new Date().toISOString(); // QUESTION(zachary): Should we trust the client's time?

      let updateDataBody: Partial<API_InvoiceUpdateRequest>;

      switch (invoiceActionType) {
        case InvoiceActionType.APPROVE:
          updateDataBody = {
            ...formData,
            id: invoiceId,
            status: InvoiceStatus.Approved,
            dateStamped,
          };
          break;
        case InvoiceActionType.REJECT:
          updateDataBody = {
            ...formData,
            id: invoiceId,
            status: InvoiceStatus.Rejected,
            dateStamped,
            reviewedAmount: (formData as RejectInvoiceForm).countTowardsSavings ? 0 : null,
          };
          break;
        case InvoiceActionType.PARTIAL:
          updateDataBody = {
            ...formData,
            id: invoiceId,
            status: InvoiceStatus.PartialApproval,
            dateStamped,
          };
          break;
        case InvoiceActionType.REQUEST_DATA:
          updateDataBody = { id: invoiceId, status: InvoiceStatus.PendingData };
          break;
        case InvoiceActionType.RESET:
          updateDataBody = {
            id: invoiceId,
            status: InvoiceStatus.Reset,
            reason: formData?.reason,
          };
          break;
        default:
          throw new Error(`Invalid invoice action type: ${invoiceActionType}`);
      }

      await trigger(updateDataBody, { revalidate: false });

      void mutate(getInvoiceMutator(invoiceId));
    },
    [invoiceId, mutate, trigger],
  );

  return reviewInvoice;
};

const useUpdateRelatedInvoice = (invoiceId: string | null) => {
  const { mutate } = useSWRConfig();

  const { trigger } = useSWRMutation(
    invoiceId ? (['invoice', invoiceId] as const) : null,
    invoiceUpdateFetcher,
  );

  const addRelatedInvoice = useCallback(
    async (relatedInvoices: string[]) => {
      await trigger({ relatedInvoices }, { revalidate: false });

      void mutate(getInvoiceMutator(invoiceId));
    },
    [invoiceId, mutate, trigger],
  );

  return addRelatedInvoice;
};

const useInvoiceDelete = () => {
  const { mutate } = useSWRConfig();

  const { trigger } = useSWRMutation(['invoice'], invoiceDeleteFetcher);

  const deleteInvoice = useCallback(
    async (invoiceId: string) => {
      if (!invoiceId) {
        throw new Error('Invoice ID is required to update an invoice');
      }

      await trigger({ id: invoiceId }, { revalidate: false });

      void mutate(getInvoiceMutator(invoiceId));
    },
    [mutate, trigger],
  );

  return deleteInvoice;
};

const useAddInvoiceNote = (invoiceId: string | null) => {
  const { mutate } = useSWRConfig();

  const { trigger } = useSWRMutation(
    invoiceId ? (['invoice', invoiceId, 'notes'] as const) : null,
    invoiceAddNoteFetcher,
    {},
  );

  const addInvoiceNote = useCallback(
    async (consultantId: string, content: string) => {
      if (!consultantId) {
        throw new Error('consultant ID is required to add a note');
      }

      await trigger({ consultantId, content }, { revalidate: false });

      void mutate(getInvoiceMutator(invoiceId));
    },
    [invoiceId, mutate, trigger],
  );

  return addInvoiceNote;
};

export const useRefreshInsights = (invoiceId: string | null) => {
  const { trigger, isMutating } = useSWRMutation(
    invoiceId ? (['invoice', invoiceId] as const) : null,
    refreshInsightsFetcher,
  );

  const refreshInsights = useCallback(async () => {
    if (!invoiceId) {
      throw new Error('Invoice ID is required to refresh insights');
    }

    await trigger({ id: invoiceId });
  }, [invoiceId, trigger]);

  return { refreshInsights, loading: isMutating };
};

/**
 * Fetches the notes for an invoice.
 * @param invoiceId Invoice ID
 * @returns {notes: InvoiceNote[] | undefined, error: Error | undefined, loading: boolean}
 * @example
 * ```typescript
 *   const { notes, error, loading } = useInvoiceNotes('123');
 *   console.log(notes); // [{ id: 'mnj', content: 'example note', owner: {...}, invoice: '123', ... }]
 * ```
 */
const useInvoiceNotes = (invoiceId: string | null) => {
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(invoiceId ? (['invoice', invoiceId, 'notes'] as const) : null, invoiceNotesFetcher);

  return { notes: data ?? [], error, loading };
};

export default function useInvoice(
  invoiceId: string | null,
  options?: Pick<SWRConfiguration, 'onError'>,
) {
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(invoiceId ? (['invoice', invoiceId] as const) : null, invoiceFetcher, options);

  const reviewInvoice = useReviewInvoice(invoiceId);
  const deleteInvoice = useInvoiceDelete();
  const invoiceNotes = useInvoiceNotes(invoiceId);
  const addNoteToInvoice = useAddInvoiceNote(invoiceId);
  const updateRelatedInvoice = useUpdateRelatedInvoice(invoiceId);

  return {
    invoice: data,
    error,
    loading,
    invoiceNotes,
    addNoteToInvoice,
    reviewInvoice,
    deleteInvoice,
    updateRelatedInvoice,
  };
}

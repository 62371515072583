import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import type { TabDefinition } from '~/components/common/tabs';
import { TabPanel, useTabs } from '~/components/common/tabs';
import type { TabsOptions } from '~/components/common/tabs/useTabs';
import { selectedTabSearchParam } from '~/components/global/constants';
import {
  getPortfolioOverviewSearchParams,
  PortfolioOverview,
} from '~/components/portfolio/PortfolioOverview';
import { PropertyCars } from '~/components/portfolio/PropertyCars';
import { PropertyContracts } from '~/components/portfolio/PropertyContracts';
import { PropertySpending } from '~/components/portfolio/PropertySpending';
import { PropertyTickets } from '~/components/portfolio/PropertyTickets';
import type { PropertyDetails } from '~/components/portfolio/types';
import usePropertyContracts from '~/data/hooks/usePropertyContracts';

enum Tab {
  Overview = 'overview',
  Contracts = 'contracts',
  Cars = 'elevators',
  Tickets = 'tickets',
  Spending = 'spending',
}

export { Tab as PortfolioTab };

export const carSearchParam = 'carId';

const tabLabels = [Tab.Overview, Tab.Contracts, Tab.Cars, Tab.Tickets, Tab.Spending];

type PortfolioDetailTabsProps = {
  defaultTab: Tab;
};

const usePortfolioDetailTabs = ({ defaultTab = Tab.Overview }: PortfolioDetailTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromSearchParams = searchParams.get(selectedTabSearchParam);
  const location = useLocation();
  const isPropertyPage = location.pathname.match(/^\/portfolio\/[0-9a-f-]+$/i) !== null;

  const onTabChange = useCallback(
    (tabName: string) => {
      setSearchParams((searchParams) => {
        if (tabName !== searchParams.get(selectedTabSearchParam)) {
          searchParams.set(selectedTabSearchParam, tabName);
        }
        return searchParams;
      });
    },
    [setSearchParams],
  );

  const tabsOpts: TabsOptions = {
    styles: {
      box: { borderColor: 'divider' },
      tabs: { backgroundColor: 'none', marginBottom: isPropertyPage ? '8px' : 3.5 },
    },
    onTabChange: onTabChange,
  };
  const { tabStrip, tabs, selectedTab, selectTab } = useTabs(
    tabLabels,
    tabFromSearchParams ?? defaultTab,
    tabsOpts,
  );

  const buildTabPanel = useCallback(
    (tab: TabDefinition) => {
      const component = (props: React.PropsWithChildren) => (
        <TabPanel tab={tab} hidden={selectedTab !== tab.tabId} {...props} />
      );
      component.displayName = `TabPanel(${tab.label})`;
      return component;
    },
    [selectedTab],
  );

  const tabsComponents = useMemo(
    () => ({
      OverviewTab: buildTabPanel(tabs[Tab.Overview]),
      ContractsTab: buildTabPanel(tabs[Tab.Contracts]),
      CarsTab: buildTabPanel(tabs[Tab.Cars]),
      TicketsTab: buildTabPanel(tabs[Tab.Tickets]),
      SpendingTab: buildTabPanel(tabs[Tab.Spending]),
    }),
    [buildTabPanel, tabs],
  );

  useEffect(() => {
    if (tabFromSearchParams) {
      selectTab(tabFromSearchParams);
    }
  }, [tabFromSearchParams, selectTab]);

  return {
    tabStrip,
    tabs: tabsComponents,
  };
};

export type PortfolioTabContainerProps = { property: PropertyDetails };
export const PortfolioTabContainer: React.FC<PortfolioTabContainerProps> = ({ property }) => {
  const [searchParams] = useSearchParams();
  const hasCarId = searchParams.has(carSearchParam);
  const selectedTab = searchParams.get(selectedTabSearchParam);
  const defaultSelectedTab = hasCarId ? Tab.Cars : Tab.Overview;

  const {
    tabStrip,
    tabs: { ContractsTab, CarsTab, OverviewTab, TicketsTab, SpendingTab },
  } = usePortfolioDetailTabs({ defaultTab: (selectedTab as Tab) ?? defaultSelectedTab });

  const portfolioOverviewSearchParams = useMemo(() => {
    const next = new URLSearchParams(searchParams);
    if (property.id) {
      next.set('property', property.id);
    }
    return getPortfolioOverviewSearchParams(next);
  }, [property.id, searchParams]);

  const { contracts, isLoading } = usePropertyContracts(property.id);

  return (
    <Stack flex={1} sx={{ '.MuiTabs-fixed': { bgcolor: '#fff' } }}>
      {tabStrip}

      <OverviewTab>
        <PortfolioOverview
          propertyId={property.id}
          searchParams={portfolioOverviewSearchParams}
          summary={property.summary}
          trends={property.trends}
        />
      </OverviewTab>
      <ContractsTab>
        {!isLoading ? (
          <PropertyContracts contracts={contracts ?? []} propertyName={property.name} />
        ) : (
          <div>Loading contracts...</div>
        )}
      </ContractsTab>
      <CarsTab>
        <PropertyCars propertyId={property.id} />
      </CarsTab>
      <TicketsTab>
        <PropertyTickets propertyId={property.id} />
      </TicketsTab>
      <SpendingTab>
        <PropertySpending propertyId={property.id} />
      </SpendingTab>
    </Stack>
  );
};

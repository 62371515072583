import { InvoiceNote } from '@liftai/asset-management-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { FC, useState } from 'react';

import { toDateFormatMMddyyyy } from '~/utils/format';

type NoteProps = {
  note: InvoiceNote;
};
export const Note: FC<NoteProps> = ({ note }) => (
  <Grid container sx={{ padding: '4px 16px', display: 'block' }}>
    <Grid size={{ xs: 12 }} sx={{ fontSize: '14px', color: 'rgba(0,0,0,.6)' }}>
      {toDateFormatMMddyyyy(note.createdAt)}
    </Grid>
    <Grid size={{ xs: 12 }} sx={{ fontSize: '14px' }}>
      {note.author?.email ?? 'Consultant'} commented:
    </Grid>
    <Grid size={{ xs: 12 }} sx={{ fontSize: '14px', color: 'rgba(0,0,0,.6)', fontStyle: 'italic' }}>
      &quot;{note.content}&quot;
    </Grid>
    <Divider />
  </Grid>
);

type NotesProps = {
  notes: InvoiceNote[];
  onAddNote: (note: string) => void;
};
export const Notes: FC<NotesProps> = ({ notes, onAddNote }) => {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState('');

  const handleAddNote = (text: string) => {
    onAddNote(text);
    setValue('');
    setCount(0);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setCount(event.target.value.length);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Grid
        container
        flexDirection="column"
        sx={{ height: '100%', position: 'absolute', overflowY: 'auto' }}
      >
        <Grid container px={2} py={3}>
          {notes.map((note) => (
            <Note key={note.id} note={note} />
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection="column"
        alignItems="self-end"
        sx={{
          position: 'absolute',
          width: 'calc(100% - 20px)',
          bottom: 0,
          bgcolor: '#fff',
          paddingBottom: '20px',
        }}
      >
        <TextField
          id="add-comment-box"
          label="Leave a comment"
          helperText={`${count}/500`}
          value={value}
          onChange={handleOnChange}
          multiline
          rows={5}
          sx={{ width: 'calc(100% - 20px)', margin: '20px 0 0 20px' }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: '20px' }}
          onClick={() => handleAddNote(value)}
        >
          Add
        </Button>
      </Grid>
    </Box>
  );
};

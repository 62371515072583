import {
  API_CertificateAttachmentDetails,
  API_InspectionReportAttachmentDetails,
  InvoiceAttachmentDetail,
  TicketAttachmentDetail,
} from '@liftai/asset-management-types';

import { getApiClient } from '~/utils/api';

export interface IUploadEntityFileRequest {
  attachment: string;
  isPrimary: boolean;
  isNextPage: boolean;
  isVoid: boolean;
  pageNumber?: number;
  invoiceId?: string;
  ticketId?: string;
  id?: string;
}

export const associateFileToEntity = async (
  attachmentId: string,
  entityId: string,
  entityType: 'invoice' | 'ticket' | 'certificate' | 'violation' | 'inspection_report' = 'invoice',
  {
    isPrimary = false,
    isNextPage = false,
    pageNumber = 1,
    isVoid = false,
  }: {
    isPrimary?: boolean;
    isNextPage?: boolean;
    pageNumber?: number;
    isVoid?: boolean;
  },
) => {
  const data = {
    attachment: attachmentId,
    isPrimary,
    isNextPage,
    isVoid,
    pageNumber,
    invoiceId: entityType === 'invoice' ? entityId : undefined,
    ticketId: entityType === 'ticket' ? entityId : undefined,
  };

  const apiClient = getApiClient();

  switch (entityType) {
    case 'invoice':
      return apiClient.invoices.createAttachment(entityId, {
        id: entityId,
        ...data,
      });
    case 'ticket':
      return apiClient.tickets.createAttachment(entityId, {
        id: entityId,
        ...data,
      });
    case 'certificate': {
      const result = apiClient.certificates.createAttachment(entityId, {
        id: entityId,
        ...data,
      });
      return result;
    }
    case 'inspection_report': {
      const result = apiClient.inspectionReports.createAttachment(entityId, {
        id: entityId,
        ...data,
      });
      return result;
    }
    default:
      console.error(`Unsupported entity type: ${entityType}`);
  }
};

export const updateAssociationFileToEntity = async (
  attachmentId: string,
  entityId: string,
  entityType: 'invoice' | 'ticket' | 'certificate' | 'inspection_report' = 'invoice',
  {
    isPrimary = false,
    isNextPage = false,
    isVoid = false,
    id,
  }: {
    isPrimary?: boolean;
    isNextPage?: boolean;
    isVoid?: boolean;
    id: string;
  },
) => {
  const data = {
    attachment: attachmentId,
    id,
    isPrimary,
    isNextPage,
    isVoid,
    invoiceId: entityType === 'invoice' ? entityId : undefined,
    ticketId: entityType === 'ticket' ? entityId : undefined,
    certificateId: entityType === 'certificate' ? entityId : undefined,
    inspectionReportId: entityType === 'inspection_report' ? entityId : undefined,
  };

  const apiClient = getApiClient();

  switch (entityType) {
    case 'invoice':
      return apiClient.invoices.updateAttachment(entityId, data);
    case 'ticket':
      return apiClient.tickets.updateAttachment(entityId, data);
    case 'certificate':
      return apiClient.certificates.updateAttachment(entityId, data);
    case 'inspection_report':
      return apiClient.inspectionReports.updateAttachment(entityId, data);
    default:
      throw new Error(`Unsupported entity type: ${entityType}`);
  }
};

export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'invoice',
): Promise<InvoiceAttachmentDetail[]>;
export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'ticket',
): Promise<TicketAttachmentDetail[]>;

export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'certificate',
): Promise<API_CertificateAttachmentDetails[]>;

export function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'inspection_report',
): Promise<API_InspectionReportAttachmentDetails[]>;

export async function getFilesAssociatedToEntity(
  entityId: string,
  entityType: 'invoice' | 'ticket' | 'certificate' | 'inspection_report',
) {
  const apiClient = getApiClient();
  switch (entityType) {
    case 'invoice':
      return apiClient.invoices
        .getAttachments(entityId)
        .then((attachments) => attachments.filter((attachment) => attachment.invoice === entityId));
    case 'ticket':
      return apiClient.tickets.getAttachments(entityId);
    case 'certificate':
      return apiClient.certificates.getAttachments(entityId);
    case 'inspection_report':
      return apiClient.inspectionReports.getAttachments(entityId);
    default:
      console.error(`Unsupported entity type: ${entityType}`);
  }
}

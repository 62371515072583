import type {
  ApproveInvoiceForm,
  Invoice,
  PartialApproveInvoiceForm,
  RejectInvoiceForm,
  ResetInvoiceForm,
} from '@liftai/asset-management-types';
import { InvoiceActionType, isInvoiceFinal } from '@liftai/asset-management-types';
import { Button, Toolbar } from '@mui/material';
import { useCallback, useReducer } from 'react';

import ConfirmationDialog from '~/components/invoices/ConfirmationDialog';
import {
  ApproveDialogContent,
  PartialApproveDialogContent,
  RejectDialogContent,
  ResetDialogContent,
} from '~/components/invoices/InvoiceActionsDialogsContent';

interface InvoiceActionsProps {
  onApprove: (formData: ApproveInvoiceForm) => Promise<void>;
  onReset: (formData: ResetInvoiceForm) => Promise<void>;
  onReject: (formData: RejectInvoiceForm) => Promise<void>;
  onPartial: (formData: PartialApproveInvoiceForm) => Promise<void>;
  onRequestData: () => Promise<void>;
  onDownloadPDF: (includeSupportingData?: boolean) => Promise<void>;
  invoice?: Invoice;
}

type InvoiceAction =
  | {
      type: 'SHOW_FORM';
      invoiceActionType:
        | InvoiceActionType.PARTIAL
        | InvoiceActionType.REJECT
        | InvoiceActionType.RESET
        | InvoiceActionType.APPROVE;
    }
  | {
      type: 'SUBMIT_FORM';
      invoiceActionType: InvoiceActionType;
    }
  | {
      type: 'FORM_SUBMITTED';
    }
  | {
      type: 'FORM_DISMISSED';
    };

type InvoiceActionState =
  | {
      type: 'initial';
    }
  | {
      type: 'show_form';
      invoiceActionType: InvoiceActionType;
    }
  | {
      type: 'submitting_form';
      invoiceActionType: InvoiceActionType;
    }
  | { type: 'final'; invoiceActionType: InvoiceActionType };

const reducer = (state: InvoiceActionState, action: InvoiceAction): InvoiceActionState => {
  switch (action.type) {
    case 'SHOW_FORM':
      return { type: 'show_form', invoiceActionType: action.invoiceActionType };
    case 'SUBMIT_FORM':
      return {
        type: 'submitting_form',
        invoiceActionType: action.invoiceActionType,
      };
    case 'FORM_SUBMITTED':
      if (state.type !== 'submitting_form') {
        throw new Error('Cannot finalize when not in submitting_form state');
      }
      return {
        ...state,
        type: 'final',
      };
    case 'FORM_DISMISSED':
      return { type: 'initial' };
  }
};

export default function InvoiceActions({
  onApprove,
  onReset,
  onPartial,
  onReject,
  onRequestData,
  onDownloadPDF,
  invoice,
}: InvoiceActionsProps) {
  const [state, dispatch] = useReducer(reducer, { type: 'initial' });

  const showConfirmationDialog =
    invoice &&
    state.type === 'final' &&
    [InvoiceActionType.APPROVE, InvoiceActionType.PARTIAL, InvoiceActionType.REJECT].includes(
      state.invoiceActionType,
    );
  const isSaving = state.type === 'submitting_form';
  const showForm = (state.type === 'show_form' || isSaving) && invoice;
  const buttonsDisabled = isSaving || !invoice;

  // The first have a different flow as there is no intermediary dialog
  const handleRequestData = useCallback(async () => {
    dispatch({
      type: 'SUBMIT_FORM',
      invoiceActionType: InvoiceActionType.REQUEST_DATA,
    });
    await onRequestData();
    dispatch({ type: 'FORM_SUBMITTED' });
  }, [onRequestData]);

  const handleApprove = useCallback(async () => {
    dispatch({ type: 'SHOW_FORM', invoiceActionType: InvoiceActionType.APPROVE });
  }, []);

  const handleReject = useCallback(async () => {
    dispatch({ type: 'SHOW_FORM', invoiceActionType: InvoiceActionType.REJECT });
  }, []);

  const handlePartial = useCallback(async () => {
    dispatch({ type: 'SHOW_FORM', invoiceActionType: InvoiceActionType.PARTIAL });
  }, []);

  const handleClose = useCallback(() => {
    dispatch({ type: 'FORM_DISMISSED' });
  }, []);

  const handleReset = useCallback(async () => {
    dispatch({ type: 'SHOW_FORM', invoiceActionType: InvoiceActionType.RESET });
  }, []);

  const handleResetSubmit = useCallback(
    async (data: ResetInvoiceForm) => {
      dispatch({ type: 'SUBMIT_FORM', invoiceActionType: InvoiceActionType.RESET });
      await onReset(data);
      dispatch({ type: 'FORM_SUBMITTED' });
    },
    [onReset],
  );

  const handleApproveSubmit = useCallback(
    async (data: ApproveInvoiceForm) => {
      dispatch({
        type: 'SUBMIT_FORM',
        invoiceActionType: InvoiceActionType.APPROVE,
      });
      await onApprove(data);
      dispatch({ type: 'FORM_SUBMITTED' });
    },
    [onApprove],
  );

  const handlePartialApproveSubmit = useCallback(
    async (data: PartialApproveInvoiceForm) => {
      dispatch({ type: 'SUBMIT_FORM', invoiceActionType: InvoiceActionType.PARTIAL });
      await onPartial(data);
      dispatch({ type: 'FORM_SUBMITTED' });
    },
    [onPartial],
  );

  const handleRejectSubmit = useCallback(
    async (data: RejectInvoiceForm) => {
      dispatch({ type: 'SUBMIT_FORM', invoiceActionType: InvoiceActionType.REJECT });
      await onReject(data);
      dispatch({ type: 'FORM_SUBMITTED' });
    },
    [onReject],
  );

  return (
    <>
      <Toolbar
        sx={{
          borderTop: 1,
          borderColor: 'divider',
          alignItems: 'center',
          justifyContent: 'space-around',
          ...(!invoice && { opacity: 0.5, cursor: 'not-allowed' }),
        }}
      >
        {!invoice || !isInvoiceFinal(invoice) ? (
          <>
            <Button
              variant="contained"
              color="success"
              disabled={buttonsDisabled}
              onClick={handleApprove}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={buttonsDisabled}
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="warning"
              disabled={buttonsDisabled}
              onClick={handlePartial}
            >
              Partial
            </Button>
            <Button
              variant="contained"
              color="info"
              disabled={buttonsDisabled}
              onClick={handleRequestData}
            >
              Request Data
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="error"
            disabled={buttonsDisabled}
            onClick={handleReset}
          >
            Undo Final Stamp
          </Button>
        )}
      </Toolbar>

      {showForm ? (
        <>
          {state.invoiceActionType === InvoiceActionType.APPROVE && (
            <ApproveDialogContent
              isSaving={isSaving}
              invoice={invoice}
              onClose={handleClose}
              onSubmit={handleApproveSubmit}
            />
          )}
          {state.invoiceActionType === InvoiceActionType.PARTIAL && (
            <PartialApproveDialogContent
              isSaving={isSaving}
              invoice={invoice}
              onClose={handleClose}
              onSubmit={handlePartialApproveSubmit}
            />
          )}
          {state.invoiceActionType === InvoiceActionType.REJECT && (
            <RejectDialogContent
              isSaving={isSaving}
              invoice={invoice}
              onClose={handleClose}
              onSubmit={handleRejectSubmit}
            />
          )}
          {state.invoiceActionType === InvoiceActionType.RESET && (
            <ResetDialogContent
              isSaving={isSaving}
              invoice={invoice}
              onClose={handleClose}
              onSubmit={handleResetSubmit}
            />
          )}
        </>
      ) : null}

      {showConfirmationDialog ? (
        <ConfirmationDialog
          onClose={handleClose}
          onDownloadPDF={onDownloadPDF}
          actionType={state.invoiceActionType}
          invoice={invoice}
        />
      ) : null}
    </>
  );
}

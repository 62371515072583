import { OEM } from '@liftai/asset-management-types';
import useSWR, { type Fetcher } from 'swr';

import { getApiClient } from '~/utils/api';

const carOemsFetcher: Fetcher<OEM[], readonly [key: 'oems']> = async () => {
  const apiClient = getApiClient();
  const oems = await apiClient.oems.getAll();
  return oems;
};

export const useOEMs = () => {
  const { data: oems } = useSWR(['oems'], carOemsFetcher, {});
  return { oems };
};

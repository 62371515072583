import type { GridFilterModel } from '@mui/x-data-grid-premium';
import { useCallback, useMemo, useState } from 'react';

type UseGridFilterModel = {
  initialState?: GridFilterModel;
  quickFilterPlaceHolder?: string;
};

export const mergeFilterModel = (
  prevFilterModel: GridFilterModel,
  nextFilterModel: Partial<GridFilterModel>,
): GridFilterModel => {
  const items = nextFilterModel.items
    ? [...nextFilterModel.items, ...prevFilterModel.items].reduce(
        (acc, item) => {
          if (acc.some((i) => i.id === item.id && i.field === item.field)) {
            return acc;
          }
          return [...acc, item];
        },
        [] as GridFilterModel['items'],
      )
    : prevFilterModel.items;

  return { ...prevFilterModel, ...nextFilterModel, items };
};

export const useGridFilterModel = (
  { initialState = { items: [] }, quickFilterPlaceHolder = 'Search...' }: UseGridFilterModel = {
    initialState: { items: [] },
    quickFilterPlaceHolder: 'Search...',
  },
) => {
  const [filterModel, setFilterModel] = useState(initialState);
  const handleSearchValueChange = useCallback((value: string) => {
    setFilterModel((prev) =>
      mergeFilterModel(prev, {
        quickFilterValues: value.split(' '),
      }),
    );
  }, []);

  const quickFilterProps = useMemo(() => {
    return {
      searchValue: filterModel.quickFilterValues?.join(' ') ?? '',
      handleSearchValueChange,
      placeholder: quickFilterPlaceHolder,
    };
  }, [filterModel, handleSearchValueChange, quickFilterPlaceHolder]);

  return useMemo(
    () => ({
      filterModel,
      setFilterModel,
      quickFilterProps,
    }),
    [filterModel, quickFilterProps],
  );
};

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useContext, useState } from 'react';

import { selectedInvoiceIdKey } from '~/components/global/constants';
import { UploadDocumentType } from '~/components/uploadDocument/AddManuallyDropdown';
import { UploadDocumentDialogFlowContext } from '~/components/uploadDocument/UploadDocumentDialogFlow';
import { useLAINavigate } from '~/hooks/useLAINavigate';

/** TODO: Move icons to correct place */
const CheckmarkIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00008 0.666504C4.40008 0.666504 0.666748 4.39984 0.666748 8.99984C0.666748 13.5998 4.40008 17.3332 9.00008 17.3332C13.6001 17.3332 17.3334 13.5998 17.3334 8.99984C17.3334 4.39984 13.6001 0.666504 9.00008 0.666504ZM7.33342 13.1665L3.16675 8.99984L4.34175 7.82484L7.33342 10.8082L13.6584 4.48317L14.8334 5.6665L7.33342 13.1665Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

const TicketIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2E7D32" fillOpacity="0.12" />
      <path
        d="M26 25H14V23H26V25ZM26 21H14V19H26V21ZM26 17H14V15H26V17ZM11 30L12.5 28.5L14 30L15.5 28.5L17 30L18.5 28.5L20 30L21.5 28.5L23 30L24.5 28.5L26 30L27.5 28.5L29 30V10L27.5 11.5L26 10L24.5 11.5L23 10L21.5 11.5L20 10L18.5 11.5L17 10L15.5 11.5L14 10L12.5 11.5L11 10V30Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

const InvoiceIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2E7D32" fillOpacity="0.12" />
      <path
        d="M13.5 30C13.1 30 12.75 29.85 12.45 29.55C12.15 29.25 12 28.9 12 28.5V11.5C12 11.1 12.15 10.75 12.45 10.45C12.75 10.15 13.1 10 13.5 10H22.525L28 15.475V28.5C28 28.9 27.85 29.25 27.55 29.55C27.25 29.85 26.9 30 26.5 30H13.5ZM19.25 26.975H20.75V25.975H22.25C22.4667 25.975 22.6458 25.9042 22.7875 25.7625C22.9292 25.6208 23 25.4417 23 25.225V21.975C23 21.7583 22.9292 21.5792 22.7875 21.4375C22.6458 21.2958 22.4667 21.225 22.25 21.225H18.5V19.475H23V17.975H20.75V16.975H19.25V17.975H17.75C17.5333 17.975 17.3542 18.0458 17.2125 18.1875C17.0708 18.3292 17 18.5083 17 18.725V21.975C17 22.1917 17.0708 22.3708 17.2125 22.5125C17.3542 22.6542 17.5333 22.725 17.75 22.725H21.5V24.475H17V25.975H19.25V26.975ZM21.9 15.475H25.875L21.9 11.5V15.475Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

const SupportingDataIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2E7D32" fillOpacity="0.12" />
      <path
        d="M16 10C14.9 10 14 10.9 14 12V24C14 25.1 14.9 26 16 26H28C29.1 26 30 25.1 30 24V12C30 10.9 29.1 10 28 10H16ZM28 24H16V12H28V24ZM23.67 18.83L21.19 21.92L19.5 19.67L17 23H27L23.67 18.83ZM12 30H26V28H12V14H10V28C10 29.1 10.9 30 12 30Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

const getIcon = (documentType: string) => {
  switch (documentType) {
    case 'Invoice':
      return <InvoiceIcon />;
    case 'Ticket':
      return <TicketIcon />;
    case 'Supporting Data':
      return <SupportingDataIcon />;
    case 'Certificate':
      return <TicketIcon />;
  }
};

export type DocumentFlowAddedDoc =
  | {
      number: string;
      documentType: UploadDocumentType.ticket | UploadDocumentType.supportingData;
      propertyName: string;
    }
  | {
      id: string;
      number: string;
      documentType: UploadDocumentType.proposal | UploadDocumentType.invoice;
      propertyName: string;
    }
  | {
      number: string;
      documentType: UploadDocumentType.certificate;
      propertyName: string;
      carId: string;
    };

interface ISuccessDocumentDialogProps {
  onClose: () => void;
  documentsAdded: DocumentFlowAddedDoc[];
}

const SuccessDocumentDialog: React.FC<ISuccessDocumentDialogProps> = ({
  onClose,
  documentsAdded,
}) => {
  const { navigate } = useLAINavigate();
  const { start } = useContext(UploadDocumentDialogFlowContext);
  const [open, setOpen] = useState(true);

  const reviewInvoiceHandler = (id: string) => {
    setOpen(false);
    navigate(
      { pathname: '/spending', search: `?${selectedInvoiceIdKey}=${id}` },
      { paramsToKeep: [] },
    );
  };

  const uploadAnotherHandler = () => {
    setOpen(false);
    start();
  };

  const reviewCertificateHandler = (carId: string) => {
    setOpen(false);
    navigate(
      {
        pathname: '/compliance',
        search: `?carId=${carId}&car_panel=open`,
      },
      { paramsToKeep: [] },
    );
  };

  const getActionButton = (doc: DocumentFlowAddedDoc) => {
    switch (doc.documentType) {
      case UploadDocumentType.invoice:
      case UploadDocumentType.proposal:
        return (
          <Button variant="contained" color="info" onClick={() => reviewInvoiceHandler(doc.id)}>
            {`Review ${doc.documentType}`}
          </Button>
        );
      case UploadDocumentType.certificate:
        return (
          <Button
            variant="contained"
            color="info"
            onClick={() => reviewCertificateHandler(doc.carId)}
          >
            VIEW ELEVATOR
          </Button>
        );
      default:
        return (
          <Button variant="contained" color="info">
            {`${doc.documentType}`}
          </Button>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" justifyContent="flex-end">
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid>
            <img
              src="/images/documents-added-successfully.png"
              alt="Documents added illustration"
              style={{
                display: 'block',
                maxHeight: '240px',
                maxWidth: '100%',
                objectFit: 'contain',
                margin: '0 auto',
              }}
            />
          </Grid>
          <Grid>
            <Typography variant="h5">Documents added successfully</Typography>
          </Grid>
          <Grid>
            <Table>
              <TableBody>
                {documentsAdded.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{getIcon(doc.documentType)}</TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" display="flex" alignItems="center">
                        <CheckmarkIcon />
                        <Box ml={1}>{doc.documentType} Added</Box>
                      </Typography>
                      <Typography variant="body2">
                        #{doc.number} - <span>{doc?.propertyName} </span>
                      </Typography>
                    </TableCell>
                    <TableCell>{getActionButton(doc)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid>
            <Button variant="outlined" color="info" onClick={uploadAnotherHandler}>
              Upload Another Document
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default SuccessDocumentDialog;

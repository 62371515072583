import { zodResolver } from '@hookform/resolvers/zod';
import type {
  API_Certificate,
  API_CertificateCreateRequest,
  PropertyForm,
} from '@liftai/asset-management-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo, useRef } from 'react';
import { useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { z } from 'zod';

import {
  associateFileToEntity,
  getFilesAssociatedToEntity,
  updateAssociationFileToEntity,
} from '~/components/utils/upload';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';
import type {
  LAIUseFormReturn,
  LiftAIDocumentTagged,
  LiftAIFormProps,
} from '../uploadDocument/SelectDocumentTypeDialog';

interface CertificateFormData extends Omit<API_CertificateCreateRequest, 'attachment_id'> {
  property_id: string;
}

export const certificateFormSchema = z
  .object({
    jurisdiction: z.string().min(1, { message: 'Jurisdiction is required' }),
    date_issued: z.string().min(1, { message: 'Issue date is required' }),
    expiration_date: z.string().min(1, { message: 'Expiration date is required' }),
    property_id: z.string().min(1, { message: 'Property is required' }),
    car_id: z.string().min(1, { message: 'Car is required' }),
  })
  .refine(
    (data) => {
      const issueDate = new Date(data.date_issued);
      const expirationDate = new Date(data.expiration_date);
      return expirationDate > issueDate;
    },
    {
      message: 'Expiration date must be after the issue date',
      path: ['expiration_date'],
    },
  );

const certificateOptionsFetcher = async (): Promise<{ properties: PropertyForm[] }> => {
  const apiClient = getApiClient();
  const properties = await apiClient.properties.getAll({ variant: 'form' });
  return { properties };
};

export const CertificateFields = () => {
  const { control, watch, setValue, getValues } = useFormContext<CertificateFormData>();
  const propertyId = watch('property_id');
  // const inspectionType = watch('inspection_type');
  const [searchParams] = useSearchParams();
  const propertyIdFromParams = searchParams.get('propertyId');
  const carIdFromParams = searchParams.get('carId');

  const { data } = useSWR('CertificateForm.Options', certificateOptionsFetcher);
  const { cars, loading: carsLoading } = usePropertyCars(propertyIdFromParams || propertyId);

  const initialized = useRef(false);

  useEffect(() => {
    if (
      !initialized.current &&
      data?.properties &&
      cars &&
      propertyIdFromParams &&
      carIdFromParams
    ) {
      const propertyExists = data.properties.some((p) => p.id === propertyIdFromParams);
      const carExists = cars.some((c) => c.id === carIdFromParams);

      if (propertyExists && carExists) {
        setValue('property_id', propertyIdFromParams, { shouldDirty: false });
        setValue('car_id', carIdFromParams, { shouldDirty: false });
        initialized.current = true;
      }
    }
  }, [propertyIdFromParams, carIdFromParams, data?.properties, cars, setValue]);

  useEffect(() => {
    if (initialized.current) {
      initialized.current = false;
    }
  }, [propertyIdFromParams, carIdFromParams]);

  const formFields: FieldDef[] = useMemo(
    () => [
      {
        name: 'property_id',
        label: 'Property',
        type: 'autocomplete' as const,
        options:
          data?.properties?.map((property) => ({
            id: property.id,
            label: property.name,
          })) ?? [],
        loading: !data?.properties,
        required: true,
        gridProps: { xs: 12 },
        autoCompleteProps: {
          value: getValues('property_id') || '',
          isOptionEqualToValue: (option: { id: string; label: string }, value: string) => {
            return option.id === value;
          },
        },
      },
      {
        name: 'car_id',
        label: 'Car',
        type: 'autocomplete',
        options:
          cars?.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          })) ?? [],
        loading: carsLoading,
        required: true,
        gridProps: { xs: 12 },
        autoCompleteProps: {
          value: getValues('car_id') || '',
          isOptionEqualToValue: (option: { id: string; label: string }, value: string) => {
            return option.id === value;
          },
        },
      },
      {
        name: 'jurisdiction',
        label: 'Jurisdiction',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'date_issued',
        label: 'Date Issued',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'expiration_date',
        label: 'Expiration Date',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
    ],
    [data?.properties, cars, carsLoading, getValues],
  );

  return (
    <>
      <Typography variant="h6" my={2}>
        Certificate Details
      </Typography>
      <Grid container spacing={2}>
        {formFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};

export const CertificateForm: React.FC<LiftAIFormProps<API_CertificateCreateRequest>> = ({
  children,
  document,
  onSubmit,
  initialData,
}) => {
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, reset } = useFormContext<CertificateFormData>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset({ ...initialData, property_id: '' });
  }, [initialData, reset]);

  const updateReferenceToFiles = async (
    certificate: API_Certificate,
    document: LiftAIDocumentTagged,
  ) => {
    if (certificate.id) {
      const attachments = await getFilesAssociatedToEntity(certificate.id, 'certificate');

      await Promise.all(
        attachments
          .filter((attachment) => attachment.isPrimary)
          .map((attachment) =>
            updateAssociationFileToEntity(attachment.attachment, certificate.id, 'certificate', {
              id: attachment.id,
              isVoid: true,
            }),
          ),
      );
    }

    await associateFileToEntity(
      document.attachment.id,
      certificate.id,
      'certificate',
      document.fileType === 'pdf'
        ? {
            pageNumber: document.pageNum,
            isPrimary: true,
          }
        : {},
    );
  };

  const saveCertificate = async (dto: API_CertificateCreateRequest): Promise<API_Certificate> => {
    const apiClient = getApiClient();
    const certificate = await apiClient.certificates.create(dto);
    return certificate;
  };

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data) => {
          try {
            const validatedData = certificateFormSchema.parse(data);
            const { property_id, ...apiRequest } = validatedData;

            const certificate = await saveCertificate({
              ...apiRequest,
              attachment_id: document?.attachment.id ?? null,
            });

            if (document) {
              await updateReferenceToFiles(certificate, document);
            }

            onSubmit(certificate);

            void mutate((key) => {
              if (!Array.isArray(key)) return false;
              const [keyType, keySubType, keyId] = key;

              if (keyType === 'certificate' && typeof keySubType === 'object') return true;
              if (keyType === 'certificate' && keySubType === 'car' && keyId === apiRequest.car_id)
                return true;
              if (keyType === 'compliance') return true;
              if (keyType === 'compliance' && keySubType === 'car' && keyId === apiRequest.car_id)
                return true;

              return false;
            });

            showEntityActionSnackbar(
              {
                name: 'Certificate',
                action: ActionEnum.Create,
                id: validatedData.car_id,
              },
              {
                variant: 'success',
              },
            );
          } catch (err) {
            console.error('Certificate form error:', err);
            showSnackbar('Certificate failed to save', {
              variant: 'error',
            });
          }
        },
        (err) => {
          console.log('Certificate validation error:', err);
        },
      )}
    >
      {children}
    </form>
  );
};

function useCertificateForm(): LAIUseFormReturn<API_CertificateCreateRequest> {
  const hookForm = useForm<CertificateFormData>({
    mode: 'all',
    resolver: zodResolver(certificateFormSchema),
    defaultValues: {
      jurisdiction: '',
      date_issued: '',
      expiration_date: '',
      property_id: '',
      car_id: '',
    },
  });

  return {
    hookForm: hookForm as unknown as UseFormReturn<API_CertificateCreateRequest>,
    Form: CertificateForm,
    Fields: CertificateFields,
  };
}

export { useCertificateForm };

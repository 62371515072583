import useSWR from 'swr';

import { getApiClient } from '~/utils/api';

const serviceProviderFetcher = async (serviceProviderId: string) => {
  const apiClient = getApiClient();
  return await apiClient.providers.getById(serviceProviderId);
};

export const useServiceProvider = (serviceProviderId: string | null) => {
  const { data, error, isLoading } = useSWR(
    serviceProviderId ? ['serviceProvider', serviceProviderId] : null,
    () => serviceProviderFetcher(serviceProviderId as string),
  );

  return { data, error, isLoading };
};

import { validateMaskHHmm } from '@liftai/asset-management-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import type { CheckboxProps, SelectProps, TextFieldProps } from '@mui/material';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { isEmpty } from 'lodash-es';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import LineItems from '../invoices/LineItems';

interface FieldOption {
  id: string;
  label: string;
}

interface FieldDefBase<FieldProps = {}> {
  gridProps?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
  fieldProps?: Omit<FieldProps, 'type' | 'name' | 'label' | 'required' | 'value'>;
  type: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
}

interface TextFieldDef extends FieldDefBase<TextFieldProps> {
  type:
    | 'text'
    | 'datetime-local'
    | 'date'
    | 'number'
    | 'time'
    | 'text-multiline'
    | 'line-items'
    | 'currency';
  label?: string;
}

interface SelectFieldDef extends FieldDefBase<SelectProps> {
  type: 'select' | 'multiple-select';
  options: FieldOption[];
  label?: string;
}

// TODO: Add support for autocomplete props
interface AutocompleteFieldDef extends FieldDefBase<TextFieldProps> {
  type: 'autocomplete' | 'autocomplete-multiple';
  options: FieldOption[];
  loading?: boolean;
  label: string;
  onInputChange?: (value: string) => void;
}

interface CheckboxFieldDef extends FieldDefBase<CheckboxProps> {
  type: 'checkbox';
  label: string;
}

interface SpacerFieldDef extends FieldDefBase {
  type: 'spacer';
}

export type FieldDef =
  | TextFieldDef
  | SelectFieldDef
  | AutocompleteFieldDef
  | SpacerFieldDef
  | CheckboxFieldDef;

/** COMMENT: Mask the input to HH:MM
 *  as MUI TimePicker is not currently stable.
 *  TODO: Extend it to where it limits to 23:59
 */
function maskString(value: string) {
  if (validateMaskHHmm(value)) {
    return value;
  }

  const onlyDigits = value.replace(/[^0-9]/g, '');
  if (onlyDigits.length > 5) {
    return onlyDigits.slice(0, 5);
  }

  if (onlyDigits.length === 4) {
    return `${onlyDigits.slice(0, 2)}:${onlyDigits.slice(2, 4)}`;
  }

  if (onlyDigits.length === 3) {
    return `0${onlyDigits.slice(0, 1)}:${onlyDigits.slice(1, 3)}`;
  }

  if (onlyDigits.length === 2) {
    return `00:${onlyDigits}`;
  }

  if (onlyDigits.length === 1) {
    return `00:0${onlyDigits}`;
  }
}

const Field = <T extends FieldValues>({
  field,
  control,
}: {
  field: FieldDef;
  control: Control<T>;
}) => {
  // Default to required if not specified
  const required = typeof field.required === 'undefined' ? true : field.required;
  const disabled = typeof field.disabled === 'undefined' ? false : field.disabled;

  switch (field.type) {
    case 'text':
    case 'text-multiline':
    case 'currency':
    case 'number':
    case 'datetime-local':
    case 'date': {
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                name={field.name}
                type={field.type === 'currency' ? 'number' : field.type}
                value={value ?? ''}
                onBlur={onBlur}
                onChange={
                  field.type === 'number' || field.type === 'currency'
                    ? (e) =>
                        onChange(
                          e.target.value !== '' ? parseFloat(e.target.value) : e.target.value,
                        )
                    : onChange
                }
                label={field.label}
                required={required}
                disabled={disabled}
                {...(field.type === 'text-multiline' ? { multiline: true, rows: 5 } : {})}
                fullWidth
                size="small"
                slotProps={{
                  inputLabel:
                    field.type === 'date' || field.type === 'datetime-local'
                      ? { shrink: true }
                      : {},
                }}
                {...(field.type === 'currency'
                  ? {
                      inputProps: {
                        min: 0,
                        step: 0.01,
                        role: 'textbox',
                      },
                      InputProps: {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      },
                    }
                  : {
                      inputProps: {
                        role: 'textbox',
                      },
                    })}
                error={typeof error !== 'undefined'}
                helperText={error?.message}
                {...field.fieldProps}
                defaultValue={
                  field.type === 'currency' ? undefined : field.fieldProps?.defaultValue
                }
              />
            );
          }}
        />
      );
    }

    case 'time': {
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              // CAUTION: Don't try use replace this with TimePicker, too many bugs we encountered around validaiton
              // may be fixed in MUI-X 6.0
              <TextField
                name={field.name}
                type={'text'}
                value={value ?? ''}
                onBlur={() => {
                  if (isEmpty(value)) {
                    onChange('');
                    onBlur();
                    return;
                  }
                  const finalVal = maskString(value);
                  onChange(finalVal);
                  onBlur();
                }}
                onChange={(ev) => {
                  onChange(ev);
                }}
                label={field.label}
                required={required}
                disabled={disabled}
                fullWidth
                size="small"
                error={typeof error !== 'undefined'}
                helperText={error?.message}
                placeholder="hh:mm"
              />
            );
          }}
        />
      );
    }
    case 'autocomplete': {
      const optionLabels = new Map(field.options.map(({ id, label }) => [id, label]));
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <Autocomplete
                value={typeof value === 'number' ? value.toString() : value ? value : ''}
                onBlur={onBlur}
                onChange={(event, option) => {
                  onChange(option);
                }}
                onInputChange={(_, value) => {
                  if (field.onInputChange) {
                    field.onInputChange(value);
                  }
                }}
                noOptionsText="No options"
                loading={field.loading}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    value={value ?? ''}
                    label={field.label}
                    name={field.name}
                    required={required}
                    error={typeof error !== 'undefined'}
                    helperText={error?.message}
                  />
                )}
                disabled={disabled}
                fullWidth
                options={field.loading ? [] : field.options.map(({ id }) => id)}
                getOptionLabel={(id) => (field.loading ? '' : optionLabels.get(id) ?? '')}
                size="small"
              />
            );
          }}
        />
      );
    }

    case 'autocomplete-multiple': {
      const optionLabels = new Map(field.options.map(({ id, label }) => [id, label]));
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <Autocomplete
                value={value ?? []}
                onBlur={onBlur}
                onChange={(event, option) => {
                  onChange(option);
                }}
                onInputChange={(_, value) => {
                  if (field.onInputChange) {
                    field.onInputChange(value);
                  }
                }}
                noOptionsText="No options"
                loading={field.loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={field.label}
                    name={field.name}
                    error={typeof error !== 'undefined'}
                    helperText={error?.message}
                  />
                )}
                disabled={disabled}
                fullWidth
                multiple
                options={field.loading ? [] : field.options.map(({ id }) => id)}
                getOptionLabel={(id) => (field.loading ? '' : optionLabels.get(id) ?? '')}
                size="small"
                disableCloseOnSelect
                renderOption={(props, id, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        name={field.name}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        value={id}
                      />
                      {optionLabels.get(id) ?? ''}
                    </li>
                  );
                }}
              />
            );
          }}
        />
      );
    }
    case 'select':
    case 'multiple-select': {
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <FormControl fullWidth size="small" required={required} disabled={disabled}>
                <InputLabel id={`input-${field.name}-label`}>{field.label} </InputLabel>
                <Select
                  name={field.name}
                  value={value ?? (field.type === 'select' ? '' : [])}
                  onBlur={onBlur}
                  onChange={onChange}
                  labelId={`input-${field.name}-label`}
                  id={`input-${field.name}`}
                  label={field.label}
                  error={typeof error !== 'undefined'}
                  fullWidth
                  multiple={field.type === 'multiple-select'}
                  {...field.fieldProps}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {typeof error !== 'undefined' ? (
                  <FormHelperText>{error.message}</FormHelperText>
                ) : null}
              </FormControl>
            );
          }}
        />
      );
    }
    case 'checkbox': {
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          // TODO figure out how to add error and helper text. Currently we don't have required checkboxes
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FormControlLabel
                label={field.label}
                control={
                  <Checkbox
                    name={field.name}
                    checked={value}
                    onChange={onChange}
                    disabled={disabled}
                    value={value}
                    {...field.fieldProps}
                  />
                }
              />
            );
          }}
        />
      );
    }
    case 'spacer': {
      return null;
    }
    case 'line-items': {
      return (
        <Controller
          key={field.name}
          name={field.name as Path<T>}
          control={control}
          render={({ fieldState: { error } }) => {
            return (
              <>
                <LineItems fieldDef={field} control={control} />
                <Typography variant="caption" color="error">
                  {error?.message}
                </Typography>
              </>
            );
          }}
        />
      );
    }
  }
};

export const FieldAndCell = <T extends FieldValues>({
  field,
  control,
}: {
  field: FieldDef;
  control: Control<T>;
}) => {
  return field.type === 'spacer' ? (
    <Grid size={{ xs: 6 }} />
  ) : (
    <Grid size={{ ...(field.gridProps ?? { xs: 12, sm: 6 }) }}>
      <Field field={field} control={control} />
    </Grid>
  );
};

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { endOfMonth, endOfQuarter, format, isSameMonth, parse, startOfMonth } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

import { getDefaultMonthRange } from '~/components/utils/dateUtils';

import Day from './Day';
import getQuartersItems from './getQuartersItems';
import type { SelectedDateRange } from './types';

type MonthRangePickerProps = {
  placeHolder: string;
  onSelect: (selectedRage: SelectedDateRange) => void;
  value?: SelectedDateRange;
};

export const parseYearMonthDay = (dateStr: string): Date => {
  const date = parse(dateStr, 'yyyy-MM-dd', new Date());
  return date;
};

export const lastDateCurrentQuarter = (): Date => {
  const today = new Date();
  return endOfQuarter(today);
};

function MonthRangePicker({ placeHolder, onSelect, value }: MonthRangePickerProps) {
  const defaultDateRange = getDefaultMonthRange();

  const initialSelectedDateRange: SelectedDateRange = value ?? { start: null, end: null };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<SelectedDateRange>(initialSelectedDateRange);
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    setSelectedDateRange({
      start: defaultDateRange.start,
      end: defaultDateRange.end,
    });
    onSelect({
      start: defaultDateRange.start,
      end: defaultDateRange.end,
    });
    handleClose();
  };

  const handleAcceptBtn = () => {
    onSelect(selectedDateRange);
    handleClose();
  };

  const handleChange = (value: [start: Date | null, end: Date | null]) => {
    const dateRange = {
      start: value[0],
      end: value[1],
    };

    if (!dateRange || !dateRange.start) {
      setSelectedDateRange(initialSelectedDateRange);
      return;
    }

    if (dateRange.start && !dateRange.end) {
      // Calculate both dateRange with the first selected date
      setSelectedDateRange({
        start: startOfMonth(dateRange.start),
        end: endOfMonth(dateRange.start),
      });
      return;
    }

    if (!dateRange.end) {
      // This should never happen, but avoid typescript warning
      return;
    }

    setSelectedDateRange({
      start: startOfMonth(dateRange.start),
      end: endOfMonth(dateRange.end),
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dateRangeText = useMemo(() => {
    if (!selectedDateRange.start || !selectedDateRange.end) {
      return '';
    }

    const formattedStartDate = selectedDateRange.start
      ? format(selectedDateRange.start, 'MMM yyyy')
      : '';

    // Return only the month
    if (isSameMonth(selectedDateRange.start, selectedDateRange.end)) {
      return formattedStartDate;
    }

    const formattedEndDate = selectedDateRange.end ? format(selectedDateRange.end, 'MMM yyyy') : '';

    // Return the range
    if (formattedStartDate && formattedEndDate) {
      return `${formattedStartDate} to ${formattedEndDate}`;
    }

    return '';
  }, [selectedDateRange]);

  useEffect(() => {
    if (value) {
      setSelectedDateRange(value);
    }
  }, [value]);

  const componentValue = useMemo(() => {
    return [selectedDateRange.start, selectedDateRange.end] as [Date | null, Date | null];
  }, [selectedDateRange]);

  return (
    <>
      <Button
        id="date-range-picker"
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        sx={{
          whiteSpace: 'nowrap',
          border: 'none',
          borderRadius: '4px',
          borderColor: 'rgba(0, 0, 0, .23)',
          color: 'rgba(0, 0, 0, .87)',
          textTransform: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
          padding: '4px',
          fontSize: '14px',
          ':hover': {
            border: 'none !important',
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
          },
        }}
      >
        {dateRangeText ? (
          <>
            <Box sx={{ color: 'rgba(0, 0, 0, .6)', paddingRight: '5px', fontWeight: '400' }}>
              {placeHolder}
            </Box>
            <Box sx={{ paddingRight: '5px' }}>{dateRangeText}</Box>
          </>
        ) : (
          placeHolder
        )}
        <ArrowDropDownIcon sx={{ color: 'rgba(0, 0, 0, .54)' }} />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            slots={{
              day: Day,
            }}
            slotProps={{
              shortcuts: {
                subheader: 'Quick filters',
                items: getQuartersItems(),
                sx: { gridRow: '1/3 !important', paddingLeft: '10px' },
              },
              actionBar: {
                actions: [],
              },
              toolbar: {
                toolbarFormat: 'MMM yyyy',
              },
              day: (ownerState) => ({
                selectedDay: selectedDateRange,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
            showDaysOutsideCurrentMonth
            calendars={1}
            maxDate={lastDateCurrentQuarter()}
            value={componentValue}
            onChange={handleChange}
            sx={{ maxHeight: '440px' }}
          />
        </LocalizationProvider>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          sx={{ paddingRight: '10px', paddingBottom: '10px', paddingLeft: '10px' }}
        >
          <Button variant="outlined" onClick={handleClear}>
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={handleAcceptBtn}
            data-testid="popover-content-ok-btn"
          >
            Filter
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default MonthRangePicker;

import { z } from 'zod';
// Schema for basic violation note
export const violationNoteSchema = z.object({
    id: z.string(),
    userEmail: z.string(),
    note: z.string(),
    createdAt: z.string(),
});
// Schema for violation note metadata
export const violationNoteMetadataSchema = z.object({
    totalNotes: z.number(),
    notesLast24h: z.number(),
    notesLast7d: z.number(),
    notesByUser: z.record(z.number()),
    notesWithoutUpdates: z.number(),
});
// Schema for the full response containing notes and metadata
export const violationNotesResponseSchema = z.object({
    metadata: violationNoteMetadataSchema,
    results: z.array(violationNoteSchema),
});
// Schema for create note request
export const createViolationNoteRequestSchema = z.object({
    violation: z.string(),
    note: z.string(),
});
// Schema for create note response
export const createViolationNoteResponseSchema = z.object({
    id: z.string(),
    violation: z.string(),
    violationCode: z.string(),
    user: z.object({
        id: z.string(),
        username: z.string(),
        fullName: z.string(),
        email: z.string(),
        phoneNumber: z.string(),
    }),
    note: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
});

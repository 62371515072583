import { Box } from '@mui/material';

import ToggleButtonGroup from '~/components/common/ToggleButtonGroup';
import { SpendingTable } from '~/components/spending/SpendingTable';
import { useSpending, useSpendingTableModel } from '~/data/hooks/useSpending';

type PropertySpendingProps = {
  propertyId: string;
};

export function PropertySpending({ propertyId }: PropertySpendingProps) {
  const {
    spendingView,
    onViewChange,
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
    spendingViewList,
  } = useSpendingTableModel();

  const { invoices, numInvoices, isLoading, onExport, onFilterModelChange } = useSpending({
    propertyId,
    paginationModel,
    filterModel,
    sortModel,
    onFilterModelChange: setFilterModel,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" justifyContent="space-between">
        <ToggleButtonGroup
          activeView={spendingView}
          viewList={spendingViewList}
          handleChange={onViewChange}
        />
      </Box>
      <SpendingTable
        invoices={invoices}
        numInvoices={numInvoices}
        isLoading={isLoading}
        onExport={onExport}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={onFilterModelChange}
        paginationModel={paginationModel}
        filterModel={filterModel}
        sortModel={sortModel}
        hiddenColumns={['property', 'portfolio']}
        enableQuickFilter
        canFilterProperty={false}
      />
    </Box>
  );
}

import {
  Contract,
  ContractCustomDetails,
  ContractHourlyRate,
  ContractReviewRule,
  ContractSummaryDetails,
} from '@liftai/asset-management-types';
import {
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid2 as Grid,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { TabDefinition, TabPanel } from '~/components/common/tabs';
import useTabs, { TabsOptions } from '~/components/common/tabs/useTabs';
import {
  mapContractToFormValues,
  useContractDetailsForm,
} from '~/components/contracts/ContractDetailsForm';
import { useContractHourlyRatesForm } from '~/components/contracts/ContractHourlyRatesForm';
import { useContractReviewRulesForm } from '~/components/contracts/ContractReviewRulesForm';
import DialogTitleWithCloseButton from '~/components/DialogTitleWithCloseButton';
import usePropertyContracts from '~/data/hooks/usePropertyContracts';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

interface EditContractDetailsDialogProps {
  /**
   * Whether the dialog is open
   */
  open: boolean;

  /**
   * Contract details to edit
   */
  defaultValues: Contract & { customDetails?: ContractCustomDetails };
  /**
   * Callback to close the dialog
   */
  onClose: () => void;
}
enum Tab {
  General = 'GENERAL',
  HourlyRates = 'HOURLY RATES',
  ReviewRules = 'INVOICE REVIEW RULES',
}
const tabLabels = [Tab.General, Tab.HourlyRates, Tab.ReviewRules];
const useContractDetailsFormTabs = ({ defaultTab = Tab.General }: { defaultTab?: Tab } = {}) => {
  const tabsOpts: TabsOptions = {
    styles: {
      box: { borderColor: 'divider' },
      tabs: { backgroundColor: 'none', marginBottom: 3.5 },
    },
  };
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { tabStrip, tabs, selectedTab, selectTab } = useTabs(tabLabels, defaultTab, tabsOpts);
  const buildTabPanel = useCallback(
    (tab: TabDefinition) => {
      const component = (props: React.PropsWithChildren) => (
        <TabPanel tab={tab} hidden={selectedTab !== tab.tabId} {...props} />
      );
      component.displayName = `TabPanel(${tab.label})`;
      return component;
    },
    [selectedTab],
  );
  const tabsComponents = useMemo(
    () => ({
      GeneralTab: buildTabPanel(tabs[Tab.General]),
      HourlyRatesTab: buildTabPanel(tabs[Tab.HourlyRates]),
      ReviewRulesTab: buildTabPanel(tabs[Tab.ReviewRules]),
    }),
    [buildTabPanel, tabs],
  );
  useEffect(() => {
    if (defaultTab) {
      selectTab(defaultTab);
    }
  }, [defaultTab, selectTab]);
  useEffect(() => {
    if (selectedTab === 'tab-general') {
      setActiveTab(Tab.General);
    } else if (selectedTab === 'tab-hourlyrates') {
      setActiveTab(Tab.HourlyRates);
    } else if (selectedTab === 'tab-invoicereviewrules') {
      setActiveTab(Tab.ReviewRules);
    }
  }, [selectedTab]);

  return {
    tabStrip,
    tabs: tabsComponents,
    activeTab,
  };
};

const ContractGeneralSectionForm = ({
  defaultValues,
  onClose,
}: {
  defaultValues: Contract & { customDetails?: ContractCustomDetails };
  onClose: () => void;
}) => {
  const { Fields, Form, hookForm } = useContractDetailsForm();
  const initialData = useMemo(() => mapContractToFormValues(defaultValues), [defaultValues]);
  const { saveSummaryDetails, saveCustomDetails } = usePropertyContracts(
    defaultValues.property?.id,
  );
  const { showEntityActionSnackbar } = useLAISnackbar();
  const onSubmitContractDetails = async ({
    customDetails,
    ...contractData
  }: ContractSummaryDetails) => {
    try {
      await Promise.all([
        saveSummaryDetails(defaultValues.id, contractData),
        customDetails ? saveCustomDetails(defaultValues.id, customDetails) : Promise.resolve(),
      ]);
      showEntityActionSnackbar(
        {
          name: 'Contract information',
          action: ActionEnum.Update,
        },
        {
          variant: 'info',
          autoHideDuration: 7000,
        },
      );
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  return (
    <FormProvider {...hookForm}>
      <Grid maxWidth="md" container sx={{ flexGrow: 1 }}>
        <Form onSubmit={onSubmitContractDetails} initialData={initialData}>
          <Fields />
          {hookForm.formState.errors.root?.serverError ? (
            <FormHelperText sx={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
              {hookForm.formState.errors.root?.serverError.message}
            </FormHelperText>
          ) : null}
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: 1 }}
          >
            <Button type="button" variant="outlined" onClick={onClose}>
              close
            </Button>
            <Button type="submit" variant="contained">
              save
            </Button>
          </Grid>
        </Form>
      </Grid>
    </FormProvider>
  );
};

const ContractHourlyRatesSectionForm = ({
  onClose,
  defaultValues,
}: {
  defaultValues: Contract & { customDetails?: ContractCustomDetails };
  onClose: () => void;
}) => {
  const { Fields, Form, hookForm } = useContractHourlyRatesForm();
  const initialData = useMemo(() => ({ hourlyRates: defaultValues.hourlyRates }), [defaultValues]);
  const { saveContractHourlyRates, deleteContractHourlyRate } = usePropertyContracts(
    defaultValues.property?.id,
  );

  const { showEntityActionSnackbar } = useLAISnackbar();
  const onSubmitContractHourlyRates = async ({
    hourlyRates,
  }: {
    hourlyRates: ContractHourlyRate[];
  }) => {
    try {
      await saveContractHourlyRates(defaultValues.id, hourlyRates);
      showEntityActionSnackbar(
        {
          name: 'Contract information',
          action: ActionEnum.Update,
        },
        {
          variant: 'info',
          autoHideDuration: 7000,
        },
      );
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  const onDeleteHourlyRate = async (id: string) => {
    try {
      await deleteContractHourlyRate(defaultValues.id, id);
      showEntityActionSnackbar(
        {
          name: 'Contract information',
          action: ActionEnum.Update,
        },
        {
          variant: 'info',
          autoHideDuration: 7000,
        },
      );
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  return (
    <FormProvider {...hookForm}>
      <Grid maxWidth="md" container sx={{ flexGrow: 1 }}>
        <Form onSubmit={onSubmitContractHourlyRates} initialData={initialData}>
          <Fields onDelete={onDeleteHourlyRate} />
          {hookForm.formState.errors.root?.serverError ? (
            <FormHelperText sx={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
              {hookForm.formState.errors.root?.serverError.message}
            </FormHelperText>
          ) : null}
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: 1 }}
          >
            <Button type="button" variant="outlined" onClick={onClose}>
              close
            </Button>
            <Button type="submit" variant="contained">
              save
            </Button>
          </Grid>
        </Form>
      </Grid>
    </FormProvider>
  );
};

const ContractReviewRulesSectionForm = ({
  onClose,
  defaultValues,
}: {
  defaultValues: Contract & { customDetails?: ContractCustomDetails };
  onClose: () => void;
}) => {
  const { Fields, Form, hookForm } = useContractReviewRulesForm();
  const initialData = useMemo(() => ({ reviewRules: defaultValues.reviewRules }), [defaultValues]);
  const { saveContractReviewRules, deleteContractReviewRule } = usePropertyContracts(
    defaultValues.property?.id,
  );

  const { showEntityActionSnackbar } = useLAISnackbar();
  const onSubmitContractReviewRules = async ({
    reviewRules,
  }: {
    reviewRules: ContractReviewRule[];
  }) => {
    try {
      await saveContractReviewRules(defaultValues.id, reviewRules);
      showEntityActionSnackbar(
        {
          name: 'Contract information',
          action: ActionEnum.Update,
        },
        {
          variant: 'info',
          autoHideDuration: 7000,
        },
      );
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  const onDeleteReviewRule = async (id: string) => {
    try {
      await deleteContractReviewRule(defaultValues.id, id);
      showEntityActionSnackbar(
        {
          name: 'Contract information',
          action: ActionEnum.Update,
        },
        {
          variant: 'info',
          autoHideDuration: 7000,
        },
      );
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  return (
    <FormProvider {...hookForm}>
      <Grid maxWidth="md" container sx={{ flexGrow: 1 }}>
        <Form onSubmit={onSubmitContractReviewRules} initialData={initialData}>
          <Fields onDelete={onDeleteReviewRule} />
          {hookForm.formState.errors.root?.serverError ? (
            <FormHelperText sx={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
              {hookForm.formState.errors.root?.serverError.message}
            </FormHelperText>
          ) : null}
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: 1 }}
          >
            <Button type="button" variant="outlined" onClick={onClose}>
              close
            </Button>
            <Button type="submit" variant="contained">
              save
            </Button>
          </Grid>
        </Form>
      </Grid>
    </FormProvider>
  );
};

export const EditContractDetailsDialog: React.FC<EditContractDetailsDialogProps> = ({
  open,
  onClose,
  defaultValues,
}) => {
  const theme = useTheme();
  const {
    tabStrip,
    tabs: { GeneralTab, HourlyRatesTab, ReviewRulesTab },
  } = useContractDetailsFormTabs();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" aria-modal>
      <DialogTitleWithCloseButton onClose={onClose} title="Edit Contract Summary" />
      <DialogContent
        sx={{
          padding: theme.spacing(2),
          paddingTop: 0,
          overflow: 'scroll',
          height: '100%',
        }}
      >
        {tabStrip}
        <GeneralTab>
          <ContractGeneralSectionForm onClose={onClose} defaultValues={defaultValues} />
        </GeneralTab>
        <HourlyRatesTab>
          <ContractHourlyRatesSectionForm onClose={onClose} defaultValues={defaultValues} />
        </HourlyRatesTab>
        <ReviewRulesTab>
          <ContractReviewRulesSectionForm onClose={onClose} defaultValues={defaultValues} />
        </ReviewRulesTab>
      </DialogContent>
    </Dialog>
  );
};

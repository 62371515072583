import type { InvoiceWithTickets } from '@liftai/asset-management-types';
import { Drawer, Toolbar } from '@mui/material';
import { createContext, useCallback, useEffect, useReducer } from 'react';

import InvoiceDetailContainer from '~/components/invoices/InvoiceDetailContainer';
import PendingInvoices from '~/components/invoices/PendingInvoices';
import useInvoice from '~/data/hooks/useInvoice';
import useLAISnackbar from '~/hooks/useLAISnackbar';

export const invoiceDetailDrawerWidth = '527px';
type InvoiceDetailFlowState =
  | { name: 'initial' }
  | { name: 'closed' }
  | { name: 'pending-invoices' }
  | { name: 'invoice-detail' };

interface IInvoiceDetailFlowState {
  state: InvoiceDetailFlowState;
  isOpen: boolean;
  showPendingInvoices: () => void;
  isShowingPendingInvoices: boolean;
  onInvoiceSelected: (invoiceId: string) => void;
  goBack(): void;
  close(): void;
}

export const InvoiceDetailDrawerFlowContext = createContext<IInvoiceDetailFlowState>({
  state: { name: 'closed' },
  isOpen: false,
  showPendingInvoices: () => {},
  isShowingPendingInvoices: false,
  onInvoiceSelected: (invoiceId: string) => {},
  goBack: () => {},
  close: () => {},
});

type InvoiceDetailDrawerAction =
  | { type: 'invoiceSidebar.show-invoice-detail' }
  | { type: 'invoiceSidebar.show-pending-invoices' }
  | { type: 'invoiceSidebar.go-back' }
  | { type: 'invoiceSidebar.close' };

// Create a reducer to handle the state transitions
const invoiceDetailReducer = (
  state: InvoiceDetailFlowState,
  action: InvoiceDetailDrawerAction,
): InvoiceDetailFlowState => {
  switch (action.type) {
    case 'invoiceSidebar.show-invoice-detail':
      return { name: 'invoice-detail' };
    case 'invoiceSidebar.show-pending-invoices':
      return { name: 'pending-invoices' };
    case 'invoiceSidebar.go-back':
      switch (state.name) {
        case 'invoice-detail':
          return { name: 'pending-invoices' };
        case 'pending-invoices':
          return { name: 'closed' };
        default:
          return state;
      }
    case 'invoiceSidebar.close':
      return { name: 'closed' };
  }
};

const useInvoiceDetailDrawerFlow = (
  selectedInvoiceId: string | null,
  parentOnInvoiceSelected: (invoiceId: string | null) => void,
): IInvoiceDetailFlowState => {
  const [state, dispatch] = useReducer(invoiceDetailReducer, {
    name: 'initial',
  });

  const goBack = useCallback(() => {
    dispatch({ type: 'invoiceSidebar.go-back' });
  }, []);

  const showPendingInvoices = useCallback(() => {
    dispatch({ type: 'invoiceSidebar.show-pending-invoices' });
  }, []);

  const onInvoiceSelected = useCallback(
    (invoiceId: string) => {
      parentOnInvoiceSelected(invoiceId);
      dispatch({ type: 'invoiceSidebar.show-invoice-detail' });
    },
    [parentOnInvoiceSelected],
  );

  const close = useCallback(() => {
    dispatch({ type: 'invoiceSidebar.close' });
    parentOnInvoiceSelected(null);
  }, [parentOnInvoiceSelected]);

  useEffect(() => {
    if (selectedInvoiceId) {
      dispatch({ type: 'invoiceSidebar.show-invoice-detail' });
    } else {
      dispatch({ type: 'invoiceSidebar.close' });
    }
  }, [selectedInvoiceId]);

  return {
    state,
    goBack,
    onInvoiceSelected,
    showPendingInvoices,
    isShowingPendingInvoices: state.name === 'pending-invoices',
    close,
    isOpen: state.name !== 'closed' && state.name !== 'initial',
  };
};

type InvoiceDetailDrawerProps = React.PropsWithChildren<{
  pendingInvoices?: InvoiceWithTickets[];
  selectedInvoiceId: string | null;
  onInvoiceSelected: (invoiceId: string | null) => void;
}>;

export const InvoiceDetailDrawerFlow: React.FC<InvoiceDetailDrawerProps> = ({
  children,
  pendingInvoices,
  selectedInvoiceId,
  onInvoiceSelected,
}) => {
  const { showSnackbar } = useLAISnackbar();
  const invoiceDetailFlowProvider = useInvoiceDetailDrawerFlow(
    selectedInvoiceId,
    onInvoiceSelected,
  );
  const { state, close } = invoiceDetailFlowProvider;

  const { invoice } = useInvoice(selectedInvoiceId, {
    onError: (error) => {
      const message = error instanceof Error ? error.message : 'Error fetching invoice';
      showSnackbar(message, {
        variant: 'error',
      });
      invoiceDetailFlowProvider.close();
    },
  });

  return (
    <InvoiceDetailDrawerFlowContext.Provider value={invoiceDetailFlowProvider}>
      {children}
      <Drawer
        open={state.name === 'invoice-detail' || state.name === 'pending-invoices'}
        variant="persistent"
        anchor="right"
        sx={{ width: invoiceDetailDrawerWidth, zIndex: (theme) => theme.zIndex.drawer }}
        PaperProps={{
          sx: { width: invoiceDetailDrawerWidth, boxSizing: 'border-box' },
        }}
      >
        {/* This is required to create space so that the drawer is not partially hidden by the AppBar */}
        <Toolbar />

        {state.name === 'invoice-detail' ? (
          <InvoiceDetailContainer onClose={() => close()} invoice={invoice} />
        ) : state.name === 'pending-invoices' ? (
          <PendingInvoices
            onInvoiceSelected={onInvoiceSelected}
            pendingInvoices={pendingInvoices}
            onClose={() => close()}
          />
        ) : null}
      </Drawer>
    </InvoiceDetailDrawerFlowContext.Provider>
  );
};

import z from 'zod';
import { portfolioKPIsByDateSchema, portfolioKPIsByPropertySchema, PortfolioKPIsSchema, } from './portfolioKPI';
export const apiPieChart = z.object({
    total: z.number(),
    data: z.array(z.tuple([z.string(), z.number()])),
});
///////////////////////////////////////////////////////////////////////////////
// Portfolios
export const API_PortfolioKPIsResponse = z.object({
    byProperty: z.array(portfolioKPIsByPropertySchema),
    byDate: z.array(portfolioKPIsByDateSchema),
    summary: PortfolioKPIsSchema,
    charts: z.object({
        ticketsByType: apiPieChart,
        invoiceSpendingsByType: apiPieChart,
        invoiceSavingsByType: apiPieChart,
    }),
});
export const API_PortfolioFilterSearchParamsSchema = z.object({
    portfolios: z.string().array().optional(),
    serviceProviders: z.string().array().optional(),
    regions: z.string().array().optional(),
    limit: z.string().pipe(z.coerce.number()).optional(),
    offset: z.string().pipe(z.coerce.number()).optional(),
    query: z.string().optional(),
});
export const API_PortfolioKpisFilterSearchParams = z.object({
    portfolios: z.string().array().optional(),
    serviceProviders: z.string().array().optional(),
    regions: z.string().array().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
});
///////////////////////////////////////////////////////////////////////////////
// Tickets
const localDateStringSchema = z
    .string()
    .min(10)
    .transform((val) => (val.length === 10 ? val + 'T00:00:00' : val))
    .transform((val) => new Date(val));
export const API_TicketFilterSearchParams = z.object({
    limit: z.number().optional(),
    offset: z.number().optional(),
    ordering: z.string().optional().default('-startedTime'),
    propertyName: z.string().optional(),
    property: z.string().optional(),
    carId: z.string().optional(),
    portfolios: z.string().array().optional(),
    serviceProviders: z.string().array().optional(),
    regions: z.string().array().optional(),
    entryType: z.string().array().optional(),
    search: z.string().optional(),
    startedTimeBefore: localDateStringSchema.optional(),
    startedTimeAfter: localDateStringSchema.optional(),
    number: z.string().optional(),
});
export const API_NumberFilterParams = z.object({
    eq: z.string().optional(),
    gt: z.string().optional(),
    gte: z.string().optional(),
    lt: z.string().optional(),
    lte: z.string().optional(),
});
export const API_InvoiceFilterSearchParams = z.object({
    limit: z.string().pipe(z.coerce.number()).optional(),
    offset: z.string().pipe(z.coerce.number()).optional(),
    ordering: z.string().optional().default('-date'),
    query: z.string().optional(),
    search: z.string().optional(),
    portfolio: z.string().array().optional(),
    tickets: z.string().optional(),
    serviceProvider: z.string().array().optional(),
    region: z.string().array().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
    startDateStamped: z.string().optional(),
    endDateStamped: z.string().optional(),
    number: z.string().optional(),
    type: z.string().array().optional(),
    kind: z.string().array().optional(),
    propertyName: z.string().optional(),
    property: z.string().optional(),
    status: z.string().array().optional(),
    variant: z.string().optional(),
    proposedAmount: API_NumberFilterParams.optional(),
    reviewedAmount: API_NumberFilterParams.optional(),
    savings: API_NumberFilterParams.optional(),
});
export { invoiceTableSchema as api_invoiceTableListResponse } from './api/invoices';
export { invoiceFormSchema as api_invoiceFormListResponse } from './api/invoices';
export { consultantGroupSchema as api_consultantGroupDetailSchema } from './api/consultantGroup';
export const API_EmbedUrlResponse = z.object({
    EmbedUrl: z.string(),
    RequestId: z.string(),
    Status: z.number(),
});
// Chat
export const API_ChatRequest = z.object({
    collection: z.enum(['contract']),
    entityId: z.string(),
    query: z.string(),
    metadata: z.object({}).optional(),
});
export const API_ChatResponse = z.object({
    answer: z.string(),
    runId: z.string(),
});

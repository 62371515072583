import type { CarInProperty } from '@liftai/asset-management-types';
import { callbackTypeValues, CarTypeOptions } from '@liftai/asset-management-types';
import { Edit } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-premium';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { UserContext } from '~/auth/userContext';
import Skeleton from '~/components/common/Skeleton';
import { carSearchParam } from '~/components/portfolio/PortfolioTabContainer';
import LiftAITable from '~/components/table';
import { colDefTemplate } from '~/components/table/colDefTemplate';
import { yesOrNo } from '~/components/utils/booleanToText';
import { useOEMs } from '~/data/hooks/useOEMs';
import { useServiceProvider } from '~/data/hooks/useServiceProvider';
import { useGridFilterModel } from '~/hooks/useGridFilterModel';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { formatNumber, formatPercent } from '~/utils/format';
import { canAccess, EDIT_ELEVATOR } from '~/utils/role';
import { buildUrlForPropertyTickets, urlToHref } from '~/utils/urlBuilder';

import PropertyEditCarDialog from './PropertyEditCarDialog';

const getTheCallbackMedianTimeLabel = (
  callbacks: number | null,
  callbackMedianTime: number | null,
) => {
  if (!callbacks || !callbackMedianTime) {
    return '-';
  }

  let dayLabel = 'days';
  if (callbackMedianTime === 1) {
    dayLabel = 'day';
  }
  return `${formatNumber(callbackMedianTime)} ${dayLabel}`;
};

const ServiceProviderCell = ({ row }: { row: CarInProperty }) => {
  const { data, isLoading } = useServiceProvider(row.serviceProviderId);
  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" height={'100%'}>
        <Skeleton sx={{ width: '100%', maxHeight: 35 }} />
      </Box>
    );
  }
  return data?.name ?? '-';
};

const buildCarColumns = ({
  propertyId,
  startDate,
  endDate,
  canEdit,
  getActionsEl,
}: {
  propertyId: string;
  startDate: string;
  endDate: string;
  canEdit: boolean;
  getActionsEl: (car: CarInProperty) => JSX.Element[];
}) => {
  const columns = colDefTemplate<CarInProperty>({
    options: {
      autoWidth: true,
    },
    cols: [
      {
        field: 'id',
        headerName: 'ID (internal use only)',
      },
      {
        field: 'ahjId',
        headerName: 'License',
      },
      {
        field: 'name',
        headerName: 'Unit Name',
      },
      {
        field: 'serviceProvider',
        headerName: 'Service Provider',
        renderCell: ({ row }) => {
          return <ServiceProviderCell row={row} />;
        },
      },
      {
        field: 'bank',
        headerName: 'Bank',
        renderCell: ({ row }) => {
          return row.bank ?? '-';
        },
      },
      {
        field: 'criticalUnit',
        headerName: 'Critical Unit',
        renderCell: ({ row }) => {
          return yesOrNo(row.criticalUnit);
        },
      },
      {
        field: 'type',
        headerName: 'Device Type',
        renderCell: ({ row }) => {
          return CarTypeOptions[row.elevatorType ?? 'Other'];
        },
      },
      {
        field: 'installationYear',
        headerName: 'Installation Year',
        renderCell: ({ row }) => {
          return row.installationYear ? row.installationYear : '-';
        },
      },
      {
        field: 'modernizationYear',
        headerName: 'Modernization Year',
        renderCell: ({ row }) => {
          return row.modernizationYear ? row.modernizationYear : '-';
        },
      },
      {
        field: 'maintenanceVisits',
        headerName: 'Maintenance Visits',
        renderCell: ({ row }) => {
          if (!row.maintenanceVisits) return '-';
          const url = buildUrlForPropertyTickets({
            startDate,
            endDate,
            propertyId,
            carId: row.id,
            entryType: ['visit'],
          });
          return (
            <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} href={urlToHref(url)}>
              {formatNumber(row.maintenanceVisits)}
            </Link>
          );
        },
      },
      {
        field: 'maintenanceCompletion',
        headerName: 'Maint. Completion',
        renderCell: ({ row }) => {
          if (!row.maintenanceCompletion) return '-';
          const url = buildUrlForPropertyTickets({
            startDate,
            endDate,
            propertyId,
            carId: row.id,
            entryType: ['visit'],
          });
          return (
            <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} href={urlToHref(url)}>
              {formatPercent(row.maintenanceCompletion)}
            </Link>
          );
        },
      },
      {
        field: 'callbacks',
        headerName: 'Callbacks',
        renderCell: ({ row }) => {
          if (!row.callbacks) return '-';
          const url = buildUrlForPropertyTickets({
            startDate,
            endDate,
            propertyId,
            carId: row.id,
            entryType: callbackTypeValues,
          });
          return (
            <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} href={urlToHref(url)}>
              {formatNumber(row.callbacks)}
            </Link>
          );
        },
      },
      {
        field: 'callbackRate',
        headerName: 'Callback Rate',
        renderCell: ({ row }) => {
          if (!row.callbackRate) return '-';
          const url = buildUrlForPropertyTickets({
            startDate,
            endDate,
            propertyId,
            carId: row.id,
            entryType: callbackTypeValues,
          });
          return (
            <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} href={urlToHref(url)}>
              {formatNumber(row.callbackRate)}
            </Link>
          );
        },
      },
      {
        field: 'callbackMedianTime',
        headerName: 'Callbacks Median Time',
        renderCell: ({ row }) => {
          const label = getTheCallbackMedianTimeLabel(row.callbacks, row.callbackMedianTime);
          if (label === '-') return label;
          const url = buildUrlForPropertyTickets({
            startDate,
            endDate,
            propertyId,
            carId: row.id,
            entryType: callbackTypeValues,
          });
          return (
            <Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} href={urlToHref(url)}>
              {label}
            </Link>
          );
        },
      },
      {
        field: 'numLandings',
        headerName: 'Number of landings',
      },
      { field: 'speedFpm', headerName: 'Speed (fpm)' },
      { field: 'capacityLbs', headerName: 'Capacity (lbs)' },
      { field: 'controllerModel', headerName: 'Controller Model' },
      { field: 'driveType', headerName: 'Drive Type' },
      { field: 'lastSspiTest', headerName: 'Last SSPI Test' },
      { field: 'numEntrances', headerName: 'Number of Entrances' },
      { field: 'oem', headerName: 'OEM' },
    ],
  });

  if (canEdit) {
    columns.push({
      field: 'actions',
      type: 'actions',
      getActions: ({ row: car }) => getActionsEl(car),
      width: 70,
      align: 'right',
    });
  }

  return columns;
};

export type PropertyCarsProps = { propertyId: string };
export const PropertyCars: React.FC<PropertyCarsProps> = ({ propertyId }) => {
  const [gridAction, setGridAction] = useState<{
    actionName: 'actionEdit';
    carId: string;
  } | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { filterModel, setFilterModel } = useGridFilterModel();

  const { cars = [], loading } = usePropertyCars(propertyId);

  const carId = searchParams.get(carSearchParam) ?? undefined;
  useEffect(() => {
    if (carId) {
      setFilterModel({
        items: [
          {
            field: 'id',
            value: carId,
            operator: 'equals',
          },
        ],
      });
    }
  }, [carId, setSearchParams, setFilterModel]);

  const clearGridAction = useCallback(() => {
    setGridAction(null);
  }, []);

  const apiRef = useGridApiRef();
  const startDate = searchParams.get('start_date') ?? '';
  const endDate = searchParams.get('end_date') ?? '';

  const {
    userDetails: { role: userRole },
  } = useContext(UserContext);
  const canEdit = canAccess(userRole, EDIT_ELEVATOR);
  const { oems } = useOEMs();

  const carToEdit = useMemo(() => {
    if (!gridAction) return null;
    const car = cars.find((car) => car.id === gridAction.carId);
    if (!car) return null;
    return { ...car, oem: oems?.find((oem) => oem.name === car.oem)?.id ?? null };
  }, [gridAction, cars, oems]);

  const getActionsEl = useCallback((car: CarInProperty): JSX.Element[] => {
    return [
      <GridActionsCellItem
        key="edit"
        icon={<Edit />}
        onClick={() => {
          if (!car.id) {
            console.error('Car has no ID');
            return;
          }
          setGridAction({ actionName: 'actionEdit', carId: car.id });
        }}
        label="Edit"
        showInMenu
      />,
    ];
  }, []);

  const carColumns = useMemo(
    () =>
      buildCarColumns({
        propertyId,
        startDate,
        endDate,
        canEdit,
        getActionsEl,
      }),
    [propertyId, startDate, endDate, canEdit, getActionsEl],
  );

  return (
    <>
      <LiftAITable<CarInProperty>
        apiRef={apiRef}
        groupingColDef={{ leafField: 'propertyManager' }}
        rows={cars}
        loading={loading}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              bank: false,
              capacityLbs: false,
              controllerModel: false,
              driveType: false,
              lastSspiTest: false,
              numEntrances: false,
              numLandings: false,
              oem: false,
              speedFpm: false,
            },
          },
        }}
        columns={carColumns}
        tableHeaderTitle="Cars"
        enableQuickFilter
        quickFilterPlaceHolder="Search cars..."
      />
      {carToEdit && canAccess(userRole, EDIT_ELEVATOR) ? (
        <PropertyEditCarDialog
          open
          onClose={clearGridAction}
          onSubmit={clearGridAction}
          defaultValues={carToEdit}
        />
      ) : null}
    </>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import type { Car, CarDto } from '@liftai/asset-management-types';
import { carDtoSchema } from '@liftai/asset-management-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useForm, useFormContext } from 'react-hook-form';
import { useSWRConfig } from 'swr';

import type {
  LAIUseFormReturn,
  LiftAIFormProps,
} from '~/components/uploadDocument/SelectDocumentTypeDialog';
import { useOEMs } from '~/data/hooks/useOEMs';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { getApiClient } from '~/utils/api';

import { FieldAndCell } from '../form/utils';
import { buildCarFieldDef } from './propertyCarFormFields';

const getDefaultValues = (): DefaultValues<CarDto> => {
  return {
    ahjId: null,
    name: '',
    criticalUnit: false,
    elevatorType: null,
    installationYear: 0,
    modernizationYear: null,
    numLandings: null,
    speedFpm: null,
    controllerModel: null,
    driveType: null,
    lastSspiTest: null,
    numEntrances: null,
    oem: null,
  };
};

const updateCar = async (dto: CarDto): Promise<Car> => {
  const apiClient = getApiClient();
  const car = await apiClient.cars.update(dto);
  return car;
};

export const useCarForm = (): LAIUseFormReturn<CarDto, Car> => {
  const hookForm = useForm<CarDto>({
    mode: 'all',
    defaultValues: getDefaultValues(),
    resolver: zodResolver(carDtoSchema),
  });

  return { hookForm, Form: CarForm, Fields: CarFields };
};

export const CarForm = ({ children, onSubmit, initialData }: LiftAIFormProps<CarDto, Car>) => {
  const { mutate } = useSWRConfig();
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, reset } = useFormContext<CarDto>();

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const formValues = {
      ...getDefaultValues(),
      ...initialData,
    };

    reset(formValues);
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data) => {
          try {
            const carDto = carDtoSchema.parse(data);
            const car = await updateCar(carDto);
            onSubmit(car);
            const action = ActionEnum.Update;

            showEntityActionSnackbar(
              {
                name: 'Car',
                action,
                id: data.id,
              },
              {
                variant: 'info',
              },
            );

            await mutate(['property', car.property.id, 'cars'], undefined, { revalidate: true });
          } catch (err) {
            console.error('CarForm save error: ', err);
            showSnackbar('Car failed to save', {
              variant: 'error',
            });
          }
        },
        (err) => {
          console.error('CarForm handleSubmit error: ', err);
        },
      )}
    >
      {children}
    </form>
  );
};

export const CarFields: React.FC = () => {
  const { control } = useFormContext<CarDto>();

  const { oems } = useOEMs();

  const fields = buildCarFieldDef({
    oems,
  });

  return (
    <>
      <Typography variant="h6" my={2}>
        Unit Details
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};

export default CarFields;

import { InvoiceInsights } from '@liftai/asset-management-types';
import { AutoAwesome, Refresh as RefreshIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-premium';
import { useState } from 'react';

import Feedback from '~/components/feedback/Feedback';
import { useRefreshInsights } from '~/data/hooks/useInvoice';

const InsightSkeleton = () => {
  return (
    <List
      sx={{
        padding: '0 0 0 16px',
        maxHeight: '100px',
      }}
    >
      {[1, 2, 3].map((item) => (
        <ListItem
          key={item}
          sx={{
            display: 'flex',
            padding: '4px 0',
            gap: 1,
            '&::marker': {
              fontSize: '0.4em',
            },
          }}
        >
          <Box
            sx={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: '#01579B',
              opacity: 0.3,
              mt: 1,
            }}
          />
          <Skeleton
            variant="text"
            width={`${Math.random() * (95 - 75) + 75}%`}
            sx={{
              bgcolor: 'rgba(1, 87, 155, 0.12)',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const InsightsNotAvailable = () => {
  return <Typography variant="body2">Insights not available</Typography>;
};

const InsightDetails = ({ insights }: { insights: InvoiceInsights }) => {
  const insightsData = insights.insights;

  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange =
    (panelIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panelIndex : null);
    };

  return (
    <>
      {insightsData?.map((insight, index) => (
        <Accordion
          expanded={expanded === index}
          onChange={handleChange(index)}
          disableGutters
          key={index}
          sx={{
            color: '#01579B',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&.MuiAccordion-root': {
              ':before': {
                content: 'none',
              },
            },
          }}
        >
          <AccordionSummary expandIcon={<GridExpandMoreIcon color="primary" />} sx={{ padding: 0 }}>
            <Typography variant="subtitle2">{insight.insight}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Typography variant="body2">{insight.reasoning}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const LiftAIInsights = ({
  insights,
  invoiceId,
}: {
  insights: InvoiceInsights | null;
  invoiceId: string;
}) => {
  const { refreshInsights, loading: isGettingInsights } = useRefreshInsights(invoiceId);

  return (
    <Box padding={2} borderRadius={2} margin={2} bgcolor="#E7F2FF">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={1}
        gap={1}
      >
        <AutoAwesome fontSize="small" color="primary" />
        <Typography variant="subtitle2" color="primary" flexGrow={1}>
          LIFT AI INSIGHTS
        </Typography>

        <IconButton onClick={refreshInsights} color="primary">
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Box>

      {isGettingInsights ? (
        <InsightSkeleton />
      ) : !insights ? (
        <InsightsNotAvailable />
      ) : (
        <>
          <InsightDetails insights={insights} />
          <Feedback
            feedbackKey="invoice-insight"
            runId={insights.runId}
            placeholder="Are these insights helpful?"
          />
        </>
      )}
    </Box>
  );
};

export default LiftAIInsights;

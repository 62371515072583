import { isSameMonth, isWithinInterval } from 'date-fns';

import { SelectedDateRange } from '~/components/date/MonthRangePicker/types';

export const isInSameMonth = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return isSameMonth(dayA, dayB);
};

export const isInRange = (day: Date, range?: SelectedDateRange) => {
  if (!range || !range.start) return false;

  return isWithinInterval(day, {
    start: range.start,
    end: range.end,
  });
};

import { z } from 'zod';
export const feedbackRequestSchema = z.object({
    runId: z.string(),
    key: z.string().min(1, 'Key cannot be empty'),
    comment: z.string().optional(),
    score: z.number().int().min(-1).max(1),
});
export const feedbackResponseSchema = z.union([
    z.object({
        status: z.string(),
    }),
    z.object({
        error: z.string(),
    }),
]);
